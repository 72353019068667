import LibGenerateTestUserSig from './lib-generate-test-usersig.min' // 签名计算
import Vue from 'vue'

function genTestUserSig(userID) {
    var lujing = window.location.href.split('#')[0]
    // 正式  1400572155
    // 测试  1400670459
    var SDKAPPID = lujing == 'https://juxin.kunzhousc.com/' ? 1400572155 : 1400670459;

    var EXPIRETIME = 604800;
    // 正式 key 9cd896f10f2a61c7a2d0f8447c608e720109dd70903142b647a125c0f85d74b6
    // 测试 key 5ad59b16109ff05a8e27ce57b5b2baaf3da4d8d09ade80c79ca817c5f5f705ec
    var SECRETKEY = lujing == 'https://juxin.kunzhousc.com/' ? '9cd896f10f2a61c7a2d0f8447c608e720109dd70903142b647a125c0f85d74b6' : '5ad59b16109ff05a8e27ce57b5b2baaf3da4d8d09ade80c79ca817c5f5f705ec';

    var generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
    var userSig = generator.genTestUserSig(userID);
    return {
        SDKAppID: SDKAPPID,
        userSig: userSig
    };
}
export default genTestUserSig
