<template>
    <div style="background-color: #fff">
        <div class="head">
            <!-- /* 聚信  头像  扫一扫 */ -->
            <!-- 左侧弹出框 -->
            <div class="NavBar" style="align-items: center">
                <div style="width: 105px">
                    <div @click="showPopup" style="width: 32px; height: 32px">
                        <img :src="user_info.avatar"
                             style="width: 100%; height: 100%; border-radius: 50%;object-fit: cover;" alt=""/>
                    </div>
                </div>
                <div style="font-weight: bold">聚信</div>
                <div class="small_icons">
                    <img src="../../assrts/payment.png" @click="payment"/>
                    <img src="../../assrts/scan.png" @click="scan"/>
                    <img style="margin-right: 0" src="../../assrts/love.png" @click="love"/>
                </div>
            </div>
            <!-- /* 聚信  头像  扫一扫 */ -->
            <popup :show="show"></popup>
            <!--            <scan></scan>-->
            <!-- 爱心 弹窗 -->
            <van-popup v-model="loveShow" position="right" :style="{ height: '100%', width: '50%', }">
                <ul class="love_list">
                    <!-- 申请 -->
                    <p class="love_list_title" v-if="shenqing.length !== 0">申请</p>
                    <li class="flex love_list_li" v-for="(item, index) in shenqing" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 申请 -->

                    <!-- 推广管理 -->
                    <p class="love_list_title" v-if="tuiguang.length !== 0">推广管理</p>
                    <li class="flex love_list_li" v-for="(item, index) in tuiguang" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 推广管理 -->

                    <!-- 聚信服务 -->
                    <p class="love_list_title" v-if="juxinfuwu.length !== 0">聚信服务</p>
                    <li class="flex love_list_li" v-for="(item, index) in juxinfuwu" :key="item.id">
                        <img :src="item.icon" alt="" style="object-fit: cover;"/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 聚信服务 -->

                    <!-- 网点 -->
                    <p class="love_list_title" v-if="wangdian.length !== 0">网点</p>
                    <li class="flex love_list_li" v-for="(item, index) in wangdian" :key="item.id">
                        <img :src="item.icon" alt="" style="object-fit: cover;"/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 网点 -->

                    <!-- 店铺 -->
                    <p class="love_list_title" v-if="dianpu.length !== 0">店铺</p>
                    <li class="flex love_list_li" v-for="(item, index) in dianpu" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 店铺 -->

                    <!-- 厂家-->
                    <p class="love_list_title" v-if="changjia.length !== 0">厂家</p>
                    <li class="flex love_list_li" v-for="(item, index) in changjia" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 厂家-->

                    <!-- 用户经理 -->
                    <p class="love_list_title" v-if="yonghu.length !== 0">用户经理</p>
                    <li class="flex love_list_li" v-for="(item, index) in yonghu" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 用户经理 -->

                    <!--产品经理  -->
                    <p class="love_list_title" v-if="jingli.length !== 0">产品经理</p>
                    <li class="flex love_list_li" v-for="(item, index) in jingli" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 产品经理 -->

                    <!--网点经理  -->
                    <p class="love_list_title" v-if="wangdianjingli.length !== 0">网点经理</p>
                    <li class="flex love_list_li" v-for="(item, index) in wangdianjingli" :key="item.id">
                        <img :src="item.icon" alt=""/>
                        <span @click="apply_empower(item)">{{ item.name }}</span>
                    </li>
                    <!-- 网点经理 -->

                </ul>
                <div style="height: 100px;"></div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import {Dialog} from 'vant';

export default {
    props: ["url", "type"],
    data() {
        return {
            show: false,
            result: "",
            loveShow: false, //爱心弹窗
            loveList: [],
            user_info: "", //个人用户信息
            juxinfuwu: [], //聚信服务
            wangdian: [], //网点
            dianpu: [], // 店铺
            jingli: [], //产品经理
            changjia: [], //厂家
            tuiguang: [], //推广管理
            shenqing: [], // 申请
            yonghu: [], //用户经理
            wangdianjingli: [],//网点经理
        };
    },
    mounted() {
        this.user_info = JSON.parse(localStorage.getItem("user_info"));
        this.Love()
    },
    methods: {
        /**
         * 点击头像左侧弹窗打开
         * */
        showPopup() {
            if (this.type) {
                this.$emit("show_popu", true);
            } else {
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 50);
            }
        },
        /**
         * 跳转到收付款
         * */
        payment() {
            if (this.$user_info.userInfo.idNumber) {
                this.$router.push("/Collection");
            } else {
                Dialog.confirm({
                    message: '还未实名，请先实名认证',
                    confirmButtonColor: 'rgb(252, 144, 19)'
                }).then(() => {
                    this.$router.push({
                        path: '/Real_name',
                        query: {
                            mm: 1
                        }
                    })
                }).catch(() => {
                    // on cancel
                });
            }
        },
        /**
         * 跳转到扫一扫
         * */
        scan() {
            this.$router.push({
                path: "/scan",
                query: {
                    url: this.url,
                },
            });
        },
        /**
         * 跳转到小爱心
         * */
        love() {
            this.loveShow = true;
        },
        // 关闭弹窗
        lovePopupFalse() {
            this.loveShow = false;
        },

        // 申请授权
        apply_empower(item) {
            console.log(item.id);
            console.log(item)
            if (item.id == 6 || item.id == 7 || item.id == 8 || item.id == 12 || item.id == 13 || item.id == 14 || item.id == 18 || item.id == 17 || item.id == 19 || item.id == 23 || item.id == 29 || item.id == 30 || item.id == 32) {
                let url = `${item.url}&token=${localStorage.getItem('token').split(' ')[1]}`
                // let url = `http://localhost:8081/#/singlePage/userPromotion/eventPromotion?who=1&&id=-1&token=${localStorage.getItem('token').split(' ')[1]}`

                location.href = url
            } else if (item.id == 5 || item.id == 9 || item.id == 10 || item.id == 11 || item.id == 15 || item.id == 16 || item.id == 20 || item.id == 21) {
                let url = `${item.url}?token=${localStorage.getItem('token').split(' ')[1]}&who=1`
                // let url = `http://localhost:8081/#/pages-onlineShop/onlineShopAdd/onlineShopAdd?who=1&&id=-1&token=${localStorage.getItem('token').split(' ')[1]}`
                location.href = url
            } else if (item.url) {
                window.open(item.url);
            } else {
                if (item.id == 27) {
                    //   推广商品
                    this.$router.push("/promotion_commodity")
                } else if (item.id == 28) {
                    // 授权
                    this.$router.push("/apply_empower");
                } else if (item.id == 24 || item.id == 22 || item.id == 31 || item.id == 25) {
                    // 产品经理推广码 24 产品经理 31 用户经理 22 普通用户/不用处理 25 网点经理
                    this.$router.push({
                        path: "/business_card",
                        query: {
                            id: item.id
                        }
                    });
                } else if (item.name == '申请厂家') {
                    // 点击做 清空
                    //  判断是否实名过
                    if (!this.$user_info.userInfo.idNumber) {
                        this.$router.push({
                            path: '/apply_changjia'
                        })
                    } else {
                        //  已实名
                        // 已经实名  但没有走完流程
                        this.loveShow = false;
                        this.$toast('请前往聚信APP开通')
                        // if (!localStorage.getItem('cun')) {
                        //     this.$router.push({
                        //         path: '/apply_changjia_xia',
                        //     })
                        // } else {
                        //     this.$axios({
                        //         url: '/bkx-juxin-app/roleMenu/queryStatusByRoleType',
                        //         method: 'GET',
                        //         params: {
                        //             roleType: 3,  // 3是厂家
                        //         }
                        //     }).then(res => {
                        //         if (res.code == 200) {
                        //             if (res.data.status == 1) {
                        //                 this.$toast('您已提交厂家申请,请耐心等待审核')
                        //             } else if (res.data.status == 2) {
                        //                 this.$toast('通过')
                        //             } else if (res.data.status == 3) {
                        //                 // 被拒绝后 重新走流程
                        //                 this.$router.push({
                        //                     path: '/apply_changjia_xia',
                        //                 })
                        //             }
                        //         }
                        //     })
                        // }
                    }
                } else if (item.name == '申请网点') {
                    // 判断是否实名
                    if (!this.$user_info.userInfo.idNumber) {
                        // 0   1未实名,  就得去实名
                        this.$router.push({
                            path: '/Real_name',
                            query: {name: 10}  // 网点
                        })
                    } else {
                        // 已实名
                        this.$axios.post('/shop-app/applyController/insetOutlets', {
                            userId: this.$user_info.uuid,
                            shopId: 1
                        }).then(res => {
                            if (res.code == 200) {
                                // 走接口看审核的状态
                                this.$axios({
                                    url: '/bkx-juxin-app/roleMenu/queryStatusByRoleType',
                                    method: 'GET',
                                    params: {
                                        roleType: 4,  // 3是厂家  4是网点  5是店铺
                                    }
                                }).then(res => {
                                    if (res.code == 200) {
                                        if (res.data.status == 1) {
                                            this.$toast('您已提交网点申请,请耐心等待审核')
                                        } else if (res.data.status == 2) {
                                            this.$toast('通过')
                                        } else if (res.data.status == 3) {
                                            this.$toast('拒绝')
                                        }
                                    }
                                })
                            }
                        })
                        this.$router.push({
                            path: '/',
                        })
                    }
                } else if (item.name == '申请店铺') {
                    // 判断是否实名
                    if (!this.$user_info.userInfo.idNumber) {
                        // 0   1未实名,  就得去实名
                        this.$router.push({
                            path: '/Real_name',
                            query: {name: 9}
                        })
                    } else {
                        // 已实名
                        this.$axios.post('bkx-shop-app/applyController/insetShop', {
                            userId: this.$user_info.uuid,
                            shopId: 1
                        }).then(res => {
                            if (res.code == 200) {
                                // 走接口看审核的状态
                                this.$axios({
                                    url: '/bkx-juxin-app/roleMenu/queryStatusByRoleType',
                                    method: 'GET',
                                    params: {
                                        roleType: 5,  // 3是厂家  4是网点  5是店铺
                                    }
                                }).then(res => {
                                    if (res.code == 200) {
                                        if (res.data.status == 1) {
                                            this.$toast('您已提交店铺申请,请耐心等待审核')
                                        } else if (res.data.status == 2) {
                                            this.$toast('通过')
                                        } else if (res.data.status == 3) {
                                            this.$toast('拒绝')
                                        } else if (res.data.status == 0) {
                                            this.$toast('您已提交店铺申请,请耐心等待审核')
                                        }
                                    }
                                })
                            } else {
                                this.$toast(res.msg)
                            }
                        })
                        this.$router.push({
                            path: '/',
                        })
                    }
                }

            }

        },
        // 推广商品
        promotion_commodity() {
            this.$router.push("/promotion_commodity");
        },

        // 小爱心列表
        Love() {
            this.$axios({
                url: "/bkx-juxin-app/roleMenu/queryOtherRoleFunctions",
                method: "GET",
                params: {
                    Authorization: this.$token
                },
            }).then((res) => {
                if (res.code == 200) {
                    this.loveList = res.data
                    for (let i = 0; i < this.loveList.length; i++) {
                        if (this.loveList[i].groupTitle == '聚信服务') {
                            this.juxinfuwu.push(this.loveList[i])
                            //   this.juxinfuwu.push(this.loveList)
                        } else if (this.loveList[i].groupTitle == '网点') {
                            this.wangdian.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '店铺') {
                            this.dianpu.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '产品经理') {
                            this.jingli.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '厂家') {
                            this.changjia.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '推广管理') {
                            this.tuiguang.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '申请') {
                            this.shenqing.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '用户经理') {
                            this.yonghu.push(this.loveList[i])
                        } else if (this.loveList[i].groupTitle == '网点经理') {
                            this.wangdianjingli.push(this.loveList[i])
                        }
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
.flex {
    display: flex;
}

::v-deep .van-overlay {
    z-index: 2016 !important;
}

::v-deep .van-popup--right {
    z-index: 2017 !important;
}

::v-deep .van-toast {
    z-index: 9999 !important;
}

.partner {
    width: 48.5%;
    height: 76px;
    background-color: #fc9013;
    display: flex;
    border-radius: 8px;
}

.partner img {
    width: 34px;
    height: 34px;
    margin: 20px 6px;
    margin-left: 34px;
}

.small_icons {
    display: flex;
    width: 210px;
    justify-content: space-between;
}

.small_icons img {
    width: 48px;
    height: 48px;
    margin-right: 18px;
}

.NavBar {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.NavBar div {
    font-size: 36px;
    margin-bottom: 10px;
    color: #181818;
}

/* 聚信  头像  扫一扫 */
.van-tabs__wrap .van-tabs__nav .van-tab {
    font-size: #000000;
}

.van-tab__pane {
    display: none !important;
}

/* 爱心弹窗 */
.love_list {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.love_list_title {
    font-size: 30px;
    color: #181818;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 0;
}

.love_list_li {
    height: 100px;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #eee;
    margin-left: 40px;
}

.love_list_li img {
    width: 42px;
    margin-right: 20px;
}

.love_list_li span {
    color: #181818;
    font-size: 28px;
}
</style>
