<template>
    <div style="display: flex; width: 100%" @click="closeAll">
        <!-- 领取荷包 -->
        <van-overlay :show="he_show" class="overlay">
            <div class="hebao" @click="ling_he">
                <img src="@/assrts/kaihe_1.png" style="width: 104px;height: 108px">
            </div>
            <div @click.stop="he_show = false">
                <img src="@/assrts/guanbi_hebao.png"
                    style="width: 36px;height: 36px;margin: 0 auto;display: block;margin-top: 36px">
            </div>
        </van-overlay>
        <!-- 个人名片弹窗 -->
        <van-popup v-model="card_show" style="border-radius: 8px">
            <div style="width: 90vw">
                <div style="padding: 20px">
                    <div style="font-size: 17px;margin-bottom: 10px">发送给：</div>
                    <div style="display: flex;margin-bottom: 20px">
                        <img :src="info.avatar" style="object-fit: cover;width: 40px;height: 40px;border-radius: 4px">
                        <div style="line-height: 40px;margin-left: 10px;color: #333;font-size: 14px">
                            {{ info.nickName }}
                        </div>
                    </div>
                    <div style="color: #999;font-size: 14px;margin-bottom: 20px">[个人名片]{{ info.nickName }}</div>
                    <div style="width: 100%;background-color: #ececec;line-height: 20px;height: 28px;overflow: hidden">
                        <input class="back_input" type="text" v-model="liulan" placeholder="给朋友留言">
                    </div>
                </div>
                <div style="display: flex;text-align: center;font-size: 14px;line-height: 50px">
                    <div style="width: 49.495vw;border-top: 0.5px solid #999" @click="card_show = false, $router.push('/')">
                        取消
                    </div>
                    <div style="width: 0.04vw;background-color: #999"></div>
                    <div style="width: 49.495vw;border-top: 0.5px solid #999;color: #FC9013" @click="fasong">发送</div>
                </div>
            </div>
        </van-popup>
        <div v-if="item_show" style="width: 100%;height: 100vh;position: absolute;top: 0;left: 0;z-index: 1999;"
            @click="item_show = false"></div>
        <div style="display: flex;width: 100%" v-if="ground_list.length != 0">
            <!-- 动画 -->
            <div style="width: 64px;position: absolute;z-index: 2001;transition: width .3s ease-in;"
                :class="showcry ? 'cryz_z_show' : ''">
                <!-- 阴影 -->
                <div style="width:100vw;height:76vh;position: absolute;bottom: 0;right: 0;left: 0;z-index: 99;background-color: rgba(0,0,0,.5);"
                    v-if="showcry" @click="closez"></div>
                <!-- 点击展开 -->
                <div :class="showcry ? 'cryz' : 'cryz_z'" @click.stop="showleft" id="showcry"></div>
                <!-- 左列表 -->
                <div class="left" id="left">
                    <div v-for="(item, index) in ground_list" :key="index" style="display: flex"
                        :class="xuan_id == index ? 'zhi_back' : ''">
                        <div id="left-z" style="display: flex;align-items: center" @touchstart="gtouchstart(item, index)"
                            @touchmove="gtouchmove()" @touchend.prevent="showDeleteButton(index)">
                            <img :src="item.faceUrl" :id='`dian${index}`' style="pointer-events: none;"
                                :class="index == xuan_id ? 'xuan_touxiang' : 'touxiang'" />
                            <div style="position: relative;left: -28px;top: -20px">
                                <div class="dingdian">
                                    <div style="background-color: #FD4625;border-radius: 14px;">
                                        <div v-if="item.unReadCount && !item.isDonotDisturb">{{
                                                item.unReadCount > 99 ? '99+' : item.unReadCount
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <img src="@/assrts/7032.png" v-if="item.isDonotDisturb"
                                     style="width: 17px;height: 17px;margin-top: 23px;margin-left: 8px">
                            </div>
                            <div class="text" v-if="showtest" :style="{ 'color': showcry ? '' : 'transparent' }">{{
                                item.nickName
                            }}
                            </div>
                        </div>
                        <!-- <div v-if="item.show && item.isPinned == true" :style="{ top: `${wid_val - 9}px` }" class="caidan">
                        <div class="pulic" style="width: 86px; height: 100%; background-color: #b2b2b2"
                            @click="pulichs(index, 1)">
                            <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                <img src="@/assrts/quzhi.png" style="width: 17px; height: 17px" />
                            </div>
                            <div style="font-size: 13px; color: #fff">取消置顶</div>
                        </div>
                        <div class="pulic" style="background-color: #f75436" @click="pulichs(index, 2)">
                            <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                            </div>
                            <div style="font-size: 13px; color: #fff">删除</div>
                        </div>
                    </div>
                    <div v-else-if="item.show && item.isPinned == false" :style="{ top: `${wid_val - 9}px` }"
                        class="caidan">
                        <div class="pulic" style="background-color: #f89b3e" @click="pulichs(index, 3)">
                            <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                <img src="@/assrts/779.png" style="width: 17px; height: 17px" />
                            </div>
                            <div style="font-size: 13px; color: #fff">置顶</div>
                        </div>
                        <div class="pulic" style="background-color: #f75436" @click="pulichs(index, 2)">
                            <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                            </div>
                            <div style="font-size: 13px; color: #fff">删除</div>
                        </div>
                    </div> -->
                    </div>
                    <div style="width: 44px;height: 80px;"></div>
                </div>
            </div>
            <div class="caidan-z"
                style="width: 155px;height:10vh;position: absolute;left: -82px;z-index:2000;transition:all .2s;"
                :style="{ 'left': show_pic ? '64px' : '-82px' }">
                <div v-if="show && isPinned == true && showcry == false" class="caidan" id="caidan" style="z-index: 9999;">
                    <div class="pulic" style="width: 86px; height: 100%; background-color: #b2b2b2"
                        @click.stop="pulichs(index, 1)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/quzhi.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">取消置顶</div>
                    </div>
                    <div class="pulic" style="background-color: #f75436" @click.stop="pulichs(index, 2)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">删除</div>
                    </div>
                </div>
                <div v-else-if="show && isPinned == false && showcry == false" class="caidan" id="caidan"
                    style="z-index: 9999;">
                    <div class="pulic" style="background-color: #f89b3e" @click.stop="pulichs(index, 3)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/779.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">置顶</div>
                    </div>
                    <div class="pulic" style="background-color: #f75436" @click.stop="pulichs(index, 2)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">删除</div>
                    </div>
                </div>
            </div>
            <div class="left" style="width: 64px;"></div>
            <div class="rigth">
                <!-- 群聊、单聊 三个小点 -->
                <div class="T-box">
                    <div class="icon">
                        <img src="@/assrts/544.png" @click="$router.push({
                            path: '/calendar', query: {
                                id: active,
                                GroupId: ground_list[xuan_id].groupId,
                            }
                        })" />
                    </div>
                    <div style="display: flex;align-items: center">
                        <div style="text-align: center;overflow: hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:1;width: 100px;font-weight: bold;"
                            v-if="ground_list[this.xuan_id].nickName">
                            {{
                                this.ground_list[this.xuan_id].nickName.length > 6 ?
                                this.ground_list[this.xuan_id].nickName.slice(0, 6) + "..." :
                                this.ground_list[this.xuan_id].nickName
                            }}
                        </div>
                        <div style="text-align: right;overflow: hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:1;width: 65px;font-weight: bold;"
                            v-else>
                            未知名称
                        </div>
                        <img v-if="this.ground_list[this.xuan_id].isDonotDisturb" src="@/assrts/7029.png"
                            style="width: 17px;height: 17px">
                    </div>

                    <div style="width: 24px">
                        <div class="icon" @click="more(ground_list[xuan_id])">
                            <img src="@/assrts/21.png" alt="" />
                        </div>
                    </div>
                </div>
                <!-- 拍照 图片  语音 视频 -->
                <div class="B-box">
                    <div style="padding: 0 8.5px"></div>
                    <div v-for="(item, index) in functions_list" :key="index" @click="fun_click(index)">
                        <div v-if="index == 0">
                            <img :src="item" class="fun_img" v-if="taboo == 1" @click="$toast('你被管理员禁言')" />
                            <van-uploader :after-read="afterRead" accept="video/*,image/*" capture="camera"
                                v-else-if="taboo == 0">
                                <img :src="item" class="fun_img" style="margin-top: 9px" />
                            </van-uploader>
                        </div>
                        <div v-else-if="index == 1">
                            <img :src="item" class="fun_img" v-if="taboo == 1" @click="$toast('你被管理员禁言')" />
                            <van-uploader :after-read="afterRead" v-else-if="taboo == 0">
                                <img :src="item" class="fun_img" style="margin-top: 9px" />
                            </van-uploader>
                        </div>
                        <img :src="item" class="fun_img" v-else />
                    </div>
                </div>

                <!-- 聊天内容区域 -->
                <!-- 提示 -->
                <div class="chat_content1" id="chat_content1" :style="{
                    'background-image': ground_list[xuan_id].backdrop != '' ?
                        'url(' + ground_list[xuan_id].backdrop + ')'
                        : '',
                    'background-repeat': 'no-repeat',
                }">
                    <!-- {{ userInfo.readGroupAnnouncements }}
                    {{ notice }}
                    {{ NumberApply }} -->
                    <div class="ProView_box"
                        v-if="(NumberApply != 0 && (userInfo.isOwner == 1 || userInfo.isAdmin == 1)) || (userInfo.readGroupAnnouncements == 0 && notice != null)">
                        <div class="ProView_Message" @click.stop="qun_Notice"
                            v-if="userInfo.readGroupAnnouncements == 0 && notice != null">
                            <div style="display: flex;align-items: center;">
                                <div class="ProView_icon"></div>
                                <p class="ProView_title">群公告</p>
                            </div>
                            <span class="rightIcon"></span>
                        </div>
                        <div class="ProView_Message"
                            v-if="NumberApply !== 0 && (userInfo.isOwner == 1 || userInfo.isAdmin == 1)"
                            @click="Application"
                            :style="{ 'border-top': info.readGroupAnnouncements == 0 ? '1px solid #eee' : '0' }">
                            <div style=" display: flex; width: 100%;height: 40px; align-items: center;">
                                <div class="notification_icon"></div>
                                <p class="ProView_title">新邀请进群申请({{ NumberApply }})</p>
                            </div>
                            <span class="rightIcon"></span>
                        </div>
                        <!-- {{ userInfo.isAdmin }} -->
                    </div>
                    <div id="chat_content_xiang1">
                        <!-- <ProView></ProView> -->
                        <div class="chat_body" v-for="(item, index) in session_list" :key="index">

                            <div class="chat_time">{{ item.timea }}</div>
                            <!-- 接收方 -->
                            <div v-if="item.From_Account != $user_info.account" class="body_left">
                                <div
                                    v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType == 99">
                                </div>
                                <div style="display: flex">
                                    <img :src="item.faceUrl" class="body_avatar" @click="goUser(item)"
                                        v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType != 99" />
                                    <div>
                                        <div v-if="isShowNickName == 1 && item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType != 99"
                                            style="font-size: 12px;margin-left: 8px;color: #666">
                                            {{ item.From_Account_NickName }}
                                        </div>
                                        <div v-if="item.MsgBody[0].MsgContent.Data">
                                            <!-- 聚信荷包 -->
                                            <div class="left_envelope left_mess" @click="envelope(item)"
                                                :style="item.MsgBody[0].MsgContent.Data.isFinished == 1 || item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? 'background-color: #F6B77C' : 'background-color: #FA902C'"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgType == 2">
                                                <div style="display: flex">
                                                    <img src="@/assrts/envelope.png" style="width: 33px; height: 33px" />
                                                    <div class="envelope_text" style="width: 45vw">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain ?
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain : '荷包鼓鼓，招财纳福'
                                                        }}
                                                    </div>
                                                </div>
                                                <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                                <div
                                                    style="font-size: 10px;opacity: 0.6;color: #fff;margin: 6px 0 7px 13px;">
                                                    聚信荷包
                                                </div>
                                            </div>
                                            <!-- 聚信转账 -->
                                            <div class="left_transfer left_mess"
                                                :style="item.MsgBody[0].MsgContent.Data.msgJson.status == 0 ? 'background-color: #FA902C' : 'background-color: #F6B77C'"
                                                @click="transfer(item)"
                                                v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 3">
                                                <div style="display: flex">
                                                    <img style=" width: 33px; height: 33px"
                                                        :src="item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? wancheng :
                                                            item.MsgBody[0].MsgContent.Data.msgJson.status == 2 ? guoqi : dengdai" />
                                                    <div class="transfer_text">
                                                        <div style="margin-top: 15px; width: 120px;">
                                                            {{
                                                                item.MsgBody[0].MsgContent.Data.msgJson.type == 1 ? '￥' : ''
                                                            }}{{
    item.MsgBody[0].MsgContent.Data.msgJson.money
}}
                                                        </div>
                                                        <div style="margin-bottom: 9px"
                                                            v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 0">
                                                            请收款
                                                        </div>
                                                        <div style="margin-bottom: 9px"
                                                            v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 1">
                                                            已被收款
                                                        </div>
                                                        <div style="margin-bottom: 9px"
                                                            v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                            已过期
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                                <div
                                                    style="font-size: 10px;opacity: 0.6;color: #fff;margin: 6px 0 7px 13px;">
                                                    聚信转账
                                                </div>
                                            </div>
                                            <!-- 个人名片 -->
                                            <div class="left_card left_mess" @click="goUser(item, 1)"
                                                v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 4">
                                                <div style="display: flex">
                                                    <img :src="item.MsgBody[0].MsgContent.Data.msgJson.avatar"
                                                        style="width: 33px; height: 33px;object-fit: cover;" />
                                                    <div class="card_text">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.name
                                                        }}
                                                    </div>
                                                </div>
                                                <div style="height: 1px; background-color: #eeeeee; width: 100%"></div>
                                                <div
                                                    style="font-size: 10px;opacity: 0.6;color: #ccc;margin: 6px 0 7px 13px;">
                                                    个人名片
                                                </div>
                                            </div>
                                            <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 99">
                                                <div style="display: flex"
                                                    v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 42">
                                                    <img src="@/assrts/envelope.png" style="width: 17px; height: 17px" />
                                                    <div style="display: flex;color: #CCC.van-overlayCCC;font-size: 10px">
                                                        <div>
                                                            {{
                                                                item.MsgBody[0].MsgContent.Data.msgJson.receiverName.length
                                                                > 7
                                                                ?
                                                                item.MsgBody[0].MsgContent.Data.msgJson.receiverName.slice(0,
                                                                    7) + '...' :
                                                                item.MsgBody[0].MsgContent.Data.msgJson.receiverName
                                                            }}领取了{{
    item.MsgBody[0].MsgContent.Data.msgJson.senderName.length >
    7 ?
    item.MsgBody[0].MsgContent.Data.msgJson.senderName.slice(0,
        7) +
    '...' : item.MsgBody[0].MsgContent.Data.msgJson.senderName
}}的
                                                        </div>
                                                        <div style="color: #FC9013">荷包</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 视频消息 -->
                                            <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 102"
                                                style="display: flex">
                                                <video class="right_video"
                                                    :poster="item.MsgBody[0].MsgContent.Data.msgJson.snapshotUrl"
                                                    autoHide="true" controls="controls">
                                                    <source :src="item.MsgBody[0].MsgContent.Data.msgJson.url"
                                                        type="video/mp4">
                                                </video>
                                            </div>
                                        </div>
                                        <!-- 图片消息 -->
                                        <div v-else-if="item.MsgBody[0].MsgType == 'TIMImageElem'" style="display: flex">
                                            <img :src="item.faceUrl" class="body_avatar" @click="goUser(item)" />
                                            <div>
                                                <div v-if="isShowNickName == 1"
                                                    style="font-size: 12px;margin-left: 8px;color: #666">
                                                    {{ item.From_Account_NickName }}
                                                </div>
                                                <img @click.stop="ImgPopupShow(item)"
                                                    :src="item.MsgBody[0].MsgContent.ImageInfoArray ? item.MsgBody[0].MsgContent.ImageInfoArray[2].URL : item.MsgBody[0].MsgContent.imageInfoArray[2].url"
                                                    class="right_img">
                                            </div>
                                        </div>
                                        <div v-else-if="item.type == 5" class="left_mess">
                                            <div style="margin: 15px 10px; font-size: 15px; color: #333333">
                                                对公消息申请
                                            </div>
                                            <div style="margin: 0 10px 5px; font-size: 15px; color: #333333">
                                                厂家：申请方的聚信号(厂家名称)申请使用河北坤州网络科技有限公司对公账号，点击
                                                <span @click.stop="look" style="color: #ff9113">查看详情</span>
                                            </div>
                                            <div
                                                style="margin: 0 10px;padding: 5px 0px 14px 0px;font-size: 12px;color: #999999;border-top: 1px solid #ccc;">
                                                申请时间 2023-10-11 12:23:12
                                            </div>
                                        </div>
                                        <!-- 文本消息 -->
                                        <div v-else-if="item.MsgBody[0].MsgType == 'TIMTextElem'" style="display: flex">
                                            <img :src="item.faceUrl" class="body_avatar" @click="goUser(item)" />
                                            <div style="display:flex;flex-direction:column;align-items: flex-start;">
                                                <div v-if="isShowNickName == 1"
                                                    style="font-size: 12px;margin-left: 8px;margin-bottom: 3px;color: #666;">
                                                    {{ item.From_Account_NickName }}
                                                </div>
                                                <div class="left_text left_mess">
                                                    {{ item.MsgBody[0].MsgContent.Text }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                            <!--发送方-->
                            <div v-else class="body_right">
                                <div></div>
                                <div style="display: flex">
                                    <div v-if="item.MsgBody[0].MsgContent.Data">
                                        <!-- 聚信荷包 -->
                                        <div class="right_envelope right_mess"
                                            :style="item.MsgBody[0].MsgContent.Data.isFinished == 1 ? 'background-color: #F6B77C' : 'background-color: #FA902C'"
                                            @click="envelope(item)" v-if="item.MsgBody[0].MsgContent.Data.msgType == 2">
                                            <div style="display: flex">
                                                <img src="@/assrts/envelope.png" style="width: 33px; height: 33px" />
                                                <div class="envelope_text" style="width: 45vw;">
                                                    {{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.explain ?
                                                        item.MsgBody[0].MsgContent.Data.msgJson.explain : '荷包鼓鼓，招财纳福'
                                                    }}
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="
                    font-size: 10px;
                    opacity: 0.6;
                    color: #fff;
                    margin: 6px 0 7px 13px;
                  ">
                                                聚信荷包
                                            </div>
                                        </div>
                                        <!-- 聚信转账 -->
                                        <div class="right_transfer right_mess"
                                            :style="item.MsgBody[0].MsgContent.Data.msgJson.status == 0 ? 'background-color: #FA902C' : 'background-color: #F6B77C'"
                                            @click="transfer(item)"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 3">
                                            <div style="display: flex">
                                                <img style=" width: 33px; height: 33px"
                                                    :src="item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? wancheng :
                                                        item.MsgBody[0].MsgContent.Data.msgJson.status == 2 ? guoqi : dengdai" />
                                                <div class="transfer_text">
                                                    <div style="margin-top: 15px;width: 120px;">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type == 1 ? '￥' : ''
                                                        }}{{ item.MsgBody[0].MsgContent.Data.msgJson.money }}
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 0">
                                                        请收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 1">
                                                        已被收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                        已过期
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="
                    font-size: 10px;
                    opacity: 0.6;
                    color: #fff;
                    margin: 6px 0 7px 13px;
                  ">
                                                聚信转账
                                            </div>
                                        </div>
                                        <!-- 个人名片 -->
                                        <div class="left_card left_mess" @click="goUser(item, 1)" style="margin-right: 6px"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 4">
                                            <div style="display: flex">
                                                <img :src="item.MsgBody[0].MsgContent.Data.msgJson.avatar"
                                                    style="width: 33px; height: 33px" />
                                                <div class="card_text">
                                                    {{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.name
                                                    }}
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #eeeeee; width: 100%"></div>
                                            <div style="font-size: 10px;opacity: 0.6;color: #ccc;margin: 6px 0 7px 13px;">
                                                个人名片
                                            </div>
                                        </div>
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 99">
                                            <div style="display: flex"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 42">
                                                <img src="@/assrts/envelope.png" style="width: 17px; height: 17px" />
                                                <div style="display: flex;color: #CCCCCC;font-size: 10px">
                                                    <div>你领取了{{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.senderName.length > 7 ?
                                                        item.MsgBody[0].MsgContent.Data.msgJson.senderName.slice(0, 7) +
                                                        '...' : item.MsgBody[0].MsgContent.Data.msgJson.senderName
                                                    }}的
                                                    </div>
                                                    <div style="color: #FC9013">荷包</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 视频消息 -->
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 102"
                                            style="display: flex;margin-top: 6px">
                                            <video class="right_video"
                                                :poster="item.MsgBody[0].MsgContent.Data.msgJson.snapshotUrl"
                                                autoHide="true" controls="controls">
                                                <source :src="item.MsgBody[0].MsgContent.Data.msgJson.url" type="video/mp4">
                                            </video>
                                        </div>
                                    </div>
                                    <!-- 图片 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMImageElem'" style="display: flex">

                                        <img @click.stop="ImgPopupShow(item)"
                                            :src="item.MsgBody[0].MsgContent.ImageInfoArray ? item.MsgBody[0].MsgContent.ImageInfoArray[2].URL : item.MsgBody[0].MsgContent.imageInfoArray[2].url"
                                            class="right_img">

                                        <img :src="$user_info.avatar" class="body_avatar" />
                                    </div>
                                    <!-- 文本消息 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMTextElem'" style="display: flex">
                                        <div class="right_text right_mess">
                                            {{
                                                item.MsgBody[0].MsgContent.Text ? item.MsgBody[0].MsgContent.Text :
                                                item.MsgBody[0].MsgContent.text
                                            }}
                                        </div>
                                        <img :src="$user_info.avatar" class="body_avatar" />
                                    </div>
                                    <img :src="$user_info.avatar" class="body_avatar"
                                        v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType != 99" />
                                </div>
                                <div
                                    v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType == 99">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 语音 输入框 表情 -->
                <div class="chat_bottom" style="padding: 10px 0" v-if="taboo">
                    <div style="background: #f2f2f5;display: flex;width: 98%">
                        <input type="text" id="text" class="bottom_input" style="text-align: center;width: 100%" readonly
                            placeholder="当前群禁言中" />
                    </div>
                </div>
                <div class="chat_bottom" style="padding: 10px 0" v-else>
                    <div style="background: #f2f2f5;display: flex;width: 85%">
                        <input type="text" id="text" v-model="message" class="bottom_input" @keyup.enter="pinot(message)"
                            @focus="biao_show = false" />
                    </div>
                    <div style="font-size: 15px;color: #FA902C;line-height: 30px;margin-left: 10px" @click="pinot(message)">
                        发送
                    </div>
                    <!--                    <img src="@/assrts/32.png" @click="biao_show = !biao_show" class="expression"/>-->
                    <!-- 表情键盘 -->
                </div>
                <div style="height: 120px" v-if="biao_show">
                    <van-popup v-model="biao_show" position="bottom" :overlay="false" class="pur_emoji">
                        <div v-for="(item, index) in emoji" :key="index" @click="emoji_cli(item)" style="
              width: 38px;
              height: 45px;
              font-size: 25px;
              text-align: center;
              line-height: 45px;
            ">
                            {{ item }}
                        </div>
                    </van-popup>
                </div>
            </div>
        </div>
        <div v-else
            style="background-color:#F2F2F5;color: #ccc;width: 100vw;font-size: 15px;display: flex;align-items: center;justify-content: space-between">
            <div></div>
            暂无聊天
            <div></div>
        </div>
        <div class="imgPopup" @click="imgShow = false" v-show="imgShow">
            <div style="display: flex; justify-content: center; align-items: center;width:100%;height: 100vh;">
                <img :src="BigImg" alt="" style="width:100% ;">
            </div>
        </div>
    </div>
</template>

<script>
import emoji from "@/utils/emoji";
import { set } from "core-js/internals/weak-map-helpers";
// import ProView from '@/components/PopView/index.vue'
export default {
    name: "index",
    // components: {
    //     ProView
    // },
    props: ["active"],
    mounted() {

        if (sessionStorage.getItem('account')) {
            let account = sessionStorage.getItem('account')
            this.$axios({
                url: '/bkx-base-user/juxinFriends/searchFriends',
                method: 'GET',
                params: {
                    accountOrPhone: account
                }
            }).then(res => {
                if (res.code == 200) {
                    this.info = res.data
                    this.card_show = true

                    this.$axios({
                        url: '/bkx-juxin-app/juxinGroup/queryGroupNotice',
                        method: 'GET',
                        params: {
                            groupId: this.$route.query.toaccount
                        }
                    }).then(res => {
                        // console.log(this.ground_list[this.xuan_id]);
                        console.log('公告', res);
                        if (res.data.notice != null || res.data.notice != '') {
                            this.notice = res.data.notice
                        }
                    }) //查找群公告
                } else {
                    this.$toast(res.msg)
                }
                sessionStorage.removeItem('account')
            })
        }
        this.emoji = emoji.emoji.data.split(',')
        this.queryH5()



    },
    data() {
        return {
            show_pic: false,
            left_t: 0,
            show_in: false,
            index: 0,
            show: false,
            isPinned: false,
            showcry: false,//是否展开
            showtest: false,
            isShowNickName: '',
            wid_val: '',
            liulan: '',
            info: '',
            card_show: false,
            wancheng: require('@/assrts/5508.png'),
            guoqi: require('@/assrts/7881.png'),
            dengdai: require('@/assrts/transfer.png'),
            MsgKey: '',
            he_uuid: '',
            he_show: false, //
            groupList: [],
            showPopover: '',
            value1: "",
            he_item: '',
            lastTime: 0,
            taboo: '',
            item_show: false,
            item_index: 0,
            message: "", // 文本消息
            emoji: "", // 表情库
            biao_show: false, // 表情键盘的显隐
            xuan_id: 0, // 选中的下标
            ground_list: [],
            session_list: [],
            functions_list: [
                require("@/assrts/xiangji.png"),
                require("@/assrts/tuku.png"),
                require("@/assrts/hebao.png"),
                require("@/assrts/sousuolishi.png"),
                require("@/assrts/gerenmingpian.png")
            ],
            notice: null, //群公告
            NumberApply: 0,// 进群申请人数
            userInfo: {},// 个人信息
            timer: null,
            time_bian: '',
            imgShow: false,
            BigImg: '',
        };
    },
    watch: {
        item_show(news) {
            if (news == false) {
                this.ground_list[this.item_index].show = false;
            }
        },
        // 监听进群申请数据是否实时发生改变
        NumberApply(newName, oldName) {
            this.NumberApply = newName
        },
    },
    methods: {
        closeAll() {
            this.show = false
            this.isPinned = false
            this.show_pic = false
        },
        /**
         * 时间处理
         * */
        time_out(message) {
            //获取当前时间 tims为当前的日期
            var new_d = new Date();
            var new_n = new_d.getFullYear();
            var new_y = new_d.getMonth() + 1;
            new_y = new_y < 10 ? ('0' + new_y) : new_y;
            var new_r = new_d.getDate();
            let tims = new_n + '-' + new_y + '-' + (new_r < 10 ? `0${new_r}` : new_r)
            //转换腾讯云给的时间 tima为转换出来的日期
            var new_data = new Date(message.time ? message.time * 1000 : message.MsgTime * 1000)
            let y = new_data.getFullYear();
            let m = new_data.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            let d = new_data.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = new_data.getHours();
            let minute = new_data.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            let tima = y + '-' + m + '-' + d
            if (this.time_bian != h + ':' + minute) {
                this.time_bian = h + ':' + minute
                if (tima == tims) {
                    return h + ':' + minute
                } else {
                    return y + '-' + m + '-' + d + ' ' + h + ':' + minute
                }
            }
        },
        //是否展开左侧
        showleft() {
            this.showcry = !this.showcry
            if (this.showcry) {
                setTimeout(() => {
                    this.showtest = true
                    this.show = false
                    this.isPinned = false
                }, 300)
            } else {
                this.showtest = false
                this.show = false
                this.isPinned = false
            }
        },
        //点击遮罩层关闭
        closez() {
            this.showcry = false
            if (this.showcry) {
                setTimeout(() => {
                    this.showtest = true
                }, 300)
            } else {
                this.showtest = false
            }
        },
        queryH5() {
            this.$axios({
                url: '/juxin-app/dialogue/queryH5',
                method: 'GET',
                params: {
                    pageNum: 1,
                    pageSize: 0
                }
            }).then(res => {
                if (res.code == 200) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        if (res.data.list[i].appUuid[0] != 'c') {
                            this.ground_list.push(res.data.list[i])
                        }
                    }
                    if (this.$route.query.togroundid) {
                        for (let j = 0; j < this.ground_list.length; j++) {
                            if (this.$route.query.togroundid == this.ground_list[j].groupId) {
                                this.xuan_id = j
                            }
                        }
                    }
                    console.log(res, 123);
                    console.log(this.ground_list, 'list');
                    console.log(this.xuan_id, 'xuan_id');
                    if (this.ground_list.length != 0) {
                        this.delexiao(this.ground_list[this.xuan_id].uuid)
                        this.ground_list[this.xuan_id].unReadCount = 0


                        this.qunzhaung()
                        if (this.$route.query.time) {
                            // 获取当前时间
                            var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                            var Y = date.getFullYear() + '-';
                            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                            var D = date.getDate() + ' ';
                            let timer = Y + M + D
                            var tempTime1 = Date.parse(timer) / 1000;
                            console.log(tempTime1, 'timer');
                            console.log(this.$route.query.time, 'this.$route.query.time')
                            this.$axios({
                                url: '/bkx-juxin-app/chattingRecords/getMsgGroup',
                                method: 'GET',
                                params: {
                                    startTime: this.$route.query.time,
                                    endTime: tempTime1 == +this.$route.query.time ? "" : tempTime1,
                                    keyword: '',
                                    account: this.$user_info.account,
                                    GroupId: this.ground_list[this.xuan_id].groupId,
                                    type: 0
                                }
                            }).then(res => {
                                console.log('res', res);
                                if (res.code == 200) {
                                    let data = []
                                    for (let i = 0; i < res.data.length; i++) {
                                        if (res.data[i].MsgBody[0].MsgContent.Data) {
                                            if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 99) {
                                                if (typeof JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson == 'string') {
                                                    if (JSON.parse(JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson).type != 52) {
                                                        data.push(res.data[i])
                                                    }
                                                } else {
                                                    data.push(res.data[i])
                                                }
                                            } else {
                                                data.push(res.data[i])
                                            }
                                        } else {
                                            data.push(res.data[i])
                                        }
                                    }
                                    this.session_list = data
                                    for (let i = 0; i < this.session_list.length; i++) {
                                        if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                            this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                            if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                                this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                            }
                                        }
                                        if (i >= 1) {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        } else {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        }
                                    }
                                    // 获取个人信息
                                    this.getUserInfo()
                                    this.jian_message()
                                    // 获取群公告
                                    this.getGroupList()
                                    // 获取好友申请加群列表
                                    this.shenqinglist()
                                    this.getGroup()
                                    this.nextTick()
                                } else {
                                    this.$toast(res.msg)
                                }
                            })
                        } else {
                            this.$axios({
                                url: '/bkx-juxin-app/chattingRecords/getMsgGroup',
                                method: 'GET',
                                params: {
                                    account: this.$user_info.account,
                                    GroupId: this.ground_list[this.xuan_id].groupId,
                                    type: 0
                                }
                            }).then(res => {
                                console.log('res', res);
                                if (res.code == 200) {
                                    let data = []
                                    for (let i = 0; i < res.data.length; i++) {
                                        if (res.data[i].MsgBody[0].MsgContent.Data) {
                                            if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 99) {
                                                if (typeof JSON.parse(res.data[i].session_listMsgBody[0].MsgContent.Data).msgJson == 'string') {
                                                    if (JSON.parse(JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson).type != 52) {
                                                        data.push(res.data[i])
                                                    }
                                                } else {
                                                    data.push(res.data[i])
                                                }
                                            } else {
                                                data.push(res.data[i])
                                            }
                                        } else {
                                            data.push(res.data[i])
                                        }
                                    }
                                    this.session_list = data
                                    for (let i = 0; i < this.session_list.length; i++) {
                                        if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                            this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                            if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                                this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                            }
                                        }
                                        if (i >= 1) {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        } else {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        }
                                    }
                                    // 获取个人信息
                                    this.getUserInfo()
                                    this.jian_message()
                                    // 获取群公告
                                    this.getGroupList()
                                    // 获取好友申请加群列表
                                    this.shenqinglist()
                                    this.getGroup()
                                    this.nextTick()
                                } else {
                                    this.$toast(res.msg)
                                }
                            })
                        }
                    }
                    // 判断是不是从通讯录过来的 如果是则xuan_id = 传过来的群聊
                    // 从通讯录-群聊跳转过来,如果uuid被储存的话,则xuan_id聚焦到该群聊窗口
                    if (sessionStorage.getItem('uuid')) {
                        let uuid = sessionStorage.getItem('uuid')
                        console.log(uuid);
                        console.log(this.ground_list);
                        for (let i = 0; i < this.ground_list.length; i++) {
                            if (this.ground_list[i].groupId == uuid) {
                                this.xuan_id = i
                                this.session_list[this.xuan_id].unReadCount = 0
                            }
                        }
                        console.log(this.xuan_id, 'xuan_id');
                        sessionStorage.removeItem('uuid')
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // look 查看详情
        look() {
            this.$router.push('/Duigong_details')
        },
        /**
         * 查询群状态
         * */
        qunzhaung() {
            this.$axios.post('/bkx-juxin-app/juxinGroupMember/selectGroupMembers', {
                groupIds: this.ground_list[this.xuan_id].groupId
            }).then(res => {
                if (res.code == 200) {
                    let groupList = res.data[this.ground_list[this.xuan_id].groupId]
                    for (let i = 0; i < groupList.length; i++) {
                        if (groupList[i].userAccount == this.$user_info.account) {
                            this.isShowNickName = groupList[i].isShowNickName
                            this.taboo = groupList[i].isForbidden
                            if (this.taboo == 1) {
                                this.$toast('你被管理员禁言')
                            }
                        }
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 获取组员
        getGroup() {
            this.$axios.post('/bkx-juxin-app/juxinGroupMember/selectGroupMembers', {
                groupIds: this.ground_list[this.xuan_id].groupId
            }).then(res => {
                if (res.code == 200) {
                    this.groupList = res.data[this.ground_list[this.xuan_id].groupId]
                    for (let i = 0; i < this.groupList.length; i++) {
                        if (this.groupList[i].userAccount == this.$user_info.account) {
                            this.userInfo = this.groupList[i]
                            if (this.userInfo.isShowNickName == 1) {
                                this.userInfo.isShowNickName = true
                            } else {
                                this.userInfo.isShowNickName = false
                            }
                            this.groupNickName = this.userInfo.nickName
                        }
                        if (!this.groupList[i].nickName) {
                            this.groupList.splice(i, 1)
                        }
                    }
                }
            })
        },
        /**
         * 监听消息
         * */
        jian_message() {
            this.deng_tim(this.$user_info.account.toString())
            let that = this
            let onMessageReceived = function (event) {
                let data = event.data[0]

                if (data.to == that.ground_list[that.xuan_id].groupId) {
                    for (let i = 0; i < that.groupList.length; i++) {
                        if (that.groupList[i].userAccount == data.from) {
                            data.From_Account_NickName = that.groupList[i].nickName
                            data.faceUrl = that.groupList[i].avatar
                        }
                    }
                    if (data.type == "TIMTextElem") {
                        data.MsgBody = [
                            {
                                MsgType: data.type,
                                MsgContent: {
                                    Text: data.payload.text
                                }
                            }
                        ]
                    } else if (data.type == "TIMImageElem") {
                        data.MsgBody = [
                            {
                                MsgType: data.type,
                                MsgContent: data.payload
                            }
                        ]
                    } else {
                        if (typeof data.payload.data == 'string') {
                            data.payload.data = JSON.parse(data.payload.data)
                        }
                        if (typeof data.payload.data.msgJson == 'string') {
                            data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                        }
                        data.MsgBody = [
                            {
                                MsgType: data.type,
                                MsgContent: {
                                    Data: data.payload.data,
                                    Desc: "",
                                    Ext: "",
                                    Sound: ""
                                }
                            }
                        ]
                    }
                    that.session_list.push(data)
                    setTimeout(() => {
                        that.nextTick()
                    }, 500)
                } else {
                    for (let ij = 0; ij < that.ground_list.length; ij++) {
                        if (that.ground_list[ij].groupId == data.to) {
                            that.ground_list[ij].unReadCount += 1
                        }
                    }
                }
                // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
                // event.name - TIM.EVENT.MESSAGE_RECEIVED
                // event.data - 存储 Message 对象的数组 - [Message]
            };
            this.$tim.on(this.$TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
        },
        /**
         * 移动到最底部
         * */
        nextTick() {
            this.$nextTick(() => {
                if (!this.$route.query.time) {
                    document.getElementById('chat_content1').scrollTop = document.getElementById('chat_content_xiang1').clientHeight
                }
            })
        },
        /**
         * 发送个人名片
         * */
        fasong() {
            this.$toast.loading({
                message: '发送中...',
                forbidClick: true,
            });
            this.deng_tim(this.$user_info.account.toString())
            let that = this
            setTimeout(() => {
                let msgJson = {
                    uuid: this.info.account,
                    userID: this.info.account,
                    avatar: this.info.avatar,
                    name: this.info.nickName,
                    sign: '',
                }
                let data = {
                    msgType: 4,
                    msgJson: msgJson
                }
                let message = this.$tim.createCustomMessage({
                    to: this.ground_list[this.xuan_id].groupId,
                    conversationType: this.$TIM.TYPES.CONV_GROUP,
                    payload: {
                        data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
                        description: '', // 获取骰子点数
                        extension: ''
                    }
                });
                let promise = this.$tim.sendMessage(message);
                promise.then(function (imResponse) {
                    that.group_xiao(that.ground_list[that.xuan_id].groupId)
                    that.$router.push('/')
                    that.card_show = false
                    let data = imResponse.data.message
                    data.From_Account = data.from
                    data.faceUrl = data.avatar
                    if (typeof data.payload.data == 'string') {
                        data.payload.data = JSON.parse(data.payload.data)
                    }
                    if (typeof data.payload.data.msgJson == 'string') {
                        data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                    }
                    data.MsgBody = [
                        {
                            MsgType: data.type,
                            MsgContent: {
                                Data: data.payload.data,
                                Desc: "",
                                Ext: "",
                                Sound: ""
                            }
                        }
                    ]
                    that.session_list.push(data)
                    if (that.liulan) {
                        that.go_mesage(that.liulan)
                        that.$toast.clear()
                    }
                }).catch(function (imError) {
                    // 发送失败
                    console.warn('sendMessage error:', imError);
                });
            }, 1000)
        },
        /**
         * 前往荷包页面
         * */
        envelope(item, id) {
            this.$axios.post('/bkx-pay/redpacket/getredverification', {
                uuid: item.MsgBody[0].MsgContent.Data.msgJson.uuid
            }).then(res => {
                if (res.code == 200) {
                    console.log(item, '这是');
                    if (item.MsgBody[0].MsgContent.Data.msgJson.pouchType == 2) {
                        console.log(res.data);
                        if (this.$user_info.account != res.data.vipId && this.$user_info.account != res.data.sendId) {
                            this.$toast(`这是${res.data.vipName}的专属荷包`)
                            return;
                        }
                        if (this.$user_info.account == res.data.sendId) {
                            this.$router.push({
                                path: '/envelope',
                                query: {
                                    active: 2,
                                    uuid: item.MsgBody[0].MsgContent.Data.msgJson.uuid,
                                    account: item.MsgBody[0].MsgContent.Data.msgJson.receiveUserID
                                }
                            })
                            return
                        }

                    }
                    this.he_item = item
                    if (id || item.MsgBody[0].MsgContent.Data.msgJson.status == 1 || item.MsgBody[0].MsgContent.Data.isFinished == 1) {
                        this.$router.push({
                            path: '/envelope',
                            query: {
                                active: 2,
                                uuid: item.MsgBody[0].MsgContent.Data.msgJson.uuid,
                                account: item.MsgBody[0].MsgContent.Data.msgJson.receiveUserID
                            }
                        })
                    } else if (item.MsgBody[0].MsgContent.Data.msgJson.status == 2) {
                        this.$toast('红包已过期')
                    } else if (item.MsgBody[0].MsgContent.Data.msgJson.status == 0) {
                        this.he_show = true
                        this.he_uuid = item.MsgBody[0].MsgContent.Data.msgJson.uuid
                        this.MsgKey = item.MsgKey
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        /**
         * 领取荷包
         * */
        ling_he() {
            this.$axios.post('/bkx-pay/redpacket/gettoreceive', {
                uuid: this.he_uuid,
                targetMsgID: this.MsgKey
            }).then(res => {
                if (res.code == 200) {
                    this.envelope(this.he_item, 1)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        /**
         * 前往转账页面
         * */
        transfer(item) {
            this.$router.push({
                path: '/transfer',
                query: {
                    active: 2,
                    uuid: item.MsgBody[0].MsgContent.Data.msgJson.uuid,
                    MsgKey: item.MsgKey
                }
            })
        },
        /**
         * 前往好友信息页面
         * */
        goUser(item, id) {
            this.$router.push({
                path: `/blackListDetails`,
                query: {
                    active: 2,
                    account: id ? item.MsgBody[0].MsgContent.Data.msgJson.userID : item.From_Account
                }
            })
        },
        /**
         * 文本消息添加表情
         * */
        emoji_cli(item) {
            this.message = `${this.message}${item}`
        },
        pinot(message) {
            let now = new Date().valueOf();
            //第一次点击
            if (this.lastTime == 0) {
                this.go_mesage(message);//首次点击调用方法
                this.lastTime = now;
            } else {
                if ((now - this.lastTime) > 1000) {
                    this.lastTime = now;
                    this.go_mesage(message);//首次点击调用方法
                }
            }
        },
        /**
         * 发送消息
         * */
        go_mesage(text) {
            document.getElementById('text').focus()
            let that = this
            let message = this.$tim.createTextMessage({
                to: this.ground_list[this.xuan_id].groupId,
                conversationType: this.$TIM.TYPES.CONV_GROUP,
                payload: {
                    text: text ? text : this.message
                },
                needReadReceipt: true
            });
            // 2. 发送消息
            let promise = this.$tim.sendMessage(message);
            promise.then(function (imResponse) {
                console.log(imResponse); // 发送成功
                that.time_bian = ''
                let timea = that.time_out(imResponse.data.message)
                if (that.session_list.length != 0) {
                    if (that.session_list[that.session_list.length - 1].timea != timea) {
                        imResponse.data.message.timea = timea
                    }
                } else {
                    imResponse.data.message.timea = timea
                }
                setTimeout(() => {
                    that.delexiao(that.ground_list[that.xuan_id].uuid)
                })
                that.pulish_xiao(that.$user_info.account, '', JSON.stringify(that.ground_list[that.xuan_id].groupId))
                imResponse.data.message.From_Account = that.$user_info.account
                imResponse.data.message.MsgBody = [
                    {
                        MsgType: imResponse.data.message.type,
                        MsgContent: {
                            Text: imResponse.data.message.payload.text
                        }
                    }
                ]
                that.session_list.push(imResponse.data.message)
                that.message = ''
                setTimeout(() => {
                    that.nextTick()
                }, 500)
            }).catch(function (imError) {
                that.$toast(imError) // 发送失败
            });
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        getVideoBase64(url) {
            let dataURL = ''
            let video = document.createElement('video')
            video.setAttribute('crossOrigin', 'anonymous') //处理跨域
            video.setAttribute('src', url)
            video.setAttribute('width', 375)
            video.setAttribute('height', 513)
            video.currentTime = 1
            video.addEventListener('loadeddata', () => {
                let canvas = document.createElement('canvas')
                let width = video.width //canvas的尺寸和图片一样
                let height = video.height
                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(video, 0, 0, width, height) //绘制canvas
                dataURL = canvas.toDataURL('image/jpeg') //转换为base64
                const blob = this.base64ImgtoFile(dataURL)
                const blobUrl = window.URL.createObjectURL(blob);
                this.imgUrl = blobUrl
            })
        },
        base64ImgtoFile(dataurl, filename = 'file') {
            //将base64格式分割：['data:image/png;base64','XXXX']
            const arr = dataurl.split(',')
            // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
            // image/png
            const mime = arr[0].match(/:(.*?);/)[1]  //image/png
            //[image,png] 获取图片类型后缀
            const suffix = mime.split('/')[1] //png
            const bstr = atob(arr[1])   //atob() 方法用于解码使用 base-64 编码的字符串
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
            })
        },
        /**
         * 发送图片消息
         * */
        afterRead(file) {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let that = this
            if (file.file.type == 'video/mp4' || file.file.type == 'video/quicktime') {
                let formData = new FormData()
                formData.append('file', file.file)
                this.$axios.post('/file/ownerUpload', formData).then(res => {
                    if (res.code == 200) {
                        //视频地址
                        this.getVideoBase64(res.data.url)
                        var duration = 0;
                        let that = this
                        that.$toast.loading({
                            message: '发送中...',
                            forbidClick: true,
                        });
                        let msgJson = {
                            dataSize: file.file.size,
                            duration: duration,
                            uuid: that.guid(),
                            url: res.data.url,
                            snapshotUrl: this.imgUrl
                        }
                        let data = {
                            msgType: 102,
                            msgJson: msgJson
                        }
                        let message = that.$tim.createCustomMessage({
                            to: that.ground_list[that.xuan_id].groupId,
                            conversationType: that.$TIM.TYPES.CONV_GROUP,
                            payload: {
                                data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
                                description: '', // 获取骰子点数
                                extension: ''
                            }
                        });
                        let promise = that.$tim.sendMessage(message);
                        promise.then(function (imResponse) {
                            that.group_xiao(that.ground_list[that.xuan_id].groupId)
                            that.$router.push('/')
                            that.card_show = false
                            let data = imResponse.data.message
                            data.From_Account = data.from
                            data.faceUrl = data.avatar
                            if (typeof data.payload.data == 'string') {
                                data.payload.data = JSON.parse(data.payload.data)
                            }
                            if (typeof data.payload.data.msgJson == 'string') {
                                data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                            }
                            data.MsgBody = [
                                {
                                    MsgType: data.type,
                                    MsgContent: {
                                        Data: data.payload.data,
                                        Desc: "",
                                        Ext: "",
                                        Sound: ""
                                    }
                                }
                            ]
                            that.session_list.push(data)
                            if (that.liulan) {
                                that.go_mesage(that.liulan)
                                that.$toast.clear()
                            }
                        }).catch(function (imError) {
                            // 发送失败
                            console.warn('sendMessage error:', imError);
                        });
                    }
                })
            } else {
                // 1. 创建消息实例，接口返回的实例可以上屏
                let message = this.$tim.createImageMessage({
                    to: this.ground_list[this.xuan_id].groupId,
                    conversationType: this.$TIM.TYPES.CONV_GROUP,
                    payload: {
                        file: file.file
                    },
                    onProgress: function (event) {
                        console.log('file uploading:', event)
                    }
                });
                // 2. 发送消息
                let promise = this.$tim.sendMessage(message);
                promise.then(function (imResponse) {
                    console.log(imResponse, '发送成功'); // 发送成功
                    setTimeout(() => {
                        that.delexiao(that.ground_list[that.xuan_id].uuid)
                    })
                    that.group_xiao(that.ground_list[that.xuan_id].groupId)
                    imResponse.data.message.From_Account = that.$user_info.account
                    imResponse.data.message.MsgBody = [
                        {
                            MsgType: imResponse.data.message.type,
                            MsgContent: imResponse.data.message.payload
                        }
                    ]
                    that.session_list.push(imResponse.data.message)
                    that.nextTick()
                }).catch(function (imError) {
                    that.$toast(imError) // 发送失败
                });
            }
        },
        // 单聊 跳到聊天设置
        more(info) {
            console.log(info);
            this.$router.push({
                path: '/qun_more',
                query: {
                    info: JSON.stringify(info)
                }
            })
        },
        /**
         * 顶部功能
         * */
        fun_click(index) {
            if (this.taboo == 1) {
                this.$toast('你被管理员禁言')
                return
            }
            if (index == 2) {
                this.$router.push({
                    path: '/wallet',
                    query: {
                        active: this.active,
                        account: this.ground_list[this.xuan_id].groupId
                    }
                })
            } else if (index == 3) {
                this.$router.push({
                    path: '/Chat_search',
                    query: {
                        id: this.active,
                        groupId: this.ground_list[this.xuan_id].groupId
                    }
                })
            } else if (index == 4) {
                this.$router.push({
                    path: '/Business',
                    query: {
                        id: this.active,
                        account: this.ground_list[this.xuan_id].groupId
                    }
                })
            } else if (index == 5) {
                console.log('点击位置')
            }
        },
        /**
         * 长按头像后
         * */
        gtouchstart(item, index) {
            console.log(1)
            this.show_pic = false
            this.show = false
            this.isPinned = false
            if (index == 0 || index) {
                var self = this;
                this.timeOutEvent = setTimeout(function () {
                    self.show = true
                    self.isPinned = item.isPinned
                    self.longPress(index);
                }, 300); //这里设置定时器，定义长按500毫秒触发长按事件
                return false;
            }
        },
        //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
        showDeleteButton(item) {
            clearTimeout(this.timeOutEvent); //清除定时器
            if (this.timeOutEvent != 0) {
                this.$axios.put('juxin-app/dialogue/updateIsRead', {
                    uuid: this.ground_list[item].uuid
                }).then(res => {
                    if (res.code == 200) {
                        this.ground_list[item].unReadCount = 0
                    }
                })
                this.xuan_id = item
                this.session_list = []
                this.$axios({
                    url: 'bkx-juxin-app/chattingRecords/getMsgGroup',
                    method: 'GET',
                    params: {
                        account: this.$user_info.account,
                        GroupId: this.ground_list[this.xuan_id].groupId,
                        type: 0
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.session_list = res.data
                        this.qunzhaung()
                        for (let i = 0; i < this.session_list.length; i++) {
                            if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                    this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                }
                            }
                            let unReadCount = 0
                            unReadCount += this.session_list[i].unReadCount
                            this.$emit('qun_shu', unReadCount)
                            if (i >= 1) {
                                this.session_list[i].timea = this.time_out(this.session_list[i])
                            } else {
                                this.session_list[i].timea = this.time_out(this.session_list[i])
                            }
                        }
                        setTimeout(() => {
                            this.nextTick()
                        }, 500)
                    } else {
                        this.$toast(res.msg)
                    }
                })
                // 获取个人信息
                this.getUserInfo()
                // 获取群公告
                this.getGroupList()
                // 获取好友申请加群列表
                this.shenqinglist()

            }
            return false;
        },
        // 获取群公告
        getGroupList() {
            this.$axios({
                url: '/bkx-juxin-app/juxinGroup/queryGroupNotice',
                method: 'GET',
                params: {
                    groupId: this.ground_list[this.xuan_id].groupId
                }
            }).then(res => {
                console.log(this.ground_list[this.xuan_id]);
                console.log('公告', res);
                if (res.data.notice != null || res.data.notice != '') {
                    this.notice = res.data.notice
                }
            })
        },
        // 获取好友申请加群列表
        shenqinglist() {
            if (this.ground_list.length != 0) {
                this.$axios({
                    url: '/bkx-juxin-app/juxinApplyList/selectJuxinApplyList',
                    method: 'GET',
                    params: {
                        groupId: this.ground_list[this.xuan_id].groupId
                    }
                }).then(res => {
                    // console.log(res, '申请');

                    if (res.code == 200) {
                        this.NumberApply = res.data.length
                    } else {
                        // Toast(res.msg)
                    }
                    // console.log(this.NumberApply, 'length');
                })
            }
        },
        // 获取个人信息
        getUserInfo() {

            this.$axios.post('/bkx-juxin-app/juxinGroupMember/selectGroupMembers', {
                groupIds: this.ground_list[this.xuan_id].groupId
            }).then(res => {
                console.log('详情数据123123', res);
                if (res.code == 200) {
                    // 找到自己
                    let userInfo = res.data[this.ground_list[this.xuan_id].groupId]
                    for (let i = 0; i < userInfo.length; i++) {
                        if (userInfo[i].userAccount == this.$user_info.account) {
                            this.userInfo = userInfo[i]
                        }
                    }

                    console.log('userInfo', this.userInfo);

                }
            })

        },
        //  跳转群公告
        qun_Notice() {
            // 获取个人信息
            // 获取群设置
            // console.log('groupId', this.groupList[0].groupId);
            this.$axios({
                url: '/bkx-juxin-app/juxinGroup/queryGroupNotice',
                method: 'GET',
                params: {
                    type: 1,
                    groupId: this.ground_list[this.xuan_id].groupId
                }
            }).then(res => {
                if (res.code == 200) {
                    console.log(res);
                    // this.settingList = res.data
                    // 跳转
                    this.$axios({
                        url: 'bkx-juxin-app/juxinGroupMember/queryGroupAnnouncements',
                        method: 'GET',
                        params: {
                            groupId: this.ground_list[this.xuan_id].groupId,
                            account: this.$user_info.account
                        }
                    }).then(res => {
                        console.log(res);
                    })
                    this.$router.push({
                        path: '/qun_Notice',
                        query: {

                            info: JSON.stringify(this.userInfo),
                            uuid: this.ground_list[this.xuan_id].groupId,
                            message: res.data.notice,
                            settingList: JSON.stringify(res.data)
                        }
                    })
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 跳转申请列表
        Application() {
            // /Application_List
            this.$router.push({
                path: '/Application_List',
                query: {
                    id: 1,
                    account: this.ground_list[this.xuan_id].groupId
                }
            })
        },
        //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
        gtouchmove() {
            clearTimeout(this.timeOutEvent); //清除定时器
            this.timeOutEvent = 0;
        },
        //真正长按后应该执行的内容
        longPress(val) {
            console.log(this.ground_list[val])
            this.timeOutEvent = 0;
            this.ground_list[val].show = true
            this.item_show = true
            this.item_index = val
            this.wid_val = document.getElementById(`dian${val}`).getBoundingClientRect().top
            this.show_pic = false
            this.index = val
            let left = document.querySelectorAll('#left-z>img')[val]
            let right = document.querySelector('.caidan-z')
            right.style.top = (left.getBoundingClientRect().top + window.pageYOffset - 10) + 'px'
            setTimeout(() => {
                this.show_pic = true
            }, 200)
        },
        /**
         * 置顶事件
         * */
        pulichs(index, id) {
            this.ground_list[this.index].show = false
            if (id == 2) { // 删除
                this.$dialog.confirm({
                    message: "确定删除该会话？",
                    confirmButtonColor: '#fa902c'
                }).then(() => {
                    this.$axios({
                        url: 'bkx-juxin-app/dialogue/delDialogue',
                        method: 'DELETE',
                        params: {
                            uuid: this.ground_list[this.index].uuid
                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.isPinned = false
                            this.show = false
                            this.$axios.put('/bkx-juxin-app/dialogue/updateIs', {
                                uuid: this.ground_list[this.index].uuid, //会话主键id
                                isPinned: 0, //是否置顶（0否1是）
                                isDonotDisturb: 0 //是否免打扰
                            })
                            this.ground_list = []
                            this.item_show = false
                            this.xuan_id = 0
                            this.queryH5()
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }).catch(() => {
                    // on cancel
                });
            } else {
                this.$axios.put('/bkx-juxin-app/dialogue/updateIs', {
                    uuid: this.ground_list[this.index].uuid, //会话主键id
                    isPinned: id == 1 ? 0 : 1, //是否置顶（0否1是）
                    isDonotDisturb: this.ground_list[this.index].isDonotDisturb == false ? 0 : 1 //是否免打扰
                }).then(res => {
                    if (res.code == 200) {
                        this.ground_list = []
                        this.item_show = false
                        this.queryH5()
                        this.isPinned = false
                        this.show = false
                    } else {
                        this.$toast(res.msg)

                    }
                })
            }
        },
        // 图片
        ImgPopupShow(item) {
            this.imgShow = true
            if (item.MsgBody[0].MsgContent.ImageInfoArray) {
                this.BigImg = item.MsgBody[0].MsgContent.ImageInfoArray[0].URL
            } else {
                this.BigImg = item.MsgBody[0].MsgContent.imageInfoArray[2].url
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.cryz {
    position: absolute;
    width: 28px;
    height: 70px;
    top: 50%;
    right: -25px;
    background: url("https://test.files.gongxiangsc.com/2023/08/01/21e4e9a9-4db7-44bb-a73c-86d159516260.png") no-repeat center right;
    transform: translateY(-50%);
    border-radius: 0 35px 35px 0;
    z-index: 1000;
}

.cryz_z {
    position: absolute;
    width: 28px;
    height: 70px;
    top: 50%;
    right: -25px;
    background: url("https://test.files.gongxiangsc.com/2023/08/01/5ae71b8f-5cf9-4d84-b51a-230390470820.png") no-repeat center right;
    transform: translateY(-50%);
    border-radius: 0 35px 35px 0;
    z-index: 900;
}

.cryz_z_show {
    width: 500px !important;
}

.text {
    font-size: 30px;
    color: #181818;
}

.overlay {
    z-index: 9999;
}

.left_mess {
    margin-left: 12px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.right_mess {
    margin-right: 12px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.pur_emoji {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 240px;
}

.left::-webkit-scrollbar {
    display: none;
}

.B-box::-webkit-scrollbar {
    display: none;
}

.chat_content1 {
    background-size: cover;
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
    position: relative;
}

// 弹窗
.ProView_box {
    width: 77%;
    background: none;
    position: fixed;
    background: #fff;
    right: 23px;
    z-index: 2;
    border-radius: 22px;

    padding: 10px 20px;
}

.ProView_box:last-child() {
    display: block;
}

.ProView_Message {
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //   padding: 10px 20px;
    box-sizing: border-box;
}

.rightIcon {
    width: 30px;
    height: 40px;
    background: url('@/assrts/113.png') no-repeat;
    background-size: 100%;
}

.ProView_icon {
    width: 50px;
    height: 50px;
    background: url('@/assrts/laba.png') no-repeat;
    background-size: 100%;
    margin-right: 30px;
}

.notification_icon {
    width: 50px;
    height: 50px;
    background: url('@/assrts/add.png') no-repeat;
    background-size: 100%;
    margin-right: 30px;
}

.ProView_title {
    color: #181818;
    font-size: 28px;
    margin: 0;
}

/* 中间 左侧头像区域 */
.content .left {
    position: relative;
    z-index: 998;
    height: 76vh;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
}

.left_z {
    width: 128px;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
}

.chat_time {
    text-align: center;
    font-size: 20px;
    color: #ccc;
    margin-top: 22px;
}

.fun_img {
    width: 66px;
    height: 66px;
    display: block;
    margin: 0 auto;
    margin-right: 44px;
}

.chat_body {
    padding: 0 20px 20px 20px;
}

.body_left {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
}

.body_right {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
}

.body_avatar {
    width: 77px;
    height: 77px;
    object-fit: cover;
}

.left_text {
    max-width: 420px;
    word-wrap: break-word;
    background-color: #fff;
    font-size: 30px;
    padding: 18px 20px;
    word-wrap: break-word;
    display: inline-block;

}

.right_text {
    max-width: 420px;
    word-wrap: break-word;
    background-color: #fc9013;
    font-size: 30px;
    padding: 18px 20px;
    color: #fff;
}

.left_img {
    margin-left: 12px;
    border-radius: 8px;
}

.right_img {
    width: 200px;
    margin-right: 12px;
    border-radius: 8px;
}

.content .left .touxiang {
    width: 84px;
    height: 84px;
    margin: 20px;
    border-radius: 8px;
}

.xuan_touxiang {
    width: 84px;
    height: 84px;
    margin: 20px;
    border-radius: 8px;
    border: 4px solid #fc9013;
}

/* 右侧内容 */
.content .rigth {
    flex: 1;
    width: 80%;
    background: #f2f2f5;
    position: relative;
    display: flex;
    flex-direction: column;
}

.content .rigth .T-box {
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
    margin: 22px 0;
}

.content .rigth .T-box .icon {
    width: 48px;
    height: 48px;
}

.content .rigth .T-box .icon img {
    width: 100%;
    height: 100%;
}

.content .rigth .T-box div {
    margin: 0;
    font-size: 32px;
    color: #000000;
}

.content .rigth .B-box {
    width: 100%;
    height: 104px;
    background: #d3d3d3;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    align-items: center;
}

.content .rigth .chat_bottom {
    display: flex;
    width: 100%;
    background: #ffffff;
}

.content .rigth .chat_bottom .chat_bottom_one {
    width: 48px;
    height: 48px;
    padding-top: 36px;
    padding-right: 20px;
}

.content .rigth .chat_bottom .chat_bottom_one img {
    width: 100%;
    height: 100%;
}

.content .rigth .chat_bottom .chat_bottom_two {
    width: 85%;
    margin: 15px 0;
    height: 88px;
    border: 0;
    border-radius: 8px;
}

.content .rigth .chat_bottom .chat_bottom_two input {
    height: 67px;
    width: 85%;
    border: 0;
    background: #f2f2f5;
}

/* 表情 */
.expression {
    width: 54px;
    height: 54px;
    margin-left: 20px;
}

.dibudingwei {
    position: sticky;
    left: 0;
    width: 100%;
    bottom: 0;
}

.van-tabs__line {
    z-index: 0;
}

.bottom_input {
    width: 83%;
    height: 60px;
    background: #f2f2f5;
    border: 0;
    font-size: 24px;
}

.van-popup {
    max-height: none;
}

.zhi_back {
    background-color: #ffede3;
}

.caidan {
    height: 121px;
    position: absolute;
    z-index: 9999;
    display: flex;
}

.pulic {
    width: 114px;
    height: 100%;
    text-align: center;
}

.el-date-picker {
    width: 1456px;
}

.left_envelope {
    background-color: #fa902c;
}

.left_envelope img {
    margin: 32px 20px 14px 26px;
}

.right_envelope {
    background-color: #fa902c;
}

.right_envelope img {
    margin: 32px 20px 14px 26px;
}

.envelope_text {
    color: #fff;
    font-size: 26px;
    line-height: 120px;
    padding: 0 52px 0 0;
}

.left_transfer {
    background-color: #fa902c;
}

.left_transfer img {
    margin: 32px 20px 14px 26px;
}

.right_transfer {
    background-color: #fa902c;
}

.right_transfer img {
    margin: 32px 20px 14px 26px;
}

.transfer_text {
    color: #fff;
    font-size: 30px;
    padding: 0 52px 0 0;
}

.left_card {
    background-color: #fff;
}

.left_card img {
    margin: 32px 20px 14px 26px;
}

.right_card {
    background-color: #fff;
}

.right_card img {
    margin: 32px 20px 14px 26px;
}

.card_text {
    width: 316px;
    color: #181818;
    line-height: 112px;
    font-size: 30px;
    padding: 0 52px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.viewer-title {
    opacity: 0;
}

.dingdian {
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    color: #fff;
}

.hebao {
    width: 666px;
    height: 654px;
    margin: 0 auto;
    background-image: url("@/assrts/hebaojiemian.png");
    background-size: 100%;
    margin-top: 290px;
}

.hebao img:hover {
    transform: rotateY(3600deg);
}

.hebao img {
    position: absolute;
    top: 700px;
    left: 275px;
    transform-origin: 100px 0 0;
    transform-style: preserve-3d;
    transition: all 20s;
}

.dingwei_img {
    margin-top: 40px;
}

.back_input {
    width: 97%;
    ;
    border: 0;
    font-size: 14px;
    margin: 0 auto;
    display: block;
    margin-top: 5px;
    background-color: #ececec
}

.back_input::placeholder {
    color: #b0b0b0;
}

.right_video {
    width: 400px;
    margin-right: 12px;
    border-radius: 8px;
}

.imgPopup {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #1E252F;
    z-index: 10000;
    transition: 0.2s;
}
</style>
