<template>
  <!-- 生成二维码开放接口:
          二维码内容[通常为url]
          二维码大小[限制为正方形]
　　　　　二维码下方显示：文字
          二维码中间显示：图片-->
    <div id="meQrcode" :title="qrText">
        <div class="qrcode_box">
            <img
                    class="qrcode_canvas"
                    id="qrcode_canvas"
                    ref="qrcode_canvas"
                    alt="二维码本图"
            />
            <img
                    v-if="qrLogo"
                    class="qrcode_logo"
                    ref="qrcode_logo"
                    :src="qrLogo"
                    alt="公司logo"
            />
            <canvas style="margin: 0 auto;display: block"
                    :width="qrSize"
                    :height="qrhelght ? qrhelght : qrSize"
                    id="canvas"
                    class="canvas"
                    ref="canvas"
            ></canvas>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
    name: 'Qrcode',
    props: {
        qrUrl: {
            type: String,
            default: 'http://www.baidu.com/'
        },
        qrSize: {
            type: Number,
            default: 250
        },
        qrhelght: {
            type: Number,
        },
        qrText: {
            default: ''
        },
        qrName: {
            default: ''
        },
        qrLogo: {
            type: String
        },
        qrLogoSize: {
            type: Number,
            default: 40
        },
        qrTextSize: {
            type: Number,
            default: 25
        },
        type: {
            type: Number
        }
    },
    data() {
        return {}
    },
    watch: {
        qrUrl(news) {
            var c = document.getElementById("canvas");
            c.width = c.width;
            if (news.indexOf('scan_xiang') != '-1' || news.indexOf('pages/classify/goodsDetails') != '-1') {
                this.handleQrcodeToDataUrl()
            }
        },
    },
    methods: {
        /**
         * @argument qrUrl        二维码内容
         * @argument qrSize       二维码大小
         * @argument qrText       二维码中间显示文字
         * @argument qrTextSize   二维码中间显示文字大小(默认16px)
         * @argument qrLogo       二维码中间显示图片
         * @argument qrLogoSize   二维码中间显示图片大小(默认为80)
         */
        //解决DPI的绘制模糊问题。绘制之前先调用；
        handleQrcodeToDataUrl() {
            let qrcode_canvas = this.$refs.qrcode_canvas
            let qrcode_logo = this.$refs.qrcode_logo
            let canvas = this.$refs.canvas
            const that = this
            QRCode.toDataURL(
                this.qrUrl,
                {errorCorrectionLevel: 'H'},
                (err, url) => {
                    qrcode_canvas.src = url
                    // 画二维码里的logo, 在canvas里进行拼接
                    let ctx = canvas.getContext('2d')
                    setTimeout(() => {
                        //获取二维码图片
                        ctx.drawImage(qrcode_canvas, 0, that.qrhelght && that.qrText ? 30 : 0, that.qrSize, that.qrSize)
                        if (that.qrLogo) {
                            //设置logo大小
                            //设置获取的logo将其变为圆角以及添加白色背景
                            ctx.fillStyle = '#fff'
                            ctx.beginPath()
                            let logoPosition = (that.qrSize - that.qrLogoSize) / 2 //logo相对于canvas居中定位
                            let h = that.qrLogoSize + 10 //圆角高 10为基数(logo四周白色背景为10/2)
                            let w = that.qrLogoSize + 10 //圆角宽
                            let x = logoPosition - 50
                            let y = logoPosition - 50
                            let r = 5 //圆角半径
                            ctx.moveTo(x + r, y)
                            ctx.arcTo(x + w, y, x + w, y + h, r)
                            ctx.arcTo(x + w, y + h, x, y + h, r)
                            ctx.arcTo(x, y + h, x, y, r)
                            ctx.arcTo(x, y, x + w, y, r)
                            ctx.closePath()
                            ctx.fill()
                            qrcode_logo.onload = function () {
                                ctx.drawImage(
                                    qrcode_logo,
                                    logoPosition,
                                    logoPosition,
                                    that.qrLogoSize,
                                    that.qrLogoSize
                                )
                            }
                            ctx.drawImage(
                                qrcode_logo,
                                logoPosition,
                                logoPosition,
                                that.qrLogoSize,
                                that.qrLogoSize
                            )
                        }
                        if (that.qrText) {
                            //设置字体
                            let fpadd = 0 //规定内间距
                            ctx.font = that.qrTextSize + 'px Arial'
                            let tw = ctx.measureText(that.qrText).width //文字真实宽度
                            let ftop = 10 //根据字体大小计算文字top
                            let fleft = (that.qrSize - tw) / 2 //根据字体大小计算文字left
                            let tp = that.qrTextSize //字体边距为字体大小的一半可以自己设置
                            ctx.fillStyle = '#fff'
                            // ctx.fillRect(
                            //     fleft - tp / 2,
                            //     ftop - tp / 2,
                            //     tw + tp,
                            //     that.qrTextSize + tp
                            // )
                            ctx.textBaseline = 'top' //设置绘制文本时的文本基线。
                            ctx.fillStyle = '#333'
                            ctx.fillText(that.qrText, fleft, ftop)
                        }
                        if (that.qrName) {
                            //设置字体
                            ctx.font = '14px 微软雅黑';
                            let tw = ctx.measureText(that.qrName).width //文字真实宽度
                            let ftop = 15 //根据字体大小计算文字top
                            let fleft = (that.qrSize - tw) / 2 //根据字体大小计算文字left
                            ctx.textBaseline = 'top' //设置绘制文本时的文本基线。
                            ctx.fillStyle = '#333'
                            ctx.fillText(that.qrName, fleft, ftop)
                        }
                        ctx.translate(0.5, 0.5);
                        qrcode_canvas.src = canvas.toDataURL()
                    }, 100)
                }
            )
        },
        /** 下载二维码*/
        downQRCode(nickName = '', text = '') {
            let canvas;
            let oA = document.createElement("a");
            canvas = document.getElementById("canvas");
            if (nickName) {
                oA.download = `聚信收款码`;// 设置下载的文件名，默认是'下载'
            } else {
                oA.download = '店铺二维码'
            }
            let canPngUrl = canvas.toDataURL("image/png");
            oA.href = canPngUrl;
            document.body.appendChild(oA);
            oA.click();
            oA.remove();
        },
    },
    mounted() {
        this.handleQrcodeToDataUrl()
    },
    updated() {
        this.handleQrcodeToDataUrl()
    },
}

</script>
<style scoped>
.qrcode_box,
#meQrcode {
    display: inline-block;
}
.qrcode_box{
    display: block;
}

.qrcode_box img {
    display: none;
}
</style>
