<template>
    <div>
        <TopPackVue :shezhi="shezhi" style="background: #F2F2F5;"></TopPackVue>
        <!-- <div style="color: #333333;font-size: 15px;font-weight: 400; margin:11px 10px 0px">使用方</div> -->
        <!-- 身份 -->
        <div class="echo">
            <span style="color: #999;">身份:</span>
            <div style="color: #999999;">{{ info.roleName }}</div>
        </div>
        <van-divider style="margin:0; margin-left:10px"/>
        <!-- 名称 -->
        <div class="echo">
            <span style="color: #999;">名称:</span>
            <div style="color: #999999;">{{ info.askName }}</div>
        </div>
        <van-divider style="margin:0; margin-left:10px"/>
        <!-- >聚信号 -->
        <div class="echo">
            <span style="color: #999;">聚信号:</span>
            <div style="color: #999999;">{{ info.askAccount }}</div>
        </div>
        <!-- >实名 -->
        <div class="echo">
            <span style="color: #999;">实名:</span>
            <div style="color: #999999;">{{ info.askRealName }}</div>
        </div>
        <van-divider style="margin:0; margin-left:10px"/>
        <!-- 使用方法 -->
        <div class="echo">
            <span style="color: #999;">使用方式:</span>
            <div style="color: #999999;">{{ info.cashType == 1 ? '提现' : info.type == 2 ? '转账' : '定时转账' }}</div>
        </div>
        <!-- >金额 -->
        <div class="echo">
            <span style="color: #999;">金额:</span>
            <div style="color: #999999;">{{ info.cash }} {{ info.type == 1 || info.type == 2 ? '元' : '' }}</div>
        </div>
        <!-- <van-divider style="margin:0; margin-left:10px" />
        <div style="color: #333333;font-size: 15px;font-weight: 400; margin:10px 10px 0px">使用对公账户信息</div> -->
        <!-- 使用方法 -->
        <!-- <div class="echo">
            <span>对公账户名称:</span>
            <div style="color: #999999;">{{ info.corpmemberName }}</div>
        </div>
        <van-divider style="margin:0; margin-left:10px" /> -->
        <!-- 留言 -->
        <div class="">
            <p style="padding-left: 8px; box-sizing: border-box; color: #999;font-size: 12px;font-weight: normal;">
                留言:</p>
            <van-field style="width: 90%; height:120px;margin: 0 auto;
            border-radius: 12px; background: #efefef;" v-model="info.remark" rows="2" autosize type="textarea"
                       placeholder="备注（选填）" show-word-limit/>
        </div>


        <div class="footer" v-if="info.noPass == 0">
            <div @click="tongguo(2)">驳回</div>
            <div @click="tongguo(1)">通过</div>
        </div>

    </div>
</template>

<script>
import {Toast} from 'vant';
import TopPackVue from '../TopPack.vue'

export default {
    components: {
        TopPackVue
    },
    mounted() {
        if (this.$route.query.info) {
            this.info = this.$route.query.info
            this.$axios({
                url: '/bkx-rh-settlement/EAV/geteavInfo',
                methods: 'GET',
                params: {
                    id: this.$route.query.info.id
                }
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.info = res.data
                } else {
                    Toast(res.msg)
                }
            })
        }
    },
    data() {
        return {
            shezhi: '申请详情',
            info: {}
        }
    },
    methods: {
        // 驳回
        bohui() {

        },
        // 通过
        tongguo(type) {
            // 判断类型 1 是同意 2是拒绝
            if (type == 1) {
                this.$axios({
                    url: '/bkx-rh-settlement/EAV/isPass',
                    methods: 'GET',
                    params: {
                        id: this.info.id,
                        type: 1,
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        Toast(res.msg)
                        this.$router.push({
                            path: '/apply_empower'
                        })
                    } else {
                        Toast(res.msg)
                    }
                })
            } else {
                this.$axios({
                    url: '/bkx-rh-settlement/EAV/isPass',
                    methods: 'GET',
                    params: {
                        id: this.info.id,
                        type: 0,
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        Toast(`已驳回`)
                        this.$router.push({
                            path: '/apply_empower'
                        })
                    } else {
                        Toast(res.msg)
                    }
                })
            }
        }
    }
}
</script>

<style scope>
.echo {
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #181818;
}

.echo span {
    margin-left: 15px;
    width: 158px;
}

.echo div {
    flex: 1;
    margin: 36px 25px;
}

/* 分割线 */
/deep/ .van-divider {
    margin: 0;
}

/* 驳回 通过 */
.footer {
    margin: 327px auto;
    font-size: 33px;
    display: flex;
    justify-content: center;
}

.footer :first-child {
    width: 296px;
    height: 92px;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid #FC9013;
    text-align: center;
    line-height: 92px;
    color: #FC9013;
    margin-right: 30px;
    font-weight: 500;
}

.footer :last-child {
    width: 296px;
    height: 92px;
    border-radius: 8px 8px 8px 8px;

    font-weight: 500;
    background: #FC9013;
    color: #FFFFFF;
    text-align: center;
    line-height: 92px;
}
</style>
