<template>
    <!-- 底部 -->
    <div class="bottom">
        <div class="bottom_top">
            <div class="public" @click="give_go(1)">
                <img src="../../assrts/renhe.png" alt="" />
                <div>商城</div>
            </div>
            <div class="public" @click="give_go(2)">
                <img src="../../assrts/liaotianxuan.png" v-if="activer == 2" />
                <img src="../../assrts/liaotian.png" v-else />
                <div>聊天</div>
            </div>
            <div class="public" @click="give_go(3)">
                <img src="../../assrts/573xuan.png" v-if="activer == 3" />
                <img src="../../assrts/573.png" v-else />
                <div>服务</div>
            </div>
            <div class="public" @click="give_go(4)">
                <img src="../../assrts/wodexuan.png" v-if="activer == 4" />
                <img src="../../assrts/wode.png" v-else />
                <div>我的</div>
            </div>
        </div>
        <!-- 搜索 -->
        <van-search v-model="value" disabled placeholder="搜索" @click="popup_show = true" />
        <van-popup v-model="popup_show" position="bottom" :style="{ height: '100%' }">
            <div class="sou_top" style="align-items: center">
                <img src="@/assrts/177.png" style="width: 20px;height: 25px" @click="fanhui()">
                <div class="top_left" v-if="shop_id == 0">
                    <input type="text" @keydown.enter="seek" style="height: 24px;width: 256px;font-size: 14px;border: 0;"
                        v-model.trim="value" />
                    <div class="top_right" @click="seek">搜索</div>
                </div>
                <div v-if="shop_id == 1">
                    <div style="font-size: 19px;color: #181818">商品</div>
                </div>
                <div v-if="shop_id == 1" style="width: 20px;"></div>
            </div>
            <div style="height: 50px;"></div>
            <div class="sou_body" style="padding: 0 0 10px 0;margin-top:10px" v-if="show_id == 0">
                <div style="display: flex; flex-wrap: wrap; ">
                    <div :class="condition_id == index ? 'xuan_sou' : 'wei_sou'" @click="xuanze(index)"
                        v-for="(item, index) in condition_list" :key="index">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <!-- 联系人 -->
            <div class="sou_body" style="margin-top: 10px"
                v-if="condition_id == 1 || (condition_id == 0 && friends.length != 0)">
                <div class="public_text">联系人</div>
                <div class="xian"></div>
                <div class="public_body" v-for="(item, index) in condition_id == 1 ? friends : friends.slice(0, 3)"
                    :key="index" @click="jump_person(item, 1)">
                    <img :src="item.avatar" style="width: 42px; height: 42px; border-radius: 4px;object-fit: cover;" />
                    <div :class="index != friends.length - 1 ? 'public_info' : 'public_info_two'">
                        <div class="public_tltie">
                            {{ item.remark != null ? item.remark : item.nickName }}
                            <span>{{
                                item.type == 1 ? '(会员)' : item.type == 2 ? '(创建商城人)' :
                                item.type == 3 ? '(厂家)' : item.type == 4 ? '(网点)' : item.type == 5 ? '(店铺)' : item.type == 6
                                    ? '(推广员)' : item.type == 7 ? '(厂家子账号)' : item.type == 8 ? '(网店子账号)' : item.type == 9 ?
                                        '(网点子账号)' :
                                        item.type == 421 ? '(用户中心)' : item.type == 422 ? '(网点中心)' : item.type == 423 ? '(产品中心)' :
                                            item.type == 424 ? '(商贸中心)' : item.type == 431 ? '(产品经理)' : item.type == 432 ? '(网点经理)' :
                                                item.type == 433 ? '(商贸经理)' : item.type == 434 ? '(用户经理)' : item.type == 420 ? '(运营中心)'
                                                    : item.type == 400 ? '(商城创始人)' : ''
                            }} </span>
                        </div>
                        <div class="public_nicheng" v-if="item.nickName != null">昵称：{{
                            item.nickName.length > 7 ? item.nickName.slice(0, 7) + '···' : item.nickName
                        }}
                        </div>
                        <div style="font-size:12px"></div>
                    </div>
                </div>

                <!--                <div class="xian" style="margin-top: 10px"></div>-->
                <div class="public_more" @click="gengduo_ren" v-if="friends.length > 3 && condition_id == 0">
                    更多联系人
                </div>
            </div>
            <div class="tiaoxing" v-if="friends.length != 0 && condition_id == 0"></div>
            <!-- 群聊 -->
            <div class="sou_body" style="margin-top: 10px"
                v-if="condition_id == 2 || (condition_id == 0 && group.length != 0)">
                <div class="public_text">群聊</div>
                <div class="xian"></div>
                <div class="public_body" v-for="(item, index) in condition_id == 2 ? group : group.slice(0, 3)" :key="index"
                    @click="jump_person(item, 2)">
                    <img :src="item.avatar" style="width: 42px; height: 42px; border-radius: 4px;object-fit: cover;" />
                    <div :class="index != group.length - 1 ? 'public_info' : 'public_info_two'">
                        <div class="public_tltie">{{ item.name }}</div>
                        <div class="public_nicheng"><span v-if="item.nickName">包含:</span> {{ item.nickName }}</div>
                    </div>
                </div>
                <!--                <div class="xian" style="margin-top: 0px"></div>-->
                <div class="public_more" @click="gengduo" v-if="group.length >= 3 && condition_id == 0">
                    更多群聊
                </div>
            </div>
            <div class="tiaoxing" v-if="group.length != 0 && (condition_id == 0)"></div>
            <!-- <div class="tiaoxing" v-if="group.length != 0 && (condition_id == 0 || condition_id == 2)"></div> -->
            <!-- 聊天记录 -->
            <div class="sou_body" style="margin-top: 10px"
                v-if="condition_id == 3 || (condition_id == 0 && Chat_record.length != 0)">
                <div class="public_text">聊天记录</div>
                <div class="xian"></div>
                <div class="public_body" v-for="(item, index) in condition_id == 0 ? Chat_record.slice(0, 3) : Chat_record "
                    :key="index" @click="jump_person(item, 3)">
                    <img :src="item.groupAvatar ? item.groupAvatar : item.faceUrl"
                        style="width: 42px; height: 42px; border-radius: 4px;object-fit: cover;" />
                    <div :class="index != 3 ? 'public_info' : 'public_info_two'">
                        <div class="public_tltie">{{
                            item.groupName ? item.groupName : item.From_Account_NickName
                        }}
                        </div>
                        <div v-if="item.list.length >= 2" class="public_nicheng">{{ item.list.length }}条相关记录</div>
                        <div v-else class="public_nicheng">{{ item.friendRemark }}:{{ item.MsgBody[0].MsgContent.Text }}
                        </div>
                    </div>
                </div>
                <!--                <div class="xian" style="margin-top: 0px"></div>-->
                <div v-if="condition_id == 0 && Chat_record.length > 3" class="public_more"
                    @click="condition_id = 3, show_id = 1">
                    更多聊天记录
                </div>
            </div>
            <div class="tiaoxing" v-if="Chat_record.length != 0 && (condition_id == 0)"></div>
            <!-- <div class="tiaoxing" v-if="Chat_record.length != 0 && (condition_id == 0 || condition_id == 3)"></div> -->
            <!--  功能  -->
            <div class="sou_body" style="margin-top: 10px"
                v-if="condition_id == 4 || (condition_id == 0 && new_gongneng.length != 0)">
                <div class="public_text">功能</div>
                <div class="xian"></div>
                <div class="public_dian" style=" display: flex; justify-content: flex-start; flex-wrap: wrap; ">
                    <div class="public_dian_body" @click="capabilities(item, index)" v-for="(item, index) in new_gongneng"
                        :key="index" style="  width: 75px;">
                        <img :src="item.img" style="width:46px; height:46px" />
                        <div style="font-size:12px">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="tiaoxing" v-if="new_gongneng.length != 0 && (condition_id == 4)"></div>
            <!-- 商品 -->
            <div class="sou_body" style="margin-top: 10px;"
                v-if="condition_id == 5 || (condition_id == 0 && goods.length != 0)">
                <div class="public_text" style="display: flex; justify-content: space-between">
                    <div>商品</div>
                    <div style="display: flex; align-items: center" @click="condition_id = 5, show_id = 1, shop_id = 1">
                        <div v-if="shop_id != 1">
                            <div v-if="goods.length > 3" style="color: #ccc; font-size: 15px">
                                更多
                            </div>
                        </div>

                        <img src="@/assrts/113.png" style="width: 14px; height: 17px"
                            v-if="condition_id != 5 && goods.length > 3" />
                    </div>
                </div>
                <div class="xian"></div>
                <div class="public_dian" style="margin: 0;overflow: hidden;overflow-x: auto;width: 100%;"
                    v-if="condition_id != 5 || condition_id == 0">
                    <div class="shop_dian_body" v-for="(item, index) in condition_id == 5 ? goods : goods.slice(0, 3)"
                        :key="index" @click="jump_person(item, 4)">
                        <img :src="item.goodsMain" style="width: 111px;height: 125px" />
                        <div class="shop_dian_text"
                            style="height: 32px;line-height: 16px; width: 88px; -webkit-line-clamp: 2">
                            {{ item.goodsName }}
                        </div>
                        <div style="display: flex">
                            <div style="font-size: 17px; color: #f83125">￥</div>
                            <div style="font-size: 21px; color: #f83125">
                                {{ item.goodsPrice }}
                            </div>
                        </div>
                        <div class="shop_dian_text" style="-webkit-line-clamp: 1;font-size: 16px;color: #999;">
                            {{ item.dianpuName }}
                        </div>
                    </div>

                </div>

                <div class="public_dian" style="margin: 0;width: 100%; display: flex; flex-wrap: wrap;"
                    v-if="condition_id == 5">

                    <div class="shop_dian_body2" v-for="(item, index) in condition_id == 5 ? goods : goods.slice(0, 3)"
                        :key="index" @click="jump_person(item, 4)">
                        <img :src="item.goodsMain" style="width:100px;height: 100px" />
                        <div class="shop_dian_text" style="margin-top: 5px; width: 88px; -webkit-line-clamp: 2">
                            {{ item.goodsName }}
                        </div>
                        <div style="display: flex;margin-top: 10px;font-weight: bold;">
                            <div style="font-size: 17px; color: #f83125">￥</div>
                            <div style="font-size: 21px; color: #f83125">
                                {{ item.goodsPrice }}
                            </div>
                        </div>
                        <div class="shop_dian_text"
                            style="height: 18px;width: 100%;  -webkit-line-clamp: 1;font-size: 16px;color: #999;">
                            {{ item.dianpuName }}
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="xian"></div> -->
            <!-- 订单 -->
            <div class="sou_body" style="margin-top: 10px"
                v-if="order.length != 0 && (condition_id == 0 || condition_id == 6)">
                <div class="public_text" style="display: flex; justify-content: space-between">
                    <div>订单</div>
                    <div style="display: flex; align-items: center" v-if="condition_id != 6">
                        <div style="color: #ccc;  font-size: 15px" @click="condition_id = 6, show_id = 1">
                            更多
                        </div>
                        <img src="@/assrts/113.png" style="width: 14px; height: 17px" />
                    </div>
                </div>
                <div class="xian"></div>
                <div class="public_body" v-for="(item, index) in condition_id == 6 ? order : order.slice(0, 3)" :key="index"
                    @click="jump_person(item, 5)">
                    <div style="width: 83px; height: 83px; border-radius: 4px">
                        <img :src="item.goodsStockLogo" style="width: 100%;height: 100%" />
                    </div>
                    <div :class="index != 3 ? 'public_info' : 'public_info_two'">
                        <div class="shop_dian_text"
                            style="height: 18px;-webkit-line-clamp: 1;font-size: 16px;width: 260px;">
                            {{ item.goodsName }}
                        </div>
                        <div class="public_nicheng" style="margin-top: 5px">
                            订单编号: {{ item.orderNum }}
                        </div>
                        <div style="
                                                                                display: flex;
                                                                                justify-content: space-between;
                                                                                margin-top: 15px;
                                                                              ">
                            <div style="display: flex; color: #181818">
                                <div style="font-size: 12px">￥</div>
                                <div style="font-size: 14px">{{ item.payMoney }}</div>
                            </div>
                            <div class="shop_dian_text" style="
                                                                                  height: 18px;
                                                                                  width:100px;
                                                                                  color: #fc9013;
                                                                                  font-size: 15px;
                                                                                  text-align: center;
                                                                                ">
                                {{
                                    item.order_status == 1 ? "待付款" : item.order_status == 2 ? "待发货" : item.order_status == 3 ?
                                        "待收货" : item.order_status == 4 ? "已完成" : item.order_status == 5 ? "退款/售后" :
                                            item.order_status == 6 ? "退货退款" : item.order_status == 7 ? "搭售商品" : "已关闭"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="xian" style="margin-right: 10px;" v-if="order.length != 0 && condition_id == 0"></div>
            <!-- 店铺 -->
            <div class="sou_body" style="margin-top: 10px" v-if="store.length != 0 && condition_id == 0">
                <div class="public_text">店铺</div>
                <div class="xian"></div>
                <div style="display: flex;margin-left: 13px;">
                    <div class="public_dian" v-for="(item, index) in store" :key="index" @click="jump_person(item, 6)">
                        <div class="public_dian_body">
                            <img :src="item.storeAvatar" />
                            <div>{{ item.storeName.slice(0, 3) + "..." }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="xian" style="margin-right: 10px;" v-if="store.length != 0 && condition_id == 0"></div>
            <!-- 网点 -->
            <div class="sou_body" style="margin-top: 10px" v-if="netWork.length != 0 && condition_id == 0">
                <div class="public_text">网点</div>
                <div class="xian"></div>
                <div class="public_dian">
                    <div class="public_dian_body" v-for="(item, index) in netWork" :key="index"
                        @click="jump_person(item, 7)" style="width:100px">
                        <img :src="item.networkAvatar" />
                        <div>{{ item.networkName.slice(0, 3) + "..." }}</div>
                    </div>
                </div>
            </div>
            <!-- 空状态 -->
            <div style=" text-align: center; font-size: 16px; margin-top:100px; color:#CCC" v-if="(value != '' && condition_id == 1 && friends.length == 0)
                || (value != '' && condition_id == 0 && friends.length == 0 && group.length == 0 && Chat_record.length == 0 && new_gongneng.length == 0 && goods.length == 0 && typea == 2)
                || (value != '' && condition_id == 2 && group.length == 0)
                || (value != '' && condition_id == 3 && Chat_record.length == 0)
                || (value != '' && condition_id == 4 && new_gongneng.length == 0)
                || (value != '' && condition_id == 5 && goods.length == 0)
                && typea == 2">
                暂无相关结果
            </div>
        </van-popup>

        <!-- 左侧弹出框 -->
        <div style="position: absolute; z-index: 2005">
            <popup :show="show" :activer="activer" @changeMsg="changeMsgFn"></popup>
        </div>
    </div>
</template>

<script>
import { Dialog, Toast } from 'vant';

export default {
    props: ["goBackId", "active"],
    data() {
        return {
            show_id: 0,
            typea: 1,
            show: false, // 左侧弹出层
            popup_show: false, // 搜索弹出层
            value: "", // 搜索框v-model
            activer: "", // 底部选中下标
            condition_list: [
                { name: "全部" },
                { name: "联系人" },
                { name: "群聊" },
                { name: "聊天记录" },
                { name: "功能" },
                { name: "商品" },
            ],
            condition_id: 0,
            shop_id: 0,
            friends: [], // 联系人
            goods: [], // 商品
            group: [], //群聊
            group_gengduo: [], //群聊更多
            netWork: [], //网点
            order: [], // 订单
            store: [], // 店铺
            gongneng: [
                { id: 1, name: '收付款', img: require('@/assrts/g1.png') },
                { id: 2, name: '扫一扫', img: require('@/assrts/g2.png') },
                { id: 3, name: '添加好友', img: require('@/assrts/g3.png') },
                { id: 4, name: '银行卡', img: require('@/assrts/g4.png') },
                { id: 5, name: '充值', img: require('@/assrts/g5.png') },
                { id: 6, name: '提现', img: require('@/assrts/g6.png') },
                { id: 7, name: '批量转账', img: require('@/assrts/g7.png') },
                // { id:8, name: '定时转账', img: require('@/assrts/g8.png') },
                // { id:9, name: '对公管理', img: require('@/assrts/g9.png') },
                { id: 10, name: '积分兑换', img: require('@/assrts/g10.png') },
                { id: 11, name: '设置支付密码', img: require('@/assrts/g11.png') },
                { id: 12, name: '实名认证', img: require('@/assrts/g12.png') },

            ],// 功能
            new_gongneng: [],
            Chat_record: [],//聊天记录
            type: 0, //更多聊天记录  四条切换
            lastTime: 0, // 默认点击时间0
        };
    },
    mounted() {
        this.activer = this.active;
    },
    methods: {
        fanhui() {
            // 联系人
            if ((this.condition_id == 1 && this.show_id == 1) || (this.condition_id == 2 && this.show_id == 1) || (this.condition_id == 3 && this.show_id == 1) || (this.condition_id == 5 && this.show_id == 1)) {
                this.condition_id = 0
                this.show_id = 0
                this.shop_id = 0
            } else {
                this.show_id = 0
                this.typea = 1
                this.popup_show = false
                this.value = ''
                this.friends = ''
                this.goods = ''
                this.group = ''
                this.netWork = ''
                this.order = ''
                this.store = ''
                this.new_gongneng = []
                this.Chat_record = ''
                this.condition_id = 0
                this.group_gengduo = ''
            }
        },
        /**
         * 跳转服务页面
         * */
        give_go(id) {
            if (id == 1) {
                this.$axios({
                    url: "/bkx-auth/juxin-app/changeLoginRole",
                    method: "GET",
                    params: {
                        utype: 100,
                        userId: this.$user_info.uuid,
                    },
                }).then((res) => {
                    if (res.code == 200) {
                        console.log(res);
                        if (this.$user_info.roleType == 100) {
                            localStorage.setItem('token', 'JuxinApp ' + res.data.access_token)
                        }
                        location.href = `${this.link}?whos=1&token=${res.data.access_token}`
                    }
                })

            } else if (id == 2) {
                this.$router.push("/");
            } else if (id == 3) {
                this.$router.push("/give_index");
            } else {
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 50);
            }
        },
        /**
         * 子组件传给父组件的事件 获取底部选中状态
         * */
        changeMsgFn(value) {
            this.activer = value;
        },

        //  全部 搜索
        seek() {
            let now = new Date().valueOf()
            // 第一次点击   // 这里做了防连点
            if (this.lastTime == 0) {
                this.sousuo2()
                this.lastTime = now
            } else {
                if ((now - this.lastTime) > 1000) {
                    this.lastTime = now;
                    this.sousuo2()
                }
            }

        },
        //  搜索到联系人 群聊等等 跳转
        jump_person(item, index) {
            if (index == 4) {
                // 网点商品
                if (item.type == 2) {
                    location.href = `${this.link}pages/classify/goodsDetails?goodsId=${item.goodsNumber ? item.goodsNumber : item.goodsId}&goods_manufacturer=${item.goodsNumber ? item.goodsNumber : item.goodsId}&network_id=${item.dianpuId}&type=${item.type}&token=${localStorage.getItem('token').split(' ')[1]}&who=1`
                } else if (item.type == 3) {
                    location.href = `${this.link}pages-trading/tradingDetails/tradingDetails?goodsNumber=${item.goodsNumber}`
                } else if (item.type == 4) {
                    // 厂商跳页
                } else {
                    location.href = `${this.link}pages/goodsDetails/goodsDetails?goodsId=${item.goodsNumber ? item.goodsNumber : item.goodsId}&network_id=${item.dianpuId}&goodsTypes=${item.type}&token=${localStorage.getItem('token').split(' ')[1]}&who=1`
                }
            } else if (index == 6) { // 店铺
                let info = {
                    store_name: item.storeName,
                    store_avatar: item.storeAvatar,
                }
                location.href = `${this.link}pages-user/shopIndex/stippleIndex?type=店铺&store_id=${item.storeId}&token=${localStorage.getItem('token').split(' ')[1]}&who=1&info=${JSON.stringify(info)}`
            } else if (index == 5) {
                location.href = `${this.link}pages-user/order/orderDetails?orderNum=${item.orderNum}&storeLeixing=${item.storeLeiXing}&goodsTypes=${item.goods_type}&goodsStatus=${item.orderStatus}&lastnum=${item.writeOffNum}&token=${localStorage.getItem('token').split(' ')[1]}&who=1`
            } else if (index == 7) { // 网点
                let info = {
                    network_name: item.networkName,
                    network_avatar: item.networkAvatar,
                }
                location.href = `${this.link}pages-user/shopIndex/stippleIndex?type=网点&networkId=${item.networkId}&token=${localStorage.getItem('token').split(' ')[1]}&who=1`
            } else if (index == 1) {
                //   index == 1 好友详情
                this.popup_show = false
                this.$router.push({
                    path: '/blackListDetails',
                    query: {
                        account: item.account || item.uuid ,
                        num:123, // 这里是给个固定值是为了 判断首页页面从哪跳过来的  解决缓存问题
                    }
                })
            } else if (index == 2) {

                //   index == 2 群聊
                this.popup_show = false
                this.$emit('qiehuan', item)
            } else if (index == 3) {
                //   聊天记录
                this.popup_show = false
                this.$emit('qiehuan2', item)
            }
        },

        // 功能跳转
        capabilities(item) {
            console.log(item.id);
            if (item.id == 1) { // 1 收付款
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/Collection')
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    })
                        .then(() => {
                            this.$router.push('/Real_name')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (item.id == 2) { // 2 扫一扫
                this.$router.push('/scan')
            } else if (item.id == 3) { // 3 添加好友
                this.popup_show = false
                this.$emit('qiehuan3', item.id)
            } else if (item.id == 4) { // 4 银行卡
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/Bank_card')
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    })
                        .then(() => {
                            this.$router.push('/Real_name')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (item.id == 5) { // 5 充值
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/recharge')
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    })
                        .then(() => {
                            this.$router.push('/Real_name')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (item.id == 6) { // 6 提现
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/withdrawal')
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    })
                        .then(() => {
                            this.$router.push('/Real_name')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (item.id == 7) { //  7 批量转账
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/BulkTransferIndex')
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    })
                        .then(() => {
                            this.$router.push('/Real_name')
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (item.id == 8) { // 8 定时转账

            } else if (item.id == 9) { // 9 对公管理
                // this.$router.push('/BulkTransferIndex')
            } else if (item.id == 10) { // 10 积分兑换
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push({
                        path: '/integral',
                        query: {
                            integral: 0.00,
                            serviceCharge: 0.01
                        }

                    })
                } else {
                    Dialog.confirm({
                        message: '您未实名，请先实名！',
                    }).then(() => {
                        this.$router.push('/Real_name')
                    }).catch(() => {
                        // on cancel
                    });
                }
            } else if (item.id == 11) {  // 11 设置支付密码
                this.$router.push('/Give_more')
            } else if (item.id == 12) { // 12 实名认证
                if (this.$user_info.userInfo.idNumber) {
                    this.$router.push('/Setting')
                } else {
                    this.$router.push('/Real_name')
                }

            }
        },

        // 更多群聊
        gengduo() {
            this.condition_id = 2,
                this.show_id = 1
            if (this.value != '') {
                this.$axios({
                    url: '/bkx-juxin-app/juxinGroupMember/getGroup',
                    method: 'GET',
                    params: {
                        keyword: this.value,
                        type: 1,
                        pageSize: '',
                        pageNum: ''

                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.group = res.data
                    } else {

                    }
                    console.log(res, '好他妈的困啊！')
                })
            } else {
                // this.$toast('不能为空')
            }


        },

        // 更多联系人
        gengduo_ren() {
            this.condition_id = 1,
                this.show_id = 1
            if (this.value != '') {
                this.$axios({
                    url: '/bkx-juxin-app/juxinGroupMember/getFriends',
                    method: 'GET',
                    params: {
                        keyword: this.value,
                        type: 1,
                        pageSize: '',
                        pageNum: ''

                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.friends = res.data
                        console.log(this.friends, 5555)
                    } else {

                    }
                })
            } else {
                // this.$toast('不能为空')
            }

        },
        xuanze(index) {
            this.condition_id = index,
                this.typea = 2
            //  1 联系人标签点击更多
            if (index == 1) {
                if (this.value == '') {
                    return
                } else {
                    this.$axios({
                        url: '/bkx-juxin-app/juxinGroupMember/getFriends',
                        method: 'GET',
                        params: {
                            keyword: this.value,
                            type: 1,
                            pageSize: '',
                            pageNum: ''

                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.friends = res.data
                            // 去重自己
                            // for (let i = 0; i < this.friends.length; i++) {
                            //     if (this.friends[i].account == this.$user_info.account.toString()) {
                            //         this.friends.splice(i, 1)
                            //     }
                            // }
                        } else {
                        }
                        console.log(res, '好他妈的困啊！')
                    })
                }

            } else if (index == 2) {
                if (this.value == '') {
                    return
                } else {
                    // 2 群聊
                    this.$axios({
                        url: '/bkx-juxin-app/juxinGroupMember/getGroup',
                        method: 'GET',
                        params: {
                            keyword: this.value,
                            type: 1,
                            pageSize: '',
                            pageNum: ''

                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.group = res.data
                        } else {
                        }
                        console.log(res, '好他妈的困啊！')
                    })
                }

            }
        },

        //  搜索的核心方法
        sousuo2() {
            // 重置上一次点击时间

            this.typea = 2
            this.friends = [], // 联系人
                this.goods = [], // 商品
                this.group = [], //群聊
                this.group_gengduo = [], //群聊更多
                this.netWork = [], //网点
                this.order = [], // 订单
                this.store = [] // 店铺
            this.Chat_record = [] // 聊天记录
            if (this.value.length == 11) {
                this.$axios({
                    url: "/bkx-base-user/juxinUser/getidentityByPhone",
                    method: "GET",
                    params: {
                        number: this.value,
                    },
                }).then(res => {
                    if (res.code == 200) {
                        this.friends = res.data
                    }
                })
            } else {
                if (this.value == '') {
                    this.typea = 1
                    return this.$toast('请输入搜索内容')
                }
                // 全部搜索的
                this.$axios({
                    url: "/bkx-shop-app/GoodsAllController/juXinQueryAll",
                    method: "GET",
                    params: {
                        content: this.value,
                        type: 0,
                    },
                }).then((res) => {
                    this.new_gongneng = []
                    this.friends = res.data.friends; // 联系人
                    // 去重自己
                    // for (let i = 0; i < this.friends.length; i++) {
                    //     if (this.friends[i].account == this.$user_info.account.toString()) {
                    //         this.friends.splice(i, 1)
                    //     }
                    // }
                    this.goods = res.data.goods; // 商品
                    this.group = res.data.group != null ? res.data.group : []; // 群聊
                    this.netWork = res.data.netWork ? res.data.netWork : []; // 网点
                    this.order = res.data.order; //订单
                    this.store = res.data.store ? res.data.store : []; // 店铺
                    for (let i = 0; i < this.gongneng.length; i++) {
                        if (this.gongneng[i].name.indexOf(this.value) != '-1') {
                            console.log('ok');
                            if (this.$user_info.roleType == 200) {
                                // 厂家
                                this.new_gongneng.push(this.gongneng[i])
                            } else if (this.$user_info.roleType != 200 && this.gongneng[i].name != '定时转账') {
                                //  其他身份
                                this.new_gongneng.push(this.gongneng[i])
                                console.log(this.new_gongneng, '偶买噶');
                            }
                        }
                    }
                });
            }
            this.Chat_record = []
            //  聊天记录
            this.$axios({
                url: "/bkx-shop-app/GoodsAllController/queryMsg",
                method: "GET",
                params: {
                    keyWord: this.value,
                    account: this.$user_info.account,
                },
            }).then(res => {
                console.log(res.data, 1020102);
                const map = new Map()
                const qc = res.data.filter(key => !map.has(key.From_Account) && map.set(key.From_Account, 1))
                qc.forEach((x) => {
                    x.list = []
                })
                res.data.forEach((i) => {
                    qc.forEach((x) => {
                        if (i.From_Account == x.From_Account) {
                            if (i.MsgBody[0].MsgContent.Text == x.MsgBody[0].MsgContent.Text) {
                                x.list.push(i.MsgBody[0].MsgContent.Text)
                            } else {
                                x.list.push(i.MsgBody[0].MsgContent.Text)
                            }
                        }
                    })
                })
                this.Chat_record.push(...qc)
            })
            //  群聊天记录
            this.$axios({
                url: "/bkx-shop-app/GoodsAllController/queryGroupMsg",
                method: "GET",
                params: {
                    keyWord: this.value,
                    account: null,
                },
            }).then(res => {
                const map = new Map()
                const qc = res.data.filter(key => !map.has(key.GroupId) && map.set(key.GroupId, 1))
                qc.forEach((x) => {
                    x.list = []
                })
                res.data.forEach((i) => {
                    qc.forEach((x) => {
                        if (i.GroupId == x.GroupId) {
                            if (i.MsgBody[0].MsgContent.Text == x.MsgBody[0].MsgContent.Text) {
                                x.list.push(i.MsgBody[0].MsgContent.Text)
                            } else {
                                x.list.push(i.MsgBody[0].MsgContent.Text)
                            }
                        }
                    })
                })
                this.Chat_record.push(...qc)
            })
        }
    },

};
</script>

<style>
.public_dian::-webkit-scrollbar {
    display: none
}

.shop_dian_body2 {
    font-size: 18px;
    color: #181818;
    margin: 20px 20px 20px 0;
}

.shop_dian_body2 .shop_dian_text {
    height: 30px;
    font-size: 26px;
}

.sou_top {
    position: fixed;
    width: 100%;
    height: 116px;
    background-color: #f2f2f5;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.sou_body {
    padding: 0 20px;
}

.top_left {
    width: 650px;
    height: 76px;
    background-color: #fff;
    border-radius: 36px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 20px;
    align-items: center;
}

.top_right {
    color: #fff;
    font-size: 30px;
    line-height: 64px;
    background-color: #FC9013;
    width: 112px;
    border-radius: 36px;
    text-align: center;
}

.public_text {
    font-size: 30px;
    color: #666;

}

.public_more {
    font-size: 28px;
    margin-top: 24px;
    color: #fc9013;
}

.public_body {
    display: flex;
    padding: 20px 0 0 0;
}

.public_info {
    width: 100%;
    margin-left: 20px;
    border-bottom: 2px #eee solid;
    padding: 0 0 20px 0;
}

.public_info_two {
    width: 100%;
    margin-left: 20px;
    border-bottom: 2px #eee solid;
    /* border-bottom: 2px #eee solid; */
    padding: 0 0 30px 0;
}

.public_tltie {
    font-size: 28px;
    color: #181818;
}

.public_nicheng {
    font-size: 24px;
    color: #999;
}

.public_dian {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 30px;

}

.public_dian_body {
    text-align: center;
    font-size: 30px;
    line-height: 50px;
}

.public_dian_body img {
    border-radius: 4px;
    width: 92px;
    height: 92px;
    margin-top: 24px;
    border-radius: 50%;
}

.shop_dian_body {
    font-size: 18px;
    color: #181818;
    margin-right: 20px;
}

.shop_dian_body img {
    width: 68px;
    height: 120px;
    border-radius: 4px;
}

.shop_dian_text {
    width: 88px;
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.xian {
    height: 2px;
    background-color: #eee;
    margin-top: 20px;
}

.tiaoxing {
    height: 20px;
    background-color: #eee;
    margin-top: 24px;
}

.bottom {
    background-color: #fff;
    border-top: 1px solid #eee;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2002;
}

.public {
    margin-top: 10px;
}

.bottom_top {
    padding: 0 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
}

.bottom_top img {
    display: block;
    width: 58px;
    height: 58px;
}

.ju_img {
    border: 10px solid #fff;
    border-radius: 50%;
}

.bottom .van-tabbar--fixed {
    position: static;
}

/* 搜索0 */
.van-search .van-search__content {
    border: 2px solid #fa902c;
    border-radius: 8px 8px 8px 8px;
    background-color: #fff;
}

.van-field__body {
    font-size: 28px;
    color: #999999;
    font-weight: 400;
}

.xuan_sou {
    padding: 10px 20px;
    background-color: #fa902c;
    color: #fff;
    text-align: center;
    font-size: 26px;
    border-radius: 26px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 20px;
}

.wei_sou {
    padding: 8px 18px;
    color: #fa902c;
    text-align: center;
    font-size: 26px;
    border-radius: 26px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 20px;
    border: 2px #fa902c solid;
}
</style>
