<template>
    <div>
        <van-popup v-model="password_show" :overlay="true" @click-overlay="password_show = false"
            style="width: 100%;height: 100vh;background-color: rgba()">
            <!-- 密码框 -->
            <div class="password_box">
                <div class="password_body">
                    <div style="display: flex;justify-content: space-between;align-items: center">
                        <img src="@/assrts/379.png" style="width: 18px;height: 18px;margin-left: 13px">
                        <div class="top_text">请输入支付密码</div>
                        <div style="width: 18px"></div>
                    </div>
                    <div class="password_title">{{ title }}</div>
                    <div style="display: flex;justify-content: space-between">
                        <div></div>
                        <div class="password_price" style="display: flex;align-items: center;">
                            <div v-if="id == 1">￥</div>
                            {{ price }}
                        </div>
                        <div></div>
                    </div>
                    <div v-if="mes" style="color: #F9310D;font-size: 14px;">{{ mes }}<span v-if="num">,你还可以输入{{ num
                    }}次</span></div>

                    <div style="height: 1px;background-color: #EFEFEF;margin: 0 auto;margin-bottom: 10px;width: 280px">
                    </div>
                    <div class="fangshi" v-if="id">
                        <div>{{ title ? '支付方式' : '手续费' }}</div>
                        <div v-if="title">{{ id == 1 ? '现金' : id == 2 ? '代金券' : id == 3 ? '积分' : id == 4 ? '预付款' : '未知' }}
                            <span v-if="balance">
                                <span>(</span>余额{{ id == 1 ? (balance.cash / 100).toFixed(2) : id == 2 ? (balance.money / 100).toFixed(2) : id
                                    == 3
                                    ? (balance.integral / 100).toFixed(2) : '未知' }}<span>)</span>
                            </span>
                        </div>
                        <div v-else>0.00</div>
                    </div>
                    <van-password-input :value="value" :gutter="10" :focused="password_show"
                        @focus="password_show = true" />
                    <div v-if="mes" style="font-size: 14px;font-weight: bold;color: #333333; margin-top:10px"
                        @click="forgot_password">
                        忘记密码</div>

                </div>
            </div>
            <!-- 数字键盘 -->
            <van-number-keyboard v-model="value" :show="password_show" @blur="close" />
        </van-popup>
    </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
    name: "password_page",
    props: ['showKeyboard', 'price', 'title', 'id', 'but_no', 'new_pass', 'balance'],
    data() {
        return {
            value: '', // 密码
            password_show: false,
            num: '',// 次数
            mes: '',// 密码错误提示语
        }
    },
    mounted() {

    },
    watch: {
        showKeyboard(news) {
            if (news == true) {
                this.password_show = news
            }
        },
        value(news) {
            if (news.length == 6) {
                if (this.but_no) {
                    this.$emit('payment', news)
                } else {
                    this.$axios({
                        url: '/bkx-base-user/juxinUserInfo/verifyPayPsw',
                        method: 'GET',
                        params: {
                            payPassword: this.$md5(news),
                            type: 1
                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.$emit('payment', res)
                            this.$emit('passwordNumber', this.value)
                        } else if (res.code == 500) {
                            if (this.$user_info.userInfo.payPasswordStatus == 0) {
                                this.$toast('未设置支付密码')
                                setTimeout(() => {
                                    this.$router.push({
                                        path: '/Give_shezhi',
                                        query: {
                                            id: 1
                                        }
                                    })
                                }, 1000)
                            } else {
                                this.value = ''
                                this.num = res.data
                                this.mes = res.msg
                                if (res.data == 0) {
                                    Dialog.confirm({
                                        message: `支付密码输入错误次数过多，请点击忘记密码或者${res.msg}`,
                                        confirmButtonText: '忘记密码',
                                        cancelButtonText: '重试',
                                        confirmButtonColor: '#FF9113',
                                        cancelButtonColor: '#999999',
                                    })
                                        .then(() => {
                                            this.$router.push('/Give_forget')
                                        })
                                        .catch(() => {
                                            // on cancel
                                        });

                                }

                                // this.$toast(res.msg)
                            }
                        }
                    })
                }
            }
        },
        new_pass(news) {
            this.value = ''
        }
    },
    methods: {
        close() {
            this.value = ''
            this.password_show = false
        },
        //忘记密码
        forgot_password(){
            this.$router.push('/Give_forget')
        }
    }
}
</script>

<style scoped>
/* 密码框 */
.password_box {
    margin-top: 100px;
    padding: 60px;
    box-sizing: border-box;
    text-align: center;
}

.top_text {
    font-size: 32px;
    color: #333;
    margin: 14px 0 14px 0;
}

.password_body {
    background-color: #fff;
    border-radius: 44px;
    padding: 30px 0;
}

.fangshi {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px 30px 40px;
    color: #999;
    font-size: 28px;
}

.close_password_box {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 40px;
    background: url('@/assrts/close.png') no-repeat;
    background-size: 100%;
}

.password_title {
    font-size: 32px;
    color: #333;
}

.password_price {
    color: #333;
    font-size: 82px;
    margin-bottom: 20px;
    margin-top: 6px;
}

/* 支付弹窗确认按钮颜色 */
/deep/ .van-key--blue {
    background-color: #FC9013;
}

/deep/ .van-popup {
    background-color: rgba(171, 169, 169, 0);
}

/deep/ .van-password-input__security li {
    background-color: #EFEFEF;
}
</style>
