<template>
    <div>
        <div class="aa" style="flex: 1;background: #F2F2F5;">
            <div style="background-color: #fff;">
                <van-tabs v-model="active" @click="getList">
                    <van-tab title="好友"></van-tab>
                    <van-tab title="群聊"></van-tab>
                    <van-tab title="新的朋友" :badge="NewFriendNumber==0?'':NewFriendNumber"></van-tab>
                    <van-tab title="添加朋友"></van-tab>
                </van-tabs>
            </div>
            <div style="height: 67vh;overflow: hidden;overflow-y: auto"
                 v-if="(active == 0 && area_list.length != 0) || active == 1">
                <!-- 好友和群聊 -->
                <!-- {{ area_list }} -->
                <!-- 创建群聊 -->
                <div class="buildGroup_box" v-if="active == 1" @click.stop="goBuildGroup">
                    <div style="display: flex; align-items: center;">
                        <img src="../../assrts/groupAdd.png" alt="" style="width: 32px;height: 32px;">
                        <div class="buildGroup_title">
                            <p style="margin-bottom: 5px; color: #000;">发起群聊</p>
                            <p style="color: #999;">与朋友进入同一个群聊</p>
                        </div>
                    </div>
                    <span class="right"></span>
                </div>
                <van-index-bar :index-list="$book_list" :sticky="false" ref="IndexBar" @change="changeBar">
                    <div v-for="(item, index) in area_list" :key="index" style="margin:0;">
                        <van-index-anchor :index="item.name"/>
                        <div style="display:flex;flex-wrap: wrap; justify-content: space-between;">
                            <div v-for="(itam, index) in item.list" :key="index" class="aaaa" @click="goUser(itam)">
                                <!-- 头像盒子 -->
                                <div class="picture"
                                     style="width:44px; height:44px;margin:14px auto 0px;border-radius: 4px 4px 4px 4px;">
                                    <img :src="itam.avatar" alt=""
                                         style="width:100%;height:100% ;border-radius: 4px 4px 4px 4px;object-fit: cover;">
                                </div>
                                <!-- 备注 -->
                                <div class="Remark" style="font-size: 14px;text-align: center;color: #000000;">
                                    {{ itam.remark ? itam.remark : itam.nickName }}
                                </div>
                            </div>
                        </div>
                    </div>
                </van-index-bar>
                <p v-if="active == 0 && area_list.length != 0" class="goodsNumber">{{ GoodsNumber }}个朋友</p>

                <!-- 群聊推荐 -->
                <div v-if="active == 1 && lengtha != 0" style="font-size:14px;   text-align: center; margin-top:15px;">
                    {{ lengtha }}个群聊
                </div>

                <div v-if="active == 1 && groupList.length != 0" class="push_text2" style="margin: 10px 0;">群聊推荐
                </div>
                <div v-if="active == 1" class="push_body2">
                    <div class="push_one" v-for="(item, index ) in groupList" :key="index">
                        <div class="push_avatar">
                            <img :src="item.avatar" style="object-fit: cover; width: 46px;height: 46px;">
                        </div>
                        <div class="push_name">{{ item.name }}</div>
                        <button :class="item.status == 1 ? 'push_tianActive' : 'push_tianNone'"
                                :disabled="item.status != 1"
                                @click="addGroup(item, 1)">{{
                            item.status == 1 ? '申请' : item.status == 2 ? '已申请' : '已加入'
                            }}
                        </button>
                    </div>
                </div>
                <p v-if="active == 1 && groupList.length != 0" class="ChangeABatch"
                   @click.stop="upList('推荐群聊')"><span
                        @click.stop="upList('推荐群聊')" class="ChangeABatchAnimation"
                        :style="rotate == true ? 'animation-play-state:running' : 'animation-play-state:paused'"></span>
                    换一批
                </p>
                <div style="height: 20px;"></div>
            </div>
            <p class="none_Data" v-if="active == 0 && area_list.length == 0">暂无好友</p>
        </div>


        <!-- 新的朋友 -->
        <div v-if="active == 2" style="padding: 0 0 0 10px; box-sizing: border-box; position: relative;  margin-top:10px;background-color: #fff;
				height: 67vh;overflow: hidden;overflow-y: auto
			 ">

            <div class="Last_three">
                <div v-if="LastThreeDays.length != 0" style="color: #666666;
                                        font-weight: 400;
                                        font-size: 12px;
                                        margin-top: 10px;
                                        ">近三天
                </div>
                <div v-show="LastThreeDays.length != 0" class="Add_reject2"
                     style="display: flex; justify-content: space-between; box-sizing: border-box; border-bottom: 1px solid #EEEEEE;"
                     v-for="(item, index) in LastThreeDays" :key="index">
                    <!-- 头像 名称 -->
                    <div class="Avatar_name" style="display: flex;align-items: center;">
                        <div style="width:40px; height: 40px;  border-radius:4px 4px 4px 4px;">
                            <img class="Good_avatar" :src="item.avatar" style="object-fit: cover;" alt=""
                                 @click.stop="goUser2(item.fromAccount)">
                        </div>

                        <p style="margin:0 0 0 10px;padding: 0;font-size: 14px;"
                           @click.stop="goUser2(item.fromAccount)">{{
                            item.nikeName
                            }}</p>
                    </div>

                    <!-- 添加、拒绝 / 已添加 -->
                    <div class="Add_reject" style="display: flex; margin:auto 0;">
                        <div v-if="item.status == 1 || item.status == 2"
                             style="font-size: 14px;line-height: 26.5px; margin-right: 10px;color: #999999;">
                            {{ item.status == 1 ? '已添加' : '已拒绝' }}
                        </div>

                        <template v-if="item.status == 0">
                            <!-- 添加 -->
                            <div @click.stop="agree(item)" style="width: 48px;
                                                height: 26.5px;
                                                background: #FC9013;
                                                border-radius: 4px 4px 4px 4px;
                                                font-size: 12px;
                                                color: #FFFFFF;
                                                text-align: center;
                                                line-height: 26.5px;
                                                margin-right: 10px;
                                                ">
                                添加
                            </div>
                            <!-- 拒绝 -->
                            <div @click.stop="refuse(item)" style="width: 48px;
                                                height: 26.5px;
                                                background: #EEEEEE;
                                                border-radius: 4px 4px 4px 4px;
                                                font-size: 12px;
                                                color: #999999;
                                                text-align: center;
                                                line-height: 26.5px;
                                                ">
                                拒绝
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <!-- 三天前 -->
            <div v-if="ThreeDaysAgo.length != 0" class="Last_three">
                <div style="color: #666666;
                                        font-weight: 400;
                                        font-size: 12px;
                                        margin-top: 10px;
                                        ">三天前
                </div>
                <div v-show="ThreeDaysAgo.length != 0" class="Add_reject2" v-for="(item, index) in ThreeDaysAgo"
                     :key="index">
                    <!-- 头像 名称 -->
                    <div class="Avatar_name" style="display: flex;align-items: center;">
                        <div style="width:40px; height: 40px;  border-radius:4px 4px 4px 4px;">
                            <img class="Good_avatar" :src="item.avatar" alt="" @click.stop="goUser2(item.fromAccount)">
                        </div>

                        <p style="margin:0 0 0 10px;padding: 0;font-size: 14px;"
                           @click.stop="goUser2(item.fromAccount)">{{
                            item.nikeName
                            }}</p>
                    </div>

                    <!-- 添加、拒绝 / 已添加 -->
                    <div class="Add_reject" style="display: flex; margin:auto 0;">
                        <div v-if="item.status == 1 || item.status == 2"
                             style="font-size: 14px;line-height: 26.5px; margin-right: 10px;color: #999999;">
                            {{ item.status == 1 ? '已添加' : '已拒绝' }}
                        </div>

                        <template v-else>
                            <!-- 添加 -->
                            <div @click="agree(item)" style="width: 48px;
                                                height: 26.5px;
                                                background: #FC9013;
                                                border-radius: 4px 4px 4px 4px;
                                                font-size: 12px;
                                                color: #FFFFFF;
                                                text-align: center;
                                                line-height: 26.5px;
                                                margin-right: 10px;
                                                ">
                                添加
                            </div>
                            <!-- 拒绝 -->
                            <div @click="refuse(item)" style="width: 48px;
                                                height: 26.5px;
                                                background: #EEEEEE;
                                                border-radius: 4px 4px 4px 4px;
                                                font-size: 12px;
                                                color: #999999;
                                                text-align: center;
                                                line-height: 26.5px;
                                                ">
                                拒绝
                            </div>
                        </template>
                    </div>
                </div>

            </div>
            <!--  -->
            <p class="none_Data" v-if="ThreeDaysAgo.length == 0 && LastThreeDays.length == 0">暂无好友申请</p>
            <div v-if="ThreeDaysAgo.length > 10" style="height: 150px;"></div>
        </div>

        <!-- 添加朋友 -->
        <div v-if="active == 3" class="Add" style="height: 67vh;overflow: hidden;overflow-y: auto">
            <div class="Search">
                <input type="text" style="" @keyup="sousuo = sousuo.replace(/[^\d]/g, '')" v-model="sousuo"
                       placeholder="聚信号/手机号" @keydown.enter="SearchList">
                <!-- v-if="!sousuo" -->
                <img src="@/assrts/sousuo.png">
            </div>
            <!--  -->
            <div v-for="searchList in searchList" :key="searchList.uuid">
                <div class="Search_list_box" v-if="searchList.length != 0" @click.stop="DetailsJump(searchList.uuid)">
                    <img class="Search_img" :src="searchList.avatar" alt="">
                    <p class="SearchTitle">{{ searchList.nickName }}
                        <span>{{
                            searchList.type == 1 ? '(会员)' : searchList.type == 2 ? '(创建商城人)' :
                                searchList.type == 3 ? '(厂家)' : searchList.type == 4 ? '(网点)' : searchList.type == 5 ? '(店铺)' :
                                    searchList.type == 6
                                        ? '(推广员)' : searchList.type == 7 ? '(厂家子账号)' : searchList.type == 8 ? '(网店子账号)' :
                                            searchList.type == 9 ?
                                                '(网点子账号)' :
                                                searchList.type == 421 ? '(用户中心)' : searchList.type == 422 ? '(网点中心)' : searchList.type == 423 ?
                                                    '(产品中心)' :
                                                    searchList.type == 424 ? '(商贸中心)' : searchList.type == 431 ? '(产品经理)' : searchList.type == 432 ?
                                                        '(网点经理)' :
                                                        searchList.type == 433 ? '(商贸经理)' : searchList.type == 434 ? '(用户经理)' : searchList.type == 420 ?
                                                            '(运营中心)'
                                                            : searchList.type == 400 ? '(商城创始人)' : ''
                            }} </span>
                    </p>
                    <button v-if="searchList.isFriends!=1" class="addFriends_butt" @click.stop="addFriends(searchList)">
                        添加
                    </button>
                </div>
            </div>

            <p class="SearchTitle" v-if="searchType == 1">该用户不存在</p>
            <!-- 扫一扫 -->
            <div class="Scan" @click.stop="scan">
                <div class="left">
                    <div class="icon"></div>
                    <div>
                        <div style="margin-top: 15px">扫一扫</div>
                        <p>扫描二维码</p>
                    </div>
                </div>
                <!-- 右侧小箭头 -->
                <div class="rigth"></div>
            </div>
            <div class="push_detalis" v-if="friendList.length != 0">
                <div class="push_text">好友推荐</div>
                <div class="push_body">
                    <div class="push_one" v-for="(item, index) in friendList" :key="index">
                        <div class="push_avatar">
                            <img :src="item.avatar" style="object-fit: cover; width: 46px;height: 46px;">
                        </div>
                        <div class="push_name">{{ item.nickName }}</div>
                        <button :class="item.check == true ? 'push_tianActive' : 'push_tianNone'"
                                :disabled="item.check == false" @click="AddMember(item)">{{
                            item.check == true ? '添加' : '已添加'
                            }}
                        </button>
                    </div>

                </div>

            </div>
            <p v-if="active == 3 && friendList.length != 0" class="ChangeABatch" @click.stop="upList('好友推荐')"><span
                    @click.stop="upList('好友推荐')" class="ChangeABatchAnimation"
                    :style="rotate == true ? 'animation-play-state:running' : 'animation-play-state:paused'"></span>
                换一批
            </p>
            <div style="height: 20px;"></div>
        </div>
    </div>
</template>

<script>
import {Toast} from 'vant';

export default {

    props: {
        // 子组件接受传过来的3
        act: {
            style: Number,
            default: ''
        }
    },
    created() {
        //  接收到传过来的3  赋值给active
        console.log(this.act, 123456)
        this.active = this.act
    },
    activated() {
        setTimeout(() => {
            this.$refs.IndexBar.scrollTo(this.$route.meta.scrollTop)
        }, 500)
        // this.barIndex = this.$route.meta.scrollTop
        // console.log(this.$route.meta.scrollTop,'this.barIndexthis.barIndexthis.barIndexthis.barIndexthis.barIndex')
    },
    mounted() {
        // 从添加好友详情回来 如果有title值则返回添加朋友页面
        if (this.$route.query.title == '添加朋友') {
            this.active = 3
            this.getList(3, '添加朋友');
            this.$route.query.title = ''
        } else if (this.$route.query.ida) {
            this.active = 2
            this.getList(2, '新的朋友');
        } else {
            this.getList(0, '好友');
        }
        // 轮询查询新的朋友
        this.getNewList()
    },
    data() {
        return {
            //好友 群聊
            active: 1, // 0 好友 1 群聊 2 新朋友 3添加朋友
            area_list: [],
            lengtha: '',
            //---------------------------------------
            type: null,  // 为 1 已添加  2 就是 已拒绝
            // 新的朋友
            LastThreeDays: [], //近三天
            ThreeDaysAgo: [], //三天前
            //---------------------------------------
            sousuo: '', //搜索
            searchType: 0, //搜搜数据显示
            searchList: '', //搜索
            friendList: [], //好友推荐
            groupList: [], // 群推荐
            rotate: false, //换一批控制器
            lastTime: 0, // 默认上次点击时间
            GoodsNumber: '',// 共多少好友展示
            NewFriendNumber: 0, //新的朋友人数展示
            // 通讯录列表默认选中的索引
            barIndex: '',
        }
    },

    methods: {
        /**
         * 当通讯录索引发生改变时向父组件回调
         * */
        changeBar(e) {
            this.$emit('changeBar', e)
        },
        // 获取新朋友个数
        getNewList() {
            this.$axios({
                url: 'bkx-juxin-app/juxinFriendRelationship/queryFriendNum',
                methods: 'GET'
            }).then(res => {
                this.NewFriendNumber = res.data
            })
        },
        // 获取好友|群聊数据|好友申请|添加朋友
        getList(name, title) {
            this.sousuo = '' //清空搜索
            this.area_list = []
            if (title == '好友') {
                this.$axios({
                    url: `bkx-juxin-app/juxinFriendRelationship/getFriends`,
                    methods: 'GET',
                    params: {
                        account: this.$user_info.account
                    }
                }).then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].account == 10069622962) {
                            res.data.splice(i, 1)
                        }
                        if (res.data[i].account == this.$user_info.account) {
                            res.data.splice(i, 1)
                        }
                    }
                    if (res.code == 200) {
                        if (res.data.length == 0) {
                            this.area_list = []
                            // console.log(123123123);
                            // console.log(this.area_list, '123123123123');
                        } else {
                            for (let i = 0; i < res.data.length; i++) {
                                if (res.data[i].account == this.$user_info.account) {
                                    res.data.splice(i, 1)
                                }
                                if (res.data[i].isBlackList == 1) {
                                    res.data.splice(i, 1)
                                }
                            }
                            this.GoodsNumber = res.data.length
                            console.log(this.GoodsNumber, '人数');
                            // 调用全局main.js方法
                            this.area_list = this.processing_book(res.data, this.$book_list)
                            console.log('好友', this.area_list)

                        }
                    }

                })
            } else if (title == '新的朋友') {
                // console.log('新的朋友')
                this.$axios({
                    url: 'bkx-juxin-app/juxinFriendRelationship/queryFriendApplicationList',
                    methods: 'GET'
                }).then(res => {
                    console.log(res)
                    // 获取当前时间
                    let d = new Date();
                    this.LastThreeDays = []
                    this.ThreeDaysAgo = []
                    for (let i = 0; i < res.data.length; i++) {
                        let time = Date.parse(new Date(res.data[i].createTime))
                        let rangeDateNum = (d.getTime() - time) / 1000 / 3600 / 24;
                        // console.log(rangeDateNum, 'rangeDateNum')
                        // // res.data[i].createTime
                        if (rangeDateNum < 3) {
                            this.LastThreeDays.push(res.data[i])
                        } else {
                            this.ThreeDaysAgo.push(res.data[i])
                        }
                    }

                    console.log(this.LastThreeDays, '三天内')
                    console.log(this.ThreeDaysAgo, '三天前')
                })
                this.$axios({
                    url: 'bkx-juxin-app/juxinFriendRelationship/queryFriendSetZero',
                    methods: 'GET'
                }).then(res => {
                    if (res.code == 200) {
                        this.NewFriendNumber = 0
                    }
                    console.log(res, 'jjjjjjjj');
                })
            } else if (title == '群聊') {
                this.$axios({
                    url: `bkx-juxin-app/juxinGroup/selectJuxinGroupList`,
                    methods: 'GET',
                    params: {
                        account: this.$user_info.account
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.lengtha = res.data.length
                        if (res.data.length !== 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                this.$set(res.data[i], 'nickName', res.data[i].name)
                                for (let j = i + 1; j < res.data.length; j++) {
                                    if (res.data[i].uuid == res.data[j].uuid) {
                                        res.data.splice(j, 1)
                                    }
                                }
                            }
                            console.log('群聊res', res)
                            // 调用全局main.js方法
                            this.area_list = this.processing_book(res.data, this.$book_list)
                            console.log(this.area_list, 'this.area_list')
                        } //else {
                        //     Toast('暂无群聊')
                        // }
                    }
                    // console.log('数据', this.area_list)
                })
                if (this.$user_info.userInfo.isRecommendGroup == 1) {
                    // 推荐群聊
                    this.$axios.post('bkx-juxin-app/SayHello/push', {
                        type: 1,
                        account: this.$user_info.account
                    }).then(res => {
                        console.log('推荐群聊', res)
                        this.groupList = res.data
                        // console.log(this.groupList)
                    })
                }

            } else if (title == '添加朋友' && this.$user_info.userInfo.isRecommendUser == 1) {
                //推荐好友
                this.$axios.post('bkx-juxin-app/SayHello/push', {
                    type: 2,
                    account: this.$user_info.account
                }).then(res => {
                    console.log('好友', res)
                    if (res.code == 200) {
                        // 数据处理- 如果status == 2隐藏
                        for (let i = 0; i < res.data.length; i++) {
                            this.$set(res.data[i], 'check', true)
                        }
                        this.friendList = res.data
                        console.log(this.friendList)
                    }
                })


            }
        },
        // 通过好友申请
        agree(item) {
            console.log(item)
            this.$axios({
                url: 'bkx-juxin-app/juxinFriendRelationship/friendReview',
                methods: 'GET',
                params: {
                    id: item.id,
                    status: 1,

                }
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    item.status = 1
                    // this.getList(2, '新的朋友');

                    Toast('添加成功')

                }
            })
        },
        // 拒绝好友添加
        refuse(item) {
            console.log(item)
            this.$axios({
                url: 'bkx-juxin-app/juxinFriendRelationship/friendReview',
                methods: 'GET',
                params: {
                    id: item.id,
                    status: 2,
                }
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    item.status = 2
                    // this.getList(2, '新的朋友');
                }

            })
        },
        goUser(item) {
            console.log(item, 7531468486464165)
            if (this.active == 1) {
                this.$axios({
                    url: '/bkx-juxin-app/dialogue/addGroupDialogue',
                    method: '',
                    params: {
                        account: this.$user_info.account,
                        type: item.type,
                        groupId: item.uuid
                    }
                }).then(res => {
                    if (res.code == 200) {
                        console.log(item);
                        // console.log(this.active);
                        var arr = {
                            item: item,
                            active: this.active,
                        }
                        this.$emit('qiehuan', arr)
                        sessionStorage.setItem('uuid', item.uuid)
                    } else {
                        this.$toast(res.msg)
                    }
                })
            } else {
                this.$router.push({
                    path: `/blackListDetails`,
                    query: {
                        account: item.account,
                    }
                })
            }
        },
        scan() {
            this.$router.push({
                path: "/scan",
                query: {
                    url: '/',
                },
            });
        },
        // 搜索事件
        SearchList() {
            if (this.sousuo == '') {
                Toast('请输入要查找的用户/手机号')
            } else {
                this.$axios({
                    url: '/bkx-base-user/juxinUser/getidentityByPhone',
                    methods: 'GET',
                    params: {
                        number: this.sousuo,
                    }
                }).then(res => {
                    console.log(res, 666)
                    if (res.code == 200) {
                        this.searchList = res.data
                        console.log(this.searchList)
                        if (this.searchList.length == 0) {
                            this.searchType = 1
                        } else {
                            this.searchType = 0
                        }
                    }
                    if (res.code == 500) {
                        Toast(res.msg);
                        if (res.msg == '未查询到此数据') {
                            this.searchType = 1
                            console.log(this.searchType)
                        }
                    }
                })
            }

        },
        // 搜索-添加好友
        addFriends(searchList) {
            console.log(searchList);
            this.$axios.post('/bkx-juxin-app/juxinFriendRelationship', {
                toAccount: searchList.uuid,
                remark: '',
                notLook: 0,
                notLetLook: 0,
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.$toast('成功')
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 申请加群
        addGroup(item) {

            this.$axios.post('/bkx-juxin-app/SayHello/say', {
                account: this.$user_info.account,
                groupIds: item.uuid,
                type: 1,
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    // console.log('status', item.status);
                    this.$axios({
                        url: '/bkx-juxin-app/juxinGroup/queryGroupNotice',
                        method: 'GET',
                        params: {
                            groupId: item.uuid
                        }
                    }).then(res => {
                        console.log(res);
                        if (res.code == 200) {
                            if (res.data.verifyJoin == 0) {
                                Toast('加入成功')
                                item.status = 3
                            } else if (res.data.verifyJoin == 1) {
                                Toast('等待管理员审核')
                                item.status = 2
                            }
                        }
                    })
                    // 重新获取推荐群聊数据
                    this.$axios({
                        url: `bkx-juxin-app/juxinGroup/selectJuxinGroupList`,
                        methods: 'GET',
                        params: {
                            account: this.$user_info.account
                        }
                    }).then(res => {
                        if (res.code == 200) {
                            if (res.data.length !== 0) {
                                for (let i = 0; i < res.data.length; i++) {
                                    this.$set(res.data[i], 'nickName', res.data[i].name)
                                }
                                // 调用全局main.js方法
                                this.area_list = []
                                this.area_list = this.processing_book(res.data, this.$book_list)
                                console.log(this.area_list, 'this.area_list')
                            } else {
                                // Toast('暂无群聊')
                            }
                        } else {
                            Toast(res.msg)
                        }
                    })
                } else {
                    this.$toast(res.msg)
                }
            })


        },
        // 添加人
        AddMember(info) {
            console.log(info);
            // 点击时让按钮变为灰色
            // info.status = 1
            // 走添加好友接口
            // this.$axios.post('bkx-juxin-app/juxinFriendRelationship', {
            //     toAccount: info.account,
            //     remark: '',
            //     applicationInformation: null,
            //     notLook: 0,
            //     notLetLook: 0
            // }).then(res => {
            //     console.log(res);
            //     if (res.code == 200) {
            //         info.check = false

            //     }
            // })
            // console.log(typeof(info.account.toString()));
            this.$axios.post('/bkx-juxin-app/SayHello/say', {
                toAccounts: info.account.toString(),
                groupIds: '',
                type: 2,
            }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    info.check = false
                } else if (res.code == 500) {
                    this.$toast(res.msg)
                }
            })
        },
        DetailsJump(account) {
            this.sousuo = ''
            this.$router.push({
                path: '/blackListDetails',
                query: {
                    account: account,
                    ida: 3
                }
            })
        },
        upList(item) {
            // 推荐群聊
            this.rotate = true
            if (item == '推荐群聊') {
                this.$axios.post('bkx-juxin-app/SayHello/push', {
                    type: 1,
                    account: this.$user_info.account
                }).then(res => {
                    // console.log('群聊', res)
                    if (res.code == 200) {
                        setTimeout(() => {
                            this.rotate = false
                            this.groupList = []
                            this.groupList = res.data
                            console.log(this.groupList)
                        }, 1000)
                    }
                })
            } else {
                //推荐好友
                this.$axios.post('bkx-juxin-app/SayHello/push', {
                    type: 2,
                    account: this.$user_info.account
                }).then(res => {
                    // console.log('好友', res)
                    if (res.code == 200) {
                        for (let i = 0; i < res.data.length; i++) {
                            // if (res.data[i].status == 2) {
                            //     res.data[i].splice(i, 1)
                            // }
                            this.$set(res.data[i], 'check', true)
                        }

                        this.rotate = false
                        this.friendList = res.data
                        console.log(this.friendList)
                    }
                })
            }
        },
        // 创建群聊
        goBuildGroup() {
            this.$router.push('/buildGroup')
        },
        // 新的朋友-去到个人详情
        goUser2(fromAccount) {
            this.$router.push({
                path: '/blackListDetails',
                query: {
                    account: fromAccount,
                    id: 0,
                    ida: 2
                }
            })
        },
    }
}
</script>

<style scoped>
.none_Data {
    margin: 0;
    height: 70vh;
    text-align: center;
    padding-top: 500px;
    box-sizing: border-box;
    font-weight: bold;
    /* background: #efefef ; */
    font-size: 28px;
    color: #999;
    /* position: absolute;
    left: 38%;
    top: 50%; */
}

/* 修改侧边栏位置 */
/deep/ .van-index-bar__index {
    margin-bottom: 5px;
}

/* A-Z定位 */
/deep/ .van-index-bar .van-index-bar__sidebar {
    position: fixed;
    top: 57%;
    right: 0;
    z-index: 1;
}

/* 修改名称定位 */
/deep/ .van-index-anchor {
    z-index: 0;
}

.aaaa {
    display: flex;

    flex-direction: column;
    flex-wrap: wrap;
    /* align-items: center; */
    width: 330px;
    height: 180px;
    border-radius: 16px 16px 16px 16px;
    background: #FFFFFF;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

}

/* 头像框 */
.picture {
    margin-bottom: 10px;
}

.aa .van-tabs {
    width: 75%;

}

/* /好友  群聊  新朋友  添加朋友/  下滑的线 */
/deep/ .van-tabs .van-tabs__wrap .van-tabs__line {
    width: 64px;
    border-radius: 4px 4px 4px 4px;
    background: #FC9013;
    height: 8px;
    z-index: 0;
}

/* 创建群聊 */
.buildGroup_box {
    height: 145px;
    background: #fff;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    align-items: center;
}

.buildGroup_title {
    font-size: 26px;
    margin-left: 25px;
}

.buildGroup_title p {
    margin: 0;
}

.right {
    display: block;
    width: 40px;
    height: 40px;
    background: url('../../assrts/right-1.png') no-repeat;
    background-size: 100%;
}

/* 据信号手机号的大盒子 */
.Add .Search {
    position: relative;
    margin: 20px 20px;
    height: 72px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    font-size: 28px;
    display: flex;
    padding-left: 70px;

}

.Add .Search img {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
}

.Add .Search input {
    flex: 1;
    padding: auto;
    border: 0;
    /* padding-left: 30px; */
    border-radius: 10px;
    box-sizing: border-box;
    /* text-indent: 30px; */
}

::-webkit-input-placeholder {
    text-align: center;
    color: #CCCCCC;
}


/* 扫一扫 */
.Scan {

    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.Scan .left {
    display: flex;
}

/* 最左边扫一扫的的图片 */
.Scan .left .icon {
    width: 64px;
    margin: 40px 30px 51px 20px;
    border-radius: 8px 8px 8px 8px;
    background-image: url(@/assrts/262.png);
    background-size: cover;
    height: 64px;
}

/* 扫一扫 */
.Scan .left :nth-child(2) {

    font-size: 32px;
}

.Last_three {
    overflow: hidden;
}

/* 扫一扫 */
.Scan .left :nth-child(2) p {
    margin: 0;
}

.Scan .left :nth-child(2) p:first-child {
    margin-top: 44px;
    color: #000000;
}

.Scan .left :nth-child(2) p:last-child {
    margin-top: 13px;
    font-size: 24px;
    color: #999999;
}

.Scan .rigth {
    margin: auto 20px;
    width: 26px;
    background: palegoldenrod;
    height: 32px;
    background-image: url(@/assrts/right.png);
    background-size: cover;

}

/deep/ .van-tab {
    flex: none;
    padding: 0 16px;
}

.push_detalis {
    padding: 42px 20px;
}

.push_text {
    color: #999;
    font-size: 26px;
}

.push_text2 {
    color: #999;
    font-size: 26px;
    padding: 0 25px;
    box-sizing: border-box;
}

.push_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.push_body2 {
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.push_one {
    width: 49%;
    height: 264px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 16px;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.push_name {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    /* margin-bottom: 9px; */
}

.push_avatar {
    width: 92px;
    height: 92px;
    /* background-image: url("@/assrts/toux.jpg"); */
    background-size: 100%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 10px;

}

*::-webkit-scrollbar {
    display: none
}

.push_avatar img {
}

.push_one2 {
    width: 49%;
    height: 264px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 16px;
    padding: 15px 20px 0;
    box-sizing: border-box;
}

.push_name {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 30px;
}

.push_tianActive {
    display: block;
    width: 240px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    margin: 0 auto;
    margin-top: 14px;
    color: #fff;
    font-size: 26px;
    background-color: #FC9013;
    border-radius: 8px;
    border: 0;
}

.push_tianNone {
    line-height: 56px;
    display: block;
    width: 240px;
    height: 56px;
    text-align: center;
    margin: 0 auto;
    margin-top: 14px;
    color: #999;
    font-size: 26px;
    border: 2px solid #FC9013;
    background-color: #fff;
    border-radius: 8px;

}

.push_yijia {
    width: 240px;
    height: 56px;
    line-height: 60px;
    font-size: 26px;
    text-align: center;
    margin: 0 auto;
    margin-top: 14px;
    color: #999;
    background-color: #EEE;
    border-radius: 8px;
}

.Remark {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

/* 头像 */
.Good_avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.goodsNumber {
    color: #999999;
    font-size: 28px;
    text-align: center;
}

/* 底部 */
.Add_reject2 {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid #EEEEEE;
    position: relative;
}

.Add_reject2:nth-last-child() {
    border-bottom: 0;
}

/* 搜索框搜索数据 */
.Search_list_box {
    width: 100%;
    height: 100px;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.addFriends_butt {
    position: absolute;
    right: 20px;
    top: 20px;
    border: 0;
    padding: 10px 20px;
    border-radius: 10px;
    background: #FC9013;
    color: #fff;
    font-size: 28px;
}

.Search_img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.Search_title {
    color: #999;
    font-size: 24px;
    margin-left: 20px;
}

/* 暂无数据 */
.SearchTitle {
    margin: 0 20px;
    color: #999;
    font-size: 28px;
    text-align: center;
}

/* 换一批 */
.ChangeABatch {
    color: #999;
    font-size: 26px;
    margin-top: 30px;
    text-align: center;
    position: relative;
}

.ChangeABatchAnimation {
    display: block;
    position: absolute;
    left: 38%;
    top: 2px;
    width: 30px;
    height: 30px;
    background: url('@/assrts/squire.png') no-repeat;
    background-size: 100%;
    animation: ChangeABatchAnima 1s infinite;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards,
}

/* 动画 */
@keyframes ChangeABatchAnima {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);

    }
}

/deep/ .van-index-bar__index--active {
    color: #000;
}

/deep/ .van-tab__text-wrapper .van-info {
    color: transparent;
    transform: scale(0.3);
    top: -12px;
    right: -10px;
}
</style>
