<template>
    <div>
        <!-- 左侧弹出框 -->
        <van-popup overlay-class="popup_overlay" v-model="popup_show" @click-overlay="clicka" position="left"
                   :style="{ height: '100%', width: '75%' }"
                   style="display: flex; flex-direction: column; z-index: 2022">
            <!-- 顶部 -->
            <div class="top" @click="info">
                <!-- 头像 -->
                <div class="top_left">
                    <img :src="user_info.avatar"
                         style="width: 100%; height: 100%; object-fit: cover; border-radius: 4px"/>
                    <img v-if="user_info.userInfo.sex == 1 || user_info.userInfo.sex == 0" class="xingbie"
                         src="@/assrts/man.png" alt=""/>
                    <img v-else class="xingbie" src="@/assrts/805.png" alt=""/>
                    <!-- v-if="user_info.userInfo.sex == 1" -->
                </div>
                <!-- 名称 据信号 地区 推荐人 -->
                <div class="top_rigth">
                    <div style="display: flex; justify-content: space-between;">
                        <h5 style="font-weight: bold;font-size: 18px;">{{
                            user_info.nickName.length > 6 ?
                                user_info.nickName.slice(0, 6) + '...'
                                :
                                user_info.nickName
                            }}</h5>
                        <div>
                            <img style="float: right" @click.stop="$router.push('/business_card')"
                                 src="@/assrts/erweima.png" class="erweima"/>

                        </div>
                    </div>
                    <p>聚信号：{{ user_info.account }}</p>
                    <p style="padding-right: 20px;box-sizing: border-box;">地区： {{ user_info.userInfo.provinceName }}{{
                        user_info.userInfo.cityName
                        }}</p>
                    <div style="display: flex; width: 100%;justify-content: space-between;"
                         v-if="$user_info.roleType == 100 || $user_info.roleType == 200 || $user_info.roleType == 310 || $user_info.roleType == 320">
                        <p> 推荐人：{{ parentId && parentId != 0 ? parentId : "无" }}</p>
                        <div v-if="$user_info.roleType != 420 && $user_info.roleType != 433 && $user_info.roleType != 434">
                            <div v-if="!parentId || parentId == 0" @click.stop="tianjia" class="tian">
                                添加
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 二维码 -->

            </div>
            <div
                    style="margin: 10px 10px 0 10px;height: 52px;background-color: #FFF9F5;display: flex;padding: 10px;font-size: 12px;justify-content: space-between;align-items: center;color: #FC9013">
                <div>
                    <div style="font-size: 14px">尊享会员特权！</div>
                    <div style="font-size: 12px;">立即激活，轻松抵扣现金！</div>
                </div>
                <div @click="$router.push('/activation')" v-if="isJihuo == 0"
                     style="height: 23px;width: 56px;border: 1px #FC9013 solid;line-height: 23px;text-align: center;border-radius: 24px">
                    去激活
                </div>
                <div v-else
                     style="height: 23px;width: 56px;border: 1px #FC9013 solid;line-height: 23px;text-align: center;border-radius: 24px">
                    已激活
                </div>
            </div>
            <!-- 切换账号 -->
            <div class="centre">
                <div v-if="identity.length > 1">
                    <p>账号切换</p>
                    <div style="overflow-x: hidden;overflow-y: auto;height: 56vh;  ">
                        <div v-for="(item, index) in identity" :key="index">
                            <div v-if="$user_info.roleType !== item.roleId" class="centre_status">
                                <div class="centre_status_photo">
                                    <img :src="item.avatar"
                                         style="width: 100%; height: 100%; border-radius: 4px;object-fit: cover;"/>
                                </div>
                                <div class="centre_status_box">
                                    <div class="centre_status_box_name">
                                        <p>{{
                                            item.nickName.length > 6 ? item.nickName.slice(0, 6) + "..." : item.nickName
                                            }}</p>
                                        <p style="color: #888;">
                                            {{
                                            item.roleId == 100
                                                ? "用户"
                                                : item.roleId == 200
                                                    ? "厂家"
                                                    : item.roleId == 310
                                                        ? "网点"
                                                        : item.roleId == 320
                                                            ? "店铺"
                                                            : item.roleId == 400
                                                                ? "商城创始人"
                                                                : item.roleId == 410
                                                                    ? "推广员"
                                                                    : item.roleId == 420
                                                                        ? "运营中心"
                                                                        : item.roleId == 421
                                                                            ? "用户中心"
                                                                            : item.roleId == 422
                                                                                ? "网点中心"
                                                                                : item.roleId == 423
                                                                                    ? "产品中心"
                                                                                    : item.roleId == 424
                                                                                        ? "商贸中心"
                                                                                        : item.roleId == 431
                                                                                            ? "产品经理"
                                                                                            : item.roleId == 432
                                                                                                ? "网点经理"
                                                                                                : item.roleId == 433
                                                                                                    ? "商贸经理"
                                                                                                    : item.roleId == 434
                                                                                                        ? "用户经理"
                                                                                                        : ""
                                            }}
                                        </p>
                                    </div>
                                    <div class="centre_status_box_btn" @click="switch_identity(index)">
                                        切换身份
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 80px;"></div>

                    </div>
                </div>
            </div>

            <!-- 城市合伙人  设置 -->
            <div class="set" style="position: sticky;bottom: 73px;width: 100%">
                <div class="set_box">
                    <!-- 城市合伙人 -->
                    <!--<div class="partner">-->
                    <!--<img src="@/assrts/hehuoren.png" alt="">-->
                    <!--<div class="partner_install_text">城市合伙人</div>-->
                    <!--</div>-->
                    <!-- 设置 -->
                    <div class="install" @click="install" v-if="this.$user_info.roleType == 100">
                        <div></div>
                        <div style="display: flex">
                            <img src="@/assrts/shezhi.png" alt=""/>
                            <div class="partner_install_text">设置</div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <!-- 退出登录 -->
            <div class="bottom">
                <div @click="exit" class="bottom_btn">退出登录</div>
                <div class="bottom_deal">
                    <span @click="xieyi(4)">用户协议</span>
                    <span @click="xieyi(6)">隐私政策</span>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="xieyu_show" position="bottom" :style="{ height: '100%', }">
            <div style="height: 56px;"></div>
            <div class="xd"
                 style="display: flex;justify-content: space-between;font-size: 20px;padding: 10px;background-color: #f2f2f5;position: fixed; top: 0;left: 0; width: 100%;z-index: 9999;">
                <img src="@/assrts/177.png" style="width: 24px;height: 24px" @click="xieyu_show = false">
                {{ v_id == 4 ? '用户须知' : '隐私政策' }}
                <div style="width: 24px;"></div>
            </div>
            <div v-html="v_html" class="img_widt" style="font-size: 18px;margin: 0 20px"></div>
        </van-popup>

    </div>
</template>

<script>
import {
    Dialog
} from "vant";

export default {
    name: "popup",
    props: ["show", "activer"],
    data() {
        return {
            isJihuo: '',
            arr_str: "", // 接受底部状态
            popup_show: false,
            parentId: "", // 有无推荐人
            identity: [], // 身份列表
            user_info: {},
            xieyu_show: false,
            v_html: '',
            v_id: '',
        };
    },
    created() {
        this.user_info = JSON.parse(localStorage.getItem("user_info"));
        // 切换账号身份
        // 循环所有身份  如果status == 1 有身份  否则没有此身份
        for (let i = 0; i < this.user_info.userInfo.juxinRole.length; i++) {
            if (this.user_info.userInfo.juxinRole[i].status == 1) {
                this.identity.push(this.user_info.userInfo.juxinRole[i]);
            }
        }
    },
    watch: {
        show(news) {
            if (news == true) {
                this.popup_show = news;
                this.$emit("changeMsg", 4);
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.arr_str = this.activer;
        }, 5);
        // 查询是否有无推荐人
        this.$axios({
            url: "/shop-system/rhUserType/getWriteInviter",
            method: "GET",
            params: {
                type: this.$user_info.roleType == 100 ? 1 : this.$user_info.roleType == 200 ? 3 : this
                    .$user_info.roleType == 320 ? 5 : this.$user_info.roleType == 310 ? 4 :
                    '', // 1会员 2创建商城人 3厂家 4网点 5店铺 6推广员
                userId: this.user_info.uuid,
            },
        }).then((res) => {
            if (res.code == 200) {
                this.parentId = res.data.parentId;
                this.$axios({
                    url: "/shop-system/rhUserType/getNoWriteInviter",
                    method: "GET",
                    params: {
                        type: 434,
                    },
                }).then((res) => {
                    if (res.code == 200) {
                        if (this.parentId == res.data) {
                            this.parentId = 0;
                        }
                    } else {
                        this.$toast(res.msg);
                    }
                });
            } else {
                this.$toast(res.msg);
            }
        });
        console.log({
            userId: this.$user_info.uuid,
            account: this.$user_info.account
        })
        this.$axios({
            url: '/shop-system/prepaymentAccount/selectUserInitInfo',
            method: "GET",
            params: {
                userId: this.$user_info.uuid,
                account: this.$user_info.account
            }
        }).then(res => {
            if (res.code == 200) {
                this.isJihuo = res.data.isJihuo
            } else {
                this.$toast(res.msg)
            }
        })
    },
    methods: {
        /**
         * 弹出协议
         * */
        xieyi(id) {
            this.v_id = id
            this.$axios({
                url: '/juxin-back/introductionContent/selectContentByType',
                method: "GET",
                params: {
                    typeId: id
                }
            }).then(res => {
                if (res.code == 200) {
                    this.xieyu_show = true
                    this.v_html = res.data
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 退出登录
        exit() {
            this.popup_show = false
            Dialog.confirm({
                message: "确定退出登录？",
                confirmButtonColor: '#fa902c',
                // overlayClass:'dialog_overlay'
            })
                .then(() => {
                    this.$axios({
                        url: "/auth/juxin-app/logout",
                        method: "Delete",
                        params: {},
                    }).then((res) => {
                        if (res.code == 200) {
                            this.tui_tim();
                            localStorage.clear();
                            this.$axios
                                .post("/shop-system/RSA/creatRsa")
                                .then((res) => {
                                    localStorage.setItem("creat", JSON.stringify(res.data));
                                })
                                .then((res) => {
                                    this.$router.push("/login");
                                });
                        } else {
                            this.$toast(res.msg);
                        }
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },
        /**
         * 传给父组件的事件
         * */
        clicka() {
            this.$emit("changeMsg", this.arr_str);
        },
        /**
         * 跳转到设置
         * */
        install() {
            this.$router.push("/Setting");
        },
        /**
         * 跳转到个人信息
         * */
        info() {
            this.$router.push("/user_info");
        },
        /**
         * 添加按钮
         * */
        tianjia() {
            this.$router.push("/invite_code");
        },
        //  切换身份
        switch_identity(index) {
            // 切换身份
            this.$axios({
                url: "/bkx-auth/juxin-app/changeLoginRole",
                method: "GET",
                params: {
                    utype: this.identity[index].roleId,
                    userId: this.user_info.uuid,
                },
            }).then((res) => {
                this.popup_show = false;
                if (res.code == 200) {
                    localStorage.setItem('token', 'JuxinApp ' + res.data.access_token)
                    setTimeout(() => {
                        this.$axios({
                            url: 'bkx-juxin-app/common/getInfoByToken',
                            method: 'GET',
                        }).then(res => {
                            if (res.code == 200) {
                                localStorage.setItem('user_info', JSON.stringify(res.data))
                                this.cun_user(res.data)
                                if (this.$user_info.roleType == 200) {
                                    console.log(11111111111);
                                    this.$axios({
                                        url: "/bkx-juxin-app/juxinFriendRelationship/isAddRobotH5",
                                        method: "GET",
                                        params: {
                                            account: this.$user_info.account,
                                        },
                                    }).then((res) => {
                                        console.log(res, 222222);
                                        if (res.code == 200) {
                                            // this.$router.push({
                                            //     path: '/'
                                            // })
                                            // setTimeout(() => {
                                            //     location.reload(); // 刷新页面
                                            // })
                                        }

                                    })
                                }
                                this.$router.push({
                                    path: '/'
                                })
                                setTimeout(() => {
                                    location.reload(); // 刷新页面
                                })
                            } else {
                                this.$toast(res.msg)
                            }
                        })
                    })
                    console.log(123456);
                } else {
                    this.$toast(res.msg)
                }
            });

            // Dialog.confirm({
            //     message: "确定要切换身份吗？",
            // })
            //     .then(() => {
            //         // 切换身份
            //         this.$axios({
            //             url: "/bkx-auth/juxin-app/changeLoginRole",
            //             method: "GET",
            //             params: {
            //                 utype: this.identity[index].roleId,
            //                 userId: this.user_info.uuid,
            //             },
            //         }).then((res) => {
            //             this.popup_show = false;
            //             if (res.code == 200) {

            //                 localStorage.setItem('token', 'JuxinApp ' + res.data.access_token)
            //                 setTimeout(() => {
            //                     this.$axios({
            //                         url: 'bkx-juxin-app/common/getInfoByToken',
            //                         method: 'GET',
            //                     }).then(res => {
            //                         if (res.code == 200) {
            //                             localStorage.setItem('user_info', JSON.stringify(res.data))
            //                             this.cun_user(res.data)

            //                         } else {
            //                             this.$toast(res.msg)
            //                         }
            //                     })
            //                 })
            //                 console.log(123456);
            //             }
            //         });
            //     })
            //     .catch(() => {
            //         // on cancel
            //     });
        },
    },
};
</script>

<style scoped>
/* 吸顶 */
.xd {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
}

/* 顶部 */
.van-popup--left {
    /* padding: 0 20px; */
}

/deep/ .van-popup::-webkit-scrollbar {
    display: none
}


.van-popup--left .top {
    margin-top: 20px;
    width: 100%;
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
}

/* 头像 */
.van-popup--left .top .top_left {
    width: 126px;
    height: 126px;
    margin-left: 20px;
    font-size: 28px;
    position: relative;
    background-size: 100%;
    border-radius: 8px;
    padding: 0;
}

.xingbie {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.van-popup--left .top .top_rigth {
    margin-left: 30px;
    flex: 1;
}

/* 名称 */
.van-popup--left .top .top_rigth h5 {
    font-size: 40px;
    margin: 0 !important;
    font-weight: 500;
    color: #000000;
}

.van-popup--left .top .top_rigth p {
    margin: 12px 0 0 0;
    font-size: 24px;
    font-weight: 400;
    color: #666666;
}

.van-popup--left .top .erweima {
    margin-right: 20px;
    width: 48px;
    height: 48px;
}

/* .van-popup--left .xian{
  margin-top: 30px;
  width: 100%;
  border: 1px solid #EEEEEE;
} */
/* 账号切换 */
.van-popup--left .centre {
    padding: 30px 0px 20px 20px;
    flex: 1;
}

.van-popup--left .centre p {
    margin: 0;
    font-size: 28px;
}

.van-popup--left .centre .centre_status {
    display: flex;
    margin-top: 30px;
}

/* 账号切换中的头像 */
.van-popup--left .centre .centre_status .centre_status_photo {
    width: 80px;
    height: 80px;
}

.van-popup--left .centre .centre_status .centre_status_box {
    margin-left: 20px;
    display: flex;
    flex: 1;
    padding-bottom: 26px;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
}

.van-popup--left .centre .centre_status .centre_status_box .centre_status_box_name:first-child {
    color: #000000;
    font-size: 28px;
}

.van-popup--left .centre .centre_status .centre_status_box .centre_status_box_name:last-child {
    color: #999999;
    font-size: 24px;
}

.van-popup--left .centre .centre_status .centre_status_box .centre_status_box_btn {
    margin-right: 20px;
    width: 136px;
    height: 48px;
    border: #fc9013 solid 2px;
    color: #fc9013;
    font-size: 24px;
    text-align: center;
    line-height: 48px;
    border-radius: 24px 24px 24px 24px;
    margin-top: 15px;
}

/* 城市合伙人  设置*/
.set {
    background: #fff;
    height: 116px;
    /* margin-top: 10px; */
}

.set .set_box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

/* 退出登录 */

.bottom {
    padding: 0 20px;
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 2021;
}

.bottom .bottom_btn {
    margin: 0 auto;
    width: 100%;
    height: 72px;
    border: 2px solid #fb9036;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    line-height: 72px;
    color: #fc9013;
    font-size: 28px;
}

.bottom .bottom_deal {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    color: #cccccc;
    font-size: 20px;
}

.bottom .bottom_deal :first-child {
    padding-right: 25px;
    border-right: 1px solid #cccccc;
}

.bottom .bottom_deal :last-child {
    padding-left: 25px;
}

.tian {
    width: 100px;
    height: 50px;
    border-radius: 26px;
    text-align: center;
    line-height: 50px;
    color: #fa902c;
    margin-left: 40px;
    margin-top: 7px;
    font-size: 26px;
}

.install {
    width: 100%;
    height: 76px;
    background-color: #fa902c;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
}

.install img {
    width: 34px;
    height: 34px;
    margin: 20px 12px;
}

.partner_install_text {
    font-size: 26px;
    line-height: 78px;
    color: #fff;
}

.popup_overlay {
    z-index: 2004 !important;
}

/deep/ .van-popup--bottom {
    z-index: 9999999999999999 !important;
}

/deep/ .img_widt img {
    width: 100% !important;
}

/deep/ .popup_overlay,
.van-popup--left {
    z-index: 99999 !important;
}
</style>
