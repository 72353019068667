<template>
    <div>
        <div class="top">
            <div style="width: 32px;">
                <div @click="Previoustep" class="top_div"></div>
            </div>
            <div style="font-weight: bold">填写邀请码</div>
            <div ></div>
        </div>

        <!-- 邀请码 -->
        <div class="invite" style="
    margin-top:23px;
    margin-left:10px;
    font-size: 14px;
    font-weight: 400;
    color: #181818;
    ">邀请码
        </div>

        <!-- input -->
        <div class="inpt">
            <input type="text" v-model.trim="id" placeholder="[选填]请填写邀请人的邀请码">
        </div>


        <!-- 确认 -->
        <div class="finish" @click="confirm">
            确认
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            id:'', //邀请码
        }
    },
    methods: {
        // 返回按钮
        Previoustep() {
            this.$router.go(-1)
        },
        
        // 跳实名认证
        confirm() {
            if(this.id ==''){
                this.$toast('请输入邀请码，再确定！')
                return
            }
              // 跳实名认证
            this.$axios.post('/bkx-shop-system/rhUserType/registerSaveParent', {
                invitationCode: this.id
            }).then(res => {
                if (res.code == 200) {
                    console.log(res);
                    // 用户经理的
                    if (res.data.inviteType == 434) {
                        if (res.data.status == 0) {   // 0  是  失败
                            this.$toast(res.data.msg)
                            this.$router.push('/')
                        } else if (res.data.status == 1) {  // 1 （单绑推荐人） 成功
                            this.$toast(res.data.msg)
                            this.$router.push('/')
                        } else {
                            this.$toast(res.data.msg)
                            this.$router.push('/')
                        }     // 网点/店铺经理
                    } else if (res.data.inviteType == 432 || res.data.inviteType == 433) {
                        // 判断是否实名
                        if (!this.$user_info.userInfo.idNumber) {
                            this.$router.push({
                                path: '/Real_name',
                                query: { id: this.id, name: 1 }
                            })
                        } else if (res.data.status == 0 && res.data.auditStatus == 1) {
                            this.$toast(res.data.msg)
                            this.$router.push({
                                path: '/',
                                query: { id: 1 }
                            })
                        } else if (res.data.status == 0 && res.data.auditStatus == 0) {
                            // 0 0 审核中
                            this.$toast(res.data.msg)
                            this.$router.push({
                                path: '/',
                                query: { id: 1 }
                            })
                        } else if (res.data.status == 1 && res.data.auditStatus == 1) {
                            // 1 1   单绑推荐人
                            this.$toast(res.data.msg)
                            this.$router.push({
                                path: '/',
                                query: { id: 1 }
                            })
                        } else if (res.data.status == 2 && res.data.auditStatus == 1) {
                            //  2  1   //提交审核
                            this.$toast(res.data.msg)
                            this.$router.push({
                                path: '/',
                                query: { id: 1 }
                            })
                        }

                    } else if (res.data.inviteType == 431) {
                        console.log(9999999);
                        // 431 产品经理
                        //  判断是否实名过
                        if (!this.$user_info.userInfo.idNumber) {
                            this.$router.push({
                                path: '/apply_changjia',
                                query: { idd: this.id, name: 1 } // 邀请码由以前的id 改成idd
                            })
                        } else {
                            for (let i = 0; i < this.$user_info.userInfo.juxinRole.length; i++) {
                                if (this.$user_info.userInfo.juxinRole[i].roleId == 200 && this.$user_info.userInfo.juxinRole[i].status == 1) {
                                    this.$toast(res.data.msg)
                                    this.$router.push({
                                        path: '/',
                                        query: { id: 1 }
                                    })
                                    return
                                } else if (this.$user_info.userInfo.juxinRole[i].roleId == 200 && this.$user_info.userInfo.juxinRole[i].status == 0) {
                                    this.$router.push({
                                        path: '/apply_changjia_xia',
                                        query: { id: 1,code:this.id }
                                    })
                                    return
                                }
                            }
                            // 已提交
                            if (res.data.status == 2 && res.data.auditStatus == 1) {
                                this.$toast(res.data.msg)
                                this.$router.push({
                                    path: '/',
                                    query: { id: 1 }
                                })
                            } else if (res.data.status == 0 && res.data.auditStatus == 1) {
                                //  出现  问题的时候
                             
                                this.$router.push({
                                        path: '/apply_changjia_xia',
                                        query: { id: 1, name: 1,code:this.id }
                                    })
                            } else if (res.data.status == 1 && res.data.auditStatus == 1) {
                                this.$toast(res.data.msg)
                                this.$router.push({
                                    path: '/apply_changjia_xia',
                                    query: { id: 1,code:this.id }
                                })
                            }else if (res.data.status == 0 && res.data.auditStatus == 0) {
                                this.$toast(res.data.msg)
                                this.$router.push({
                                    path: '/',
                                    query: { id: 1 }
                                })
                            }

                        }
                    }


                    //   this.$toast(res.data.msg)
                    //   判断 是否 实名
                    //   if(res.status == 0 && res.auditStatus == 1){  // 0 1是未实名
                    //     if(res.data.inviteType == 431){
                    //         // '/apply_changjia'
                    //          this.$router.push({
                    //             path:'/apply_changjia',
                    //             query:{id:this.id}
                    //          })
                    //        }else{
                    //         // '/Real_name'
                    //         this.$router.push({
                    //             path:'/Real_name',
                    //             query:{id:this.id}
                    //         })
                    //        }
                    //   }else if(res.status == 0 && res.auditStatus == 0){ // 0 0 审核中
                    //     //  431  厂商
                    //     if(res.data.inviteType == 431){
                    //         this.$router.push('/')
                    //     }else{
                    //         this.$router.push('/')
                    //     }
                    //   }else if(res.status == 1 && res.auditStatus == 1){ // 1 1 单绑推荐人
                    //      //  431  厂商
                    //      if(res.data.inviteType == 431){
                    //         this.$router.push('/')
                    //     }else{
                    //         this.$router.push('/')
                    //     }
                    //   }else if(res.status == 2 && res.auditStatus == 1){ // 2 1

                    //   }
                }else{
                    this.$toast(res.msg)
                }
                console.log(res, 999)
            })


            // let type = 1
            // if (type == 1) {
            //     // 厂家实名认证
            //     this.$router.push('/apply_changjia')
            // } else {
            //     this.$router.push('/Real_name')
            // }
        }

    }
}
</script>

<style scoped>
.top {
    margin: 39px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
}

.top_div {
    width: 39px;
    height: 48px;
    /* background: red; */
    background-image: url(@/assrts/177.png);
    background-size: cover;
}

.top :nth-child(2) {

    font-weight: 500;
    color: #181818;
    font-size: 36px;
}

.top :nth-child(3) {

    color: #999999;
    font-weight: 400;
    font-size: 32px;
}

/* input */
.inpt {
    /* background: pink; */
    height: 84px;
    font-size: 28px;
    margin: 22px 20px;
    border: 1px solid #CCCCCC;
    display: flex;
}

.inpt input {
    padding-left: 30px;
    flex: 1;
    border: 0;
}

/* 确认 */
.finish {
     margin: 800px auto;
    width: 512px;
    text-align: center;
    line-height: 84px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 28px;
    border-radius: 42px 42px 42px 42px;
    background: #FC9013;
    height: 84px;
}

</style>
