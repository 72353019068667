<template>
  <div>
    <!-- 顶部 -->
       <div class="head">
          <div class="head_one" @click="back"></div>
          <div class="head_two">对公信息详情</div>
          <div  class="head_three">{{type == 2 ? '修改' :''}}</div>
       </div>
    <!-- 审核状态 -->
    <!-- 审核中 -->
    <div
      v-if="type == 0"
      class="Audit_status"
      style="display: flex; align-items: center; background: #fff3e7"
    >
      <img
        style="width: 16px; height: 16px; margin: 7px 4px 7px 10px"
        src="@/assrts/7980.png"
        alt=""
      />
      <span style="color: #fa902c; font-size: 15px">审核中</span>
    </div>
    <!-- 审核成功 -->
    <div
      v-if="type == 1"
      class="Audit_status"
      style="display: flex; align-items: center; background: #ebf9f3"
    >
      <img
        style="width: 16px; height: 16px; margin: 7px 4px 7px 10px"
        src="@/assrts/7982.png"
        alt=""
      />
      <span style="color: #22b13d; font-size: 15px">审核成功</span>
    </div>

    <!-- 审核失败 -->
    <div
      v-if="type == 2"
      class="Audit_status"
      style="display: flex; align-items: center; background: #fdf0ee"
    >
      <img
        style="width: 16px; height: 16px; margin: 7px 4px 7px 10px"
        src="@/assrts/7983.png"
        alt=""
      />
      <span style="color: #fa5153; font-size: 15px">审核失败</span>
    </div>

    <!-- 驳回原因   只有 审核失败的时候才显示 -->

    <div class="range" v-if="type == 2">
      <div>驳回原因</div>
      <van-field
        class="ada"
        readonly
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入留言"
        show-word-limit
      ></van-field>
    </div>

    <!-- 审核中  、审核成功-->
    <div class="Echo">
      <span style="width: 150px">对公帐户名称</span>
      <div>1</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">社会信用代码</span>
      <div>13216546513255565655</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">社会信用证有效期</span>
      <div>2022.02.12-2050.03.22</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">企业地址</span>
      <div>河北省 石家庄</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">详细地址</span>
      <div>疯狂减肥飞机可俯瞰</div>
    </div>
    <!-- 经营范围 -->
    <div class="range">
      <div>经营范围</div>
      <van-field
        class="ada"
        readonly
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入留言"
        show-word-limit
      ></van-field>
    </div>
    <div class="Echo">
      <span style="width: 150px">开户行名称</span>
      <div>中国农业银行</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">银行卡对应的户名</span>
      <div>赵县农村信用联社福分有限公司</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">对公账户号</span>
      <div>123</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">法人姓名</span>
      <div>张三</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">法人身份证号</span>
      <div>123456789101112131</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">身份证有效期</span>
      <div>2022.02.12-2032.02.12</div>
    </div>
    <div class="Echo">
      <span style="width: 150px">法人手机号</span>
      <div>18812345678</div>
    </div>

    <!-- 法人身份证照片 -->
    <div class="photo">
      <div class="photo_one" style="font-size: 15px; color: #181818">
        法人身份证照片
      </div>
      <div
        class="photo_two"
        style="display: flex; justify-content: space-between; margin-top: 17px"
      >
        <div
          style="
            width: 166px;
            height: 104px;
            border-radius: 6px 6px 6px 6px;
            background: yellow;
          "
        ></div>
        <div
          style="
            width: 166px;
            height: 104px;
            border-radius: 6px 6px 6px 6px;
            background: yellow;
          "
        ></div>
      </div>
    </div>

    <!-- 营业执照 -->
    <div class="photo">
      <div class="photo_one" style="font-size: 15px; color: #181818">
        营业执照
      </div>
      <div
        class="photo_two"
        style="display: flex; justify-content: space-between; margin-top: 17px"
      >
        <div
          style="
            width: 166px;
            height: 104px;
            border-radius: 6px 6px 6px 6px;
            background: yellow;
          "
        ></div>
      </div>
    </div>

    <!-- 开户许可 -->
    <div class="photo">
      <div class="photo_one" style="font-size: 15px; color: #181818">
        开户许可
      </div>
      <div
        class="photo_two"
        style="display: flex; justify-content: space-between; margin-top: 17px"
      >
        <div
          style="
            width: 166px;
            height: 104px;
            border-radius: 6px 6px 6px 6px;
            background: yellow;
          "
        ></div>
      </div>
    </div>

    <!-- 驳回  通过  只有审核失败才显示 -->
    <div class="foot" v-if="type == 2">
      <div @click="bohui">驳回</div>
      <div @click="tongguo">通过</div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      type: 2, // 0 审核中  1 成功 2失败
    };
  },
  // 返回
  methods:{
    back(){
        this.$router.back()
    },
    // 驳回
    bohui(){
        this.$router.go(-1)
    },
    //   通过
    tongguo(){
         this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
/* 顶部 */
.head{

    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F2F2F5;
}
.head .head_one{
 margin-left: 20px;
 width: 41px;
 height: 50px;
background-image:url(@/assrts/177.png) ;
background-size: cover;
}
.head .head_two{
margin-top: 40px;
margin-bottom: 34px;
font-weight: 500;  
color: #181818;
font-size: 38px;
}
.head .head_three{
   margin-right: 20px;
font-size: 25px; 
color: #333333;
}


.Echo {
  height: 100px;
  display: flex;
  font-size: 29px;
  margin: 0px 20px;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}

/* 经营范围 */
.range {
  margin: 34px 20px;
  border-bottom: 1px solid #eeeeee;
}
.range div {
  color: #181818;
  font-size: 29px;
}
.range .ada {
  margin-top: 34px;
  margin-bottom: 34px;
  background: #efefef;
  height: 208px;
  border-radius: 17px 17px 17px 17px;
}

.photo {
  margin: 48px 20px 0 20px;
  background: pink;
}

/* foot  驳回 通过 */
.foot {
  margin: 83px auto;
  display: flex;
  justify-content: center;
}
.foot :first-child {
  width: 296px;
  text-align: center;
  line-height: 92px;
  border: 2px solid #fc9013;
  border-radius: 8px 8px 8px 8px;
  height: 92px;
  color: #fc9013;
  font-size: 33px;
  font-weight: 500;
  margin-right: 30px;
  margin-bottom: 98px;
}

.foot :last-child {
  width: 296px;
  text-align: center;
  line-height: 92px;
  background: #fc9013;
  border-radius: 8px 8px 8px 8px;
  height: 92px;
  color: #ffffff;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 98px;
}


</style>