<template>
    <div style="background: #f2f2f5;height: 100vh;  position: relative;overflow: auto;">
		<div style="position:sticky;top: 0; background-color: #f2f2f5;">
			<TopPack :shezhi="shezhi"></TopPack>
		</div>
        <!-- 暂无推广商品 -->
        <div v-if="list.length == 0" class="shopping_kong">
            <div class="shopping_kong_one"></div>
            <div style="font-size: 15px; color: #333333; text-align: center">
                暂无推广商品
            </div>
        </div>

        <!-- 商品列表 -->
        <div class="Product_list" v-for="(item,index) in list" :key="index">
            <!-- 左侧商品图片 -->
            <div class="Product_list_one">
                <img :src="item.goods_main" alt="" style="object-fit: cover;"/>
            </div>
            <!-- 右侧盒子 -->
            <div class="Product_list_two">
                <div class="Product_list_two_top">
                    {{ item.goods_name }}
                </div>

                <div class="Product_list_two_bottom">
                    <span style="color: #333333; font-size: 12px">{{item.dianpu_type==1 ?'网点名称：':'店铺名称：'}}{{ item.dianpu_name }}</span>
                    <div @click="shangpin(item)">商品码</div>
                </div>
            </div>
        </div>

        <van-popup v-model="show" position="bottom" round :style="{ height: '50%' }">
            <!-- 商品名称 -->
            <div style="
          font-size: 15px;
          color: #333333;
          margin: 10px auto;
          display: flex;
          justify-content: center;
          margin: 35px 32px;

        ">
               商品名称: &nbsp; {{ item_info.goods_name }}
            </div>
            <!-- 店铺名称 -->
            <div style="
          font-size: 15px;
          color: #333333;
          display: flex;
          justify-content: center;
        ">
                <span>{{content_two==1 ?'网点名称：':'店铺名称：'}}</span><span>{{ item_info.dianpu_name }}</span>
            </div>
            <div class="shopping_code">
                <Qrcode v-if="content" ref="qrcode" class="qr_code_two" :qrUrl="content" :qrSize="200"></Qrcode>
            </div>
            <div @click="handleDownQRCode" style="
          background: #ff9113;
          margin: 35px 32px;
          height: 32px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 32px;
        ">
                保存商品码
            </div>
        </van-popup>
    </div>
</template>

<script>
import TopPack from "../TopPack.vue";
import Qrcode from "@/components/Qrcode.vue";

export default {
    components: {
        TopPack,
        Qrcode,
    },
    data() {
        return {
            shezhi: "推广商品",
            list: '',
            show: false,
            content: "", // 收款二维码内容
            item_info: '',
            content_two:''
        };
    },
    mounted() {
        this.$axios({
            url: '/bkx-shop-app/APPrhGoodsController/selectPromoterGoods',
            method: 'GET',
            params: {
                pageNum: 1,
                pageSize: 999
            }
        }).then(res => {
            if (res.code == 200) {
                console.log(res);
                this.list = res.data.list
            } else {
                this.$toast(res.msg)
            }
        })
    },
    methods: {
        handleDownQRCode() {
            this.$refs.qrcode.downQRCode('商品码', this.content)
        },
        shangpin(item) {
            this.content_two = item.dianpu_type
            console.log(item);
            this.show = true
            this.item_info = item
            this.content = `${this.link}pages/classify/goodsDetails?goodsId=${item.goods_number}&goods_manufacturer=${item.goods_number}&network_id=${item.dianpu_id}&type=网点`
        }
    }
};
</script>

<style scoped>
.shopping_kong_one {
    margin: auto;
    width: 410px;
    height: 410px;
    background-image: url(@/assrts/shop_kong.png);
    background-size: cover;
}

/* 商品列表 */
.Product_list {
    margin: 20px 20px 0px;
    background: #fff;
    display: flex;
}

/* 左侧商品图片 */
.Product_list .Product_list_one {
    margin: 20px;
    width: 146px;
    height: 146px;
}

.Product_list .Product_list_one img {
    width: 100%;
    height: 100%;
}

/* 右侧盒子 */
.Product_list .Product_list_two {
    margin: 20px 20px 20px 0px;
    flex: 1;
    font-size: 29px;
}

/* 店铺名称  商品码 */
.Product_list .Product_list_two .Product_list_two_bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* 商品码 */
.Product_list .Product_list_two .Product_list_two_bottom div {
    width: 146px;
    height: 58px;
    color: #ffffff;
    background: #ff9113;
    line-height: 58px;
    text-align: center;
    font-size: 25px;
}

/* 商品列表 结束 */

/* 二维码 */
.shopping_code {
    margin: 65px auto 65px;
    width: 385px;
    height: 385px;
}
</style>
