<template>
	<div style="background: #f2f2f5; min-height: 100vh; position: relative;overflow: auto; height: 100%;">
		<div style="position:sticky;top: 0; height: 100px; background-color: #f2f2f5; z-index: 100;">
			<TopPack :shezhi="shezhi" url="/"></TopPack>
			<div class="tab">
				<div class="tab_one">
					<van-tabs title-active-color="#FF9113" @click="getList" v-model="active">
						<van-tab title="待审核"></van-tab>
						<van-tab title="已审核"></van-tab>
					</van-tabs>
				</div>
			</div>
		</div>
		<!-- 待审核 -->
		<!-- 空状态 -->
		<!--    <div v-if="loadingList == [] && completeList == []" class="kong">
      <div class="tupian"></div>
      <p style="text-align: center; color: #333333; font-size: 15px">
        暂无内容
      </p>
    </div> -->

		<!-- 待审核 -->
		<template v-if="active == 0">
			<div v-if="loadingList.length==0" class="kong">
				<div class="tupian"></div>
				<p style="text-align: center; color: #333333; font-size: 15px">
					暂无内容
				</p>
			</div>
			<div v-else>
				<div class="content" v-for="(item, index) in loadingList" :key="index" @click.stop="goDetails(item)">
					<div class="content_one">
						<span>{{item.title}}</span>
					</div>

					<div class="content_two">
						<span>申请时间</span>
						<span>{{ item.createTime }}</span>
					</div>
					<van-divider style="margin: 5px 10px 0px 10px; background: pink" />
					<div class="content_three">
						<span @click.stop="goDetails(item)">点击查看详情</span>
						<div></div>
					</div>
				</div>
			</div>

		</template>

		<!-- 已审核 -->
		<template v-else>

			<div v-if="completeList.length==0" class="kong">
				<div class="tupian"></div>
				<p style="text-align: center; color: #333333; font-size: 15px">
					暂无内容
				</p>
			</div>

			<div v-else>
				<div class="content" v-for="(item, index) in completeList" :key="index">
					<div class="content_Add" style="margin: 10px 10px; display: flex;align-items: center;">
						<!-- 通过图标 -->
						<!-- <img src="@/assrts/success.png"  alt="" style="width: 15px; height: 15px" /> -->
						<!-- 驳回 图标-->
						<img :src="item.noPass == 1 ? require('@/assrts/6731.png') : require('@/assrts/cuo.png')" alt=""
							style="width: 15px; height: 15px;object-fit: cover;" />
						<span
							style="margin-left: 4px; font-size: 15px; color: #333333">{{ item.noPass == 1 ? '审核通过' : '审核驳回' }}</span>
					</div>
					<div class="content_one" style="margin-top:0px; margin-bottom:0px;">
						<span>{{ item.title }}</span>
					</div>
					<van-divider style="margin: 8px 10px 0px 10px; background: pink" />
					<div class="content_two" style="margin: 8px 10px 10px 10px">
						<span>{{item.noPass == 1 ? '通过' : '驳回'}}时间</span>
						<span style="margin-left:8px">{{ item.updateTime }}</span>
					</div>
				</div>
			</div>

		</template>
		<div v-if="loadingList != [] && completeList != []" style="height: 50px;"></div>
	</div>
</template>

<script>
	import TopPack from "../TopPack.vue";
	export default {
		components: {
			TopPack,
		},
		mounted() {
			this.getList()
		},
		data() {
			return {
				shezhi: "申请授权列表",
				active: 0,
				loadingList: [], //审核中数据
				completeList: [], //审核完成数据
			};
		},
		methods: {
			// 获取审核数据
			getList() {
				console.log(this.active);
				if (this.active == 0) {
					this.$axios({
						url: '/bkx-rh-settlement/EAV/eavList',
						methods: 'GET',
						params: {
							account: this.$user_info.account.toString(),
							pageNum: 1,
							pageSize: 99,
							type: 1
						}
					}).then(res => {
						this.loadingList = res.data.records
					})

				} else {
					this.$axios({
						url: '/bkx-rh-settlement/EAV/eavList',
						methods: 'GET',
						params: {
							account: this.$user_info.account.toString(),
							pageNum: 1,
							pageSize: 99,
							type: 2
						}
					}).then(res => {
						this.completeList = res.data.records
					})
				}
			},
			goDetails(info) {
				this.$router.push({
					path: '/details',
					query: {
						info: info
					}
				})
			}
		}
	};
</script>

<style scoped>
	.tab {
		width: 100%;
		background: #fff;
	}

	.tab .tab_one {
		width: 400px;
		background: yellow;
	}

	/deep/ .van-tabs__line {
		background-image: url("@/assrts/xhx..png") !important;
		background-size: 100% 100%;
		bottom: 30px;
		width: 35px;
		height: 30px;
		background-color: transparent !important;
	}

	/* 内容 */
	.content {
		margin: 20px;
		border-radius: 25px 25px 25px 25px;
		background: #ffffff;
		overflow: hidden;
	}

	.content .content_one {
		margin: 25px 20px;
		font-size: 29px;
	}

	.content .content_two {
		margin: 0px 20px;

		color: #999999;
		font-size: 25px;
	}

	.content .content_three {
		margin: 10px 20px 20px;
		color: #ff9113;
		font-size: 25px;
		display: flex;
		justify-content: space-between;
	}

	.content .content_three div {
		width: 30px;
		height: 30px;
		background-image: url(@/assrts/right-r.png);
		background-size: cover;
	}

	.kong {
		margin-top: 100px;
	}

	/* 空状态 */
	.kong .tupian {
		margin: auto;
		width: 400px;
		height: 400px;
		background-image: url(@/assrts/kong.png);
		background-size: cover;
	}
</style>
