<template>
    <div id="app">
        <!-- 需要缓存 -->
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <!-- 不需要缓存 -->
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    computed:{
        business(){
            return this.$route.query.account
        }
    },
    mounted() {
        console.log(this.business,'this.business');
        console.log(this.$route)
        if (!localStorage.getItem('creat')) {
            this.$axios.post('/shop-system/RSA/creatRsa').then(res => {
                localStorage.setItem('creat', JSON.stringify(res.data))
            })
        } else {
            let time = JSON.parse(localStorage.getItem('creat')).dueTime.split('T')[0]
            var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth() + 1 > 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
            day = date.getDate()
            let dang_time = year + "-" + month + "-" + day
            if (time < dang_time) {
                this.$axios.post('/shop-system/RSA/creatRsa').then(res => {
                    localStorage.setItem('creat', JSON.stringify(res.data))
                })
            }
        }
    }
}
</script>

<style>
#app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
</style>
