<template>
    <div style="display: flex; width: 100%" @click="closeAll">
        <!-- 领取荷包 -->
        <van-overlay :show="he_show">
            <div class="hebao" @click="ling_he">
                <img src="@/assrts/kaihe_1.png" style="width: 104px;height: 108px">
            </div>
            <div @click.stop="he_show = false">
                <img src="@/assrts/guanbi_hebao.png"
                    style="width: 36px;height: 36px;margin: 0 auto;display: block;margin-top: 36px">
            </div>
        </van-overlay>
        <!-- 个人名片弹窗 -->
        <van-popup v-model="card_show" style="border-radius: 8px;">
            <div style="width: 90vw;background: #fff;">
                <div style="padding: 20px">
                    <div style="font-size: 17px;margin-bottom: 10px">发送给：</div>
                    <div style="display: flex;margin-bottom: 20px">
                        <img :src="info.avatar" style="width: 40px;height: 40px;border-radius: 4px">
                        <div style="line-height: 40px;margin-left: 10px;color: #333;font-size: 14px">
                            {{ info.nickName }}
                        </div>
                    </div>
                    <div style="color: #999;font-size: 14px;margin-bottom: 20px">[个人名片]{{ info.nickName }}</div>
                    <div style="width: 100%;background-color: #ececec;line-height: 20px;height: 28px;overflow: hidden">
                        <input class="back_input" type="text" v-model="liulan" placeholder="给朋友留言">
                    </div>
                </div>
                <div style="display: flex;text-align: center;font-size: 14px;line-height: 50px">
                    <div style="width: 49.495vw;border-top: 0.5px solid #999" @click="card_show = false">取消</div>
                    <div style="width: 0.04vw;background-color: #999"></div>
                    <div style="width: 49.495vw;border-top: 0.5px solid #999;color: #FC9013" @click="fasong">发送</div>
                </div>
            </div>
        </van-popup>
        <div v-if="item_show" style="width: 100%;height: 100vh;position: absolute;top: 0;left: 0;z-index: 1999;"
            @click="item_show = false"></div>
        <div style="display: flex;width: 100%" v-if="message_list.length != 0">
            <!-- 动画 -->
            <div style="width: 64px;position: absolute;z-index: 2001;transition: width .3s ease-in;"
                :class="showcry ? 'cryz_z_show' : ''">
                <!-- 阴影 -->
                <div style="width:100vw;height:76vh;position: absolute;bottom: 0;right: 0;left: 0;z-index: 99;background-color: rgba(0,0,0,.5);"
                    v-if="showcry" @click="closez"></div>
                <!-- 点击展开 -->
                <div :class="showcry ? 'cryz' : 'cryz_z'" @click.stop="showleft" id="showcry"></div>
                <!-- 左列表 -->
                <div class="left" id="left">
                    <div v-for="(item, index) in message_list" :key="index" style="display: flex;"
                        :class="xuan_id == index ? 'zhi_back' : ''">
                        <div id="left-z" style="display: flex;align-items: center" @touchstart="gtouchstart(item, index)"
                            @touchmove="gtouchmove()" @touchend.prevent="showDeleteButton(index)">
                            <img :src="item.faceUrl" :id='`dian${index}`' style="pointer-events: none;"
                                :class="index == xuan_id ? 'xuan_touxiang' : 'touxiang'" />
                            <div style="position: relative;left: -28px;top: -20px">
                                <div class="dingdian">
                                    <div style="background-color: #FD4625;border-radius: 14px;">
                                        <div v-if="item.unReadCount && !item.isDonotDisturb">{{
                                                item.unReadCount > 99 ? '99+' : item.unReadCount
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <img src="@/assrts/7032.png" v-if="item.isDonotDisturb"
                                     style="width: 17px;height: 17px;margin-top: 23px;margin-left: 8px">
                            </div>
                            <div class="text" v-if="showtest" :style="{ 'color': showcry ? '' : 'transparent' }">{{
                                item.nickName
                            }}
                            </div>
                        </div>

                        <!-- <div v-if="item.show && item.isPinned == true" class="caidan">
                            <div class="pulic" style="width: 86px; height: 100%; background-color: #b2b2b2"
                                @click="pulichs(index, 1)">
                                <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                    <img src="@/assrts/quzhi.png" style="width: 17px; height: 17px" />
                                </div>
                                <div style="font-size: 13px; color: #fff">取消置顶</div>
                            </div>
                            <div class="pulic" style="background-color: #f75436" @click="pulichs(index, 2)">
                                <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                    <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                                </div>
                                <div style="font-size: 13px; color: #fff">删除</div>
                            </div>
                        </div>
                        <div v-else-if="item.show && item.isPinned == false" class="caidan">
                            <div class="pulic" style="background-color: #f89b3e" @click="pulichs(index, 3)">
                                <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                    <img src="@/assrts/779.png" style="width: 17px; height: 17px" />
                                </div>
                                <div style="font-size: 13px; color: #fff">置顶</div>
                            </div>
                            <div class="pulic" style="background-color: #f75436" @click="pulichs(index, 2)">
                                <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                                    <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                                </div>
                                <div style="font-size: 13px; color: #fff">删除</div>
                            </div>
                        </div> -->

                    </div>
                    <div style="width: 44px;height: 44px;"></div>
                </div>
            </div>
            <div class="caidan-z"
                style="width: 155px;height:10vh;position: absolute;left: -82px;z-index:2000;transition:all .2s;"
                :style="{ 'left': show_pic ? '64px' : '-82px' }">
                <div v-if="show && isPinned == true && showcry == false" class="caidan" id="caidan" style="z-index: 9999;">
                    <div class="pulic" style="width: 86px; height: 100%; background-color: #b2b2b2"
                        @click.stop="pulichs(index, 1)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/quzhi.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">取消置顶</div>
                    </div>
                    <div class="pulic" style="background-color: #f75436" @click.stop="pulichs(index, 2)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">删除</div>
                    </div>
                </div>
                <div v-else-if="show && isPinned == false && showcry == false" class="caidan" id="caidan"
                    style="z-index: 9999;">
                    <div class="pulic" style="background-color: #f89b3e" @click.stop="pulichs(index, 3)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/779.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">置顶</div>
                    </div>
                    <div class="pulic" style="background-color: #f75436" @click.stop="pulichs(index, 2)">
                        <div style="height: 37px;display: flex;align-items: center;justify-content: center">
                            <img src="@/assrts/shan.png" style="width: 17px; height: 17px" />
                        </div>
                        <div style="font-size: 13px; color: #fff">删除</div>
                    </div>
                </div>
            </div>
            <div class="left" style="width: 64px;"></div>
            <div class="rigth">
                <!-- 单聊 三个小点 -->
                <div class="T-box">
                    <div class="icon">
                        <img src="@/assrts/544.png"
                            @click="$router.push({ path: '/calendar', query: { id: active, account: message_list[xuan_id].toAccount } })" />
                    </div>
                    <!-- {{ message_list[xuan_id].toAccount }} -->
                    <div style="display: flex;align-items: center">
                        <div style="width: 150px; text-align: center;overflow: hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:1;font-weight: bold;"
                            v-if="message_list[this.xuan_id].remark == null || message_list[this.xuan_id].remark == ''">
                            {{
                                this.message_list[this.xuan_id].nickName
                            }}
                        </div>
                        <div style="text-align: center;overflow: hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:1;width: 150px;font-weight: bold;"
                            v-else>
                            {{
                                this.message_list[this.xuan_id].remark
                            }}
                        </div>
                        <img v-if="this.message_list[this.xuan_id].isDonotDisturb" src="@/assrts/7029.png"
                            style="width: 17px;height: 17px">

                    </div>
                    <div style="width: 24px">
                        <div class="icon" @click="more(message_list[xuan_id])" v-if="message_list[xuan_id].isRobot != 1">
                            <img src="@/assrts/21.png" alt="" />
                        </div>
                    </div>
                </div>
                <!-- 拍照 图片  语音 视频 -->
                <div class="B-box" v-if="message_list[xuan_id].isRobot != 1">
                    <div style="padding: 0 8.5px"></div>
                    <div v-for="(item, index) in functions_list" :key="index" @click="fun_click(index)">
                        <van-uploader :after-read="afterRead" accept="image/*,video/*" capture="camera" v-if="index == 0">
                            <img :src="item" class="fun_img" style="margin-top: 9px" />
                        </van-uploader>
                        <van-uploader :after-read="afterRead" accept="image/*" v-else-if="index == 1">
                            <img :src="item" class="fun_img" style="margin-top: 9px" />
                        </van-uploader>
                        <img :src="item" class="fun_img" v-else />
                    </div>
                </div>
                <!-- 聊天内容区域 -->
                <div class="chat_content" id="chat_content" :style="{
                    'background-image': message_list[xuan_id].backdrop != '' ?
                        'url(' + message_list[xuan_id].backdrop + ')'
                        : '',
                    'background-repeat': 'no-repeat',
                }">
                    <div id="chat_content_xiang">

                        <div class="chat_body" v-for="(item, index) in session_list" :key="index"
                            style="background: url();">
                            <div class="chat_time">{{ item.timea }}</div>
                            <!-- 接收方 -->
                            <div v-if="item.userID == $user_info.account" class="body_left">
                                <div
                                    v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType == 99 && item.MsgBody[0].MsgContent.Data.msgType == 300 && item.MsgBody[0].MsgContent.Data.msgType == 301">
                                </div>
                                <!-- justify-content: center; -->
                                <div style="display:flex; ">
                                    <img :src="message_list[xuan_id].faceUrl" class="body_avatar" @click.stop="goUser(item)"
                                        v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType != 99 && item.MsgBody[0].MsgContent.Data.msgType != 300 && item.MsgBody[0].MsgContent.Data.msgType != 301" />
                                    <div v-if="item.MsgBody[0].MsgContent.Data">
                                        <!-- 聚信荷包 -->
                                        <div class="left_envelope left_mess" @click="envelope(1, index)"
                                            :style="item.MsgBody[0].MsgContent.Data.msgJson.status != 0 ? 'background-color: #F6B77C' : 'background-color: #fc9013'"
                                            v-if="item.MsgBody[0].MsgContent.Data.msgType == 2">
                                            <div style="display: flex; align-items: center;">
                                                <img src="@/assrts/envelope.png" style="width: 33px; height: 33px" />
                                                <div class="envelope_text"
                                                    style="width: 45vw; display: flex;align-items: center;flex-wrap: wrap;">
                                                    <p style="margin: 0; width: 100%;">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain ?
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain : '荷包鼓鼓，招财纳福'
                                                        }}</p>
                                                    <p style="margin: 5px 0 0 0;font-size: 12px;width: 100%;"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                        已过期</p>
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="font-size: 10px;opacity: 0.6;color: #fff;margin: 6px 0 7px 13px;">
                                                聚信荷包
                                            </div>
                                        </div>
                                        <!-- 聚信转账 -->
                                        <div class="left_transfer left_mess" @click="transfer(item)"
                                            :style="item.MsgBody[0].MsgContent.Data.msgJson.status == 0 ? 'background-color: #FA902C' : 'background-color: #F6B77C'"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 3">
                                            <div style="display: flex">
                                                <img style=" width: 33px; height: 33px"
                                                    :src="item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? wancheng :
                                                        item.MsgBody[0].MsgContent.Data.msgJson.status == 2 ? guoqi : dengdai" />
                                                <div class="transfer_text">
                                                    <div style="margin-top: 15px; width: 120px;">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type == 1 ? '￥' :
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type ==
                                                                2 ? '代金券' : item.MsgBody[0].MsgContent.Data.msgJson.type == 3 ? '积分'
                                                                : ''
                                                        }}{{
    item.MsgBody[0].MsgContent.Data.msgJson.money
}}
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 0">
                                                        请收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 1">
                                                        已被收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                        已过期
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="font-size: 10px;opacity: 0.6;color: #fff;margin: 6px 0 7px 13px;">
                                                聚信转账
                                            </div>
                                        </div>
                                        <!-- 个人名片 -->
                                        <div class="left_card left_mess" @click="goUser(item, 1)"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 4">
                                            <div style="display: flex">
                                                <img :src="item.MsgBody[0].MsgContent.Data.msgJson.avatar"
                                                    style="width: 33px; height: 33px" />
                                                <div class="card_text">
                                                    {{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.name
                                                    }}
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #eeeeee; width: 100%"></div>
                                            <div style="font-size: 10px;opacity: 0.6;color: #ccc;margin: 6px 0 7px 13px;">
                                                个人名片
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 99 || item.MsgBody[0].MsgContent.Data.msgType == 300 || item.MsgBody[0].MsgContent.Data.msgType == 301">
                                            <div style="display: flex;justify-content: space-between;width: 78vw;"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 42">
                                                <div></div>
                                                <div
                                                    style="display: flex;justify-content: center; color: #CCCCCC;font-size: 10px">
                                                    <img src="@/assrts/envelope.png" style="width: 17px; height: 17px" />
                                                    <div>{{
                                                        message_list[xuan_id].nickName
                                                    }}领取了你的
                                                    </div>
                                                    <div style="color: #FC9013">荷包</div>
                                                </div>
                                                <div></div>

                                            </div>
                                            <div style="display: flex;justify-content: space-between;width: 78vw;"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 41">
                                                <div></div>
                                                <div style="text-align: center;color: #CCCCCC;font-size: 10px;">
                                                    {{ message_list[xuan_id].nickName }}领取了你的转账
                                                </div>
                                                <div></div>
                                            </div>
                                            <div v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 1 || item.MsgBody[0].MsgContent.Data.msgJson.type == -1"
                                                style="width: 77.5vw;background-color: #fff;overflow: hidden;border-radius: 4px">
                                                <div style="margin: 12px 13px;font-size: 13px;color: #181818">
                                                    {{ item.MsgBody[0].MsgContent.Data.msgJson.content }}
                                                </div>
                                                <div style="font-size: 10px;color: #999;margin-left: 13px;">
                                                    {{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.type == 1 ? "通过时间" : "驳回时间"
                                                    }}
                                                    <span style="margin-left: 5px">
                                                        {{ timestampToTime(item.MsgTime) }}
                                                    </span>
                                                </div>
                                                <div style="height: 10px;"></div>
                                            </div>
                                            <div v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 0"
                                                style="width: 77.5vw;background-color: #fff;overflow: hidden;border-radius: 4px">
                                                <div style="margin: 12px 13px;font-size: 13px;color: #181818">
                                                    {{ item.MsgBody[0].MsgContent.Data.msgJson.content }},
                                                    <div style="color: #FA902C"
                                                        @click="$router.push({ path: '/Application_Details', query: { id: item.MsgBody[0].MsgContent.Data.msgJson.id } })">
                                                        点击查看详情
                                                    </div>
                                                </div>
                                                <div style="font-size: 10px;color: #999;margin-left: 13px;">
                                                    申请时间
                                                    <span style="margin-left: 5px">
                                                        {{ timestampToTime(item.MsgTime) }}
                                                    </span>
                                                </div>
                                                <div style="height: 10px;"></div>
                                            </div>
                                            <div v-if="item.MsgBody[0].MsgContent.Data.msgJson.businessType"
                                                 style="width: 77.5vw;background-color: #fff;overflow: hidden;border-radius: 4px;padding: 10px 10px 0 10px; box-sizing: border-box;border-radius: 5px;"
                                                 @click="jumpCont(item.MsgBody[0].MsgContent.Data.msgJson)">
                                                <div style="color: #181818;font-size: 18px;">
<!--                                                  //1提现，2转账;3更换授权人成，4更换授权人发起审核 5除授权人成 6删除授权人发起申请   3,5是授权人消息；4，6是授权人更改消息-->
                                                  {{item.MsgBody[0].MsgContent.Data.msgJson.businessType==1?'提现申请消息':item.MsgBody[0].MsgContent.Data.msgJson.businessType==2?'转账申请消息':(item.MsgBody[0].MsgContent.Data.msgJson.businessType==3||item.MsgBody[0].MsgContent.Data.msgJson.businessType==5) ?'授权人消息':'授权人更改消息'}}
                                                </div>
                                                <div style="margin: 12px 13px;font-size: 13px;color: #181818">
                                                    {{ item.MsgBody[0].MsgContent.Data.msgJson.content }}
                                                </div>
                                                <div style="font-size: 10px;color: #999; margin-bottom: 10px;">
                                                    申请时间
                                                    <span style="margin-left: 5px">
                                                        {{ timestampToTime(item.MsgTime) }}
                                                    </span>
                                                </div>
                                                <div v-if="item.MsgBody[0].MsgContent.Data.msgJson.businessType == 1 || item.MsgBody[0].MsgContent.Data.msgJson.businessType == 2"
                                                        style="display: flex; width: 100%;height: 30px; justify-content: space-between; align-items: center; border-top: 1px solid #eee;">
                                                    <div style="color: #FA902C;font-size: 15px;">
                                                        点击查看详情
                                                    </div>
                                                    <img src="../assrts/right-r.png" style="width: 16px; height: 16px;" />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 视频消息 -->
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 102"
                                            style="display: flex">
                                            <video class="right_video"
                                                :poster="item.MsgBody[0].MsgContent.Data.msgJson.snapshotUrl"
                                                autoHide="true" controls="controls">
                                                <source :src="item.MsgBody[0].MsgContent.Data.msgJson.url" type="video/mp4">
                                            </video>
                                        </div>
                                        <!-- 余额明细 -->
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 31"
                                            class="left_text left_mess">
                                            <div style="font-weight: bold;margin-bottom: 5px">
                                                余额提醒
                                            </div>
                                            <div v-for="(item, index) in item.MsgBody[0].MsgContent.Data.msgJson.cashTypeVos"
                                                :key="index">
                                                <div style="font-size: 14px">{{ item.name }}</div>
                                                <div style="font-size: 14px">账户可用余额 {{
                                                    item.money
                                                }}元
                                                </div>
                                            </div>
                                            <div style="font-size: 12px;margin-top: 5px;color: #999">
                                                {{ item.MsgBody[0].MsgContent.Data.msgJson.timea }}
                                            </div>
                                        </div>
                                        <div v-else style="display: flex">
                                            <div class="left_text left_mess">
                                                暂不支持此消息
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 图片消息 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMImageElem'" style="display: flex">
                                        <img :src="message_list[xuan_id].faceUrl" class="body_avatar"
                                            @click.stop="goUser(item)" />

                                        <img :src="item.MsgBody[0].MsgContent.ImageInfoArray ? item.MsgBody[0].MsgContent.ImageInfoArray[2].URL : item.MsgBody[0].MsgContent.imageInfoArray[2].url"
                                            @click.stop="ImgPopupShow(item)" class="right_img">

                                    </div>
                                    <div v-else-if="item.type == 5" class="left_mess">
                                        <div style="margin: 15px 10px; font-size: 15px; color: #333333">
                                            对公消息申请
                                        </div>
                                        <div style="margin: 0 10px 5px; font-size: 15px; color: #333333">
                                            厂家：申请方的聚信号(厂家名称)申请使用河北坤州网络科技有限公司对公账号，点击
                                            <span @click.stop="look" style="color: #ff9113">查看详情</span>
                                        </div>
                                        <div
                                            style="margin: 0 10px;padding: 5px 0px 14px 0px;font-size: 12px;color: #999999;border-top: 1px solid #ccc;">
                                            申请时间 2023-10-11 12:23:12
                                        </div>
                                    </div>
                                    <!-- 文本消息 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMTextElem'" style="display: flex">
                                        <img :src="message_list[xuan_id].faceUrl" class="body_avatar"
                                            @click="goUser(item)" />
                                        <div class="left_text left_mess">
                                            {{ item.MsgBody[0].MsgContent.Text }}
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                            <!--发送方-->
                            <div v-else class="body_right">
                                <div></div>
                                <div style="display: flex">
                                    <div v-if="item.MsgBody[0].MsgContent.Data">
                                        <!-- 聚信荷包 -->
                                        <div class="right_envelope right_mess"
                                            :style="item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? 'background-color: #F6B77C' : 'background-color: #FA902C'"
                                            @click="envelope(2, index)" v-if="item.MsgBody[0].MsgContent.Data.msgType == 2">
                                            <div style="display: flex">
                                                <img src="@/assrts/envelope.png" style="width: 33px; height: 33px" />
                                                <div class="envelope_text"
                                                    style="width: 45vw; display: flex;align-items: center;flex-wrap: wrap;">
                                                    <p style="margin: 0; width: 100%;">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain ?
                                                            item.MsgBody[0].MsgContent.Data.msgJson.explain : '荷包鼓鼓，招财纳福'
                                                        }}</p>
                                                    <p style="margin: 5px 0 0 0;font-size: 12px;width: 100%;"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                        已过期</p>
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="
                    font-size: 10px;
                    opacity: 0.6;
                    color: #fff;
                    margin: 6px 0 7px 13px;
                  ">
                                                聚信荷包
                                            </div>
                                        </div>
                                        <!-- 聚信转账 -->
                                        <div class="right_transfer right_mess"
                                            :style="item.MsgBody[0].MsgContent.Data.msgJson.status == 0 ? 'background-color: #FA902C' : 'background-color: #F6B77C'"
                                            @click="transfer(item)"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 3">
                                            <div style="display: flex">
                                                <img style=" width: 33px; height: 33px"
                                                    :src="item.MsgBody[0].MsgContent.Data.msgJson.status == 1 ? wancheng :
                                                        item.MsgBody[0].MsgContent.Data.msgJson.status == 2 ? guoqi : dengdai" />
                                                <div class="transfer_text">
                                                    <div style="margin-top: 15px;width: 120px;">
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type == 1 ? '￥' : ''
                                                        }}
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type == 2 ? '代金券' : ''
                                                        }}
                                                        {{
                                                            item.MsgBody[0].MsgContent.Data.msgJson.type == 3 ? '积分' : ''
                                                        }}
                                                        {{ item.MsgBody[0].MsgContent.Data.msgJson.money }}
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 0">
                                                        请收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 1">
                                                        已被收款
                                                    </div>
                                                    <div style="margin-bottom: 9px"
                                                        v-if="item.MsgBody[0].MsgContent.Data.msgJson.status == 2">
                                                        已过期
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #ffc680; width: 100%"></div>
                                            <div style="
                    font-size: 10px;
                    opacity: 0.6;
                    color: #fff;
                    margin: 6px 0 7px 13px;
                  ">
                                                聚信转账
                                            </div>
                                        </div>
                                        <!-- 个人名片 -->
                                        <div class="right_card right_mess" style="margin-right: 10px"
                                            @click.stop="goUser(item, 1)"
                                            v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 4">
                                            <div style="display: flex">
                                                <img :src="item.MsgBody[0].MsgContent.Data.msgJson.avatar"
                                                    style="width: 33px; height: 33px;object-fit: cover;" />
                                                <div class="card_text">
                                                    {{
                                                        item.MsgBody[0].MsgContent.Data.msgJson.name
                                                    }}
                                                </div>
                                            </div>
                                            <div style="height: 1px; background-color: #eeeeee; width: 100%"></div>
                                            <div style="
                    font-size: 10px;
                    opacity: 0.6;
                    color: #ccc;
                    margin: 6px 0 7px 13px;
                  ">
                                                个人名片
                                            </div>
                                        </div>
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 99">
                                            <div style="display: flex"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 42">
                                                <img src="@/assrts/envelope.png" style="width: 17px; height: 17px" />
                                                <div style="display: flex;color: #CCCCCC;font-size: 10px">
                                                    <div>你领取了{{
                                                        message_list[xuan_id].nickName
                                                    }}的
                                                    </div>
                                                    <div style="color: #FC9013">荷包</div>
                                                </div>
                                            </div>
                                            <div style="display: flex"
                                                v-if="item.MsgBody[0].MsgContent.Data.msgJson.type == 41">
                                                <div style="display: flex;color: #CCCCCC;font-size: 10px">
                                                    你领取了{{ message_list[xuan_id].nickName }}的转账
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 视频消息 -->
                                        <div v-else-if="item.MsgBody[0].MsgContent.Data.msgType == 102"
                                            style="display: flex">
                                            <video class="right_video"
                                                :poster="item.MsgBody[0].MsgContent.Data.msgJson.snapshotUrl"
                                                autoHide="true" controls="controls">
                                                <source :src="item.MsgBody[0].MsgContent.Data.msgJson.url" type="video/mp4">
                                            </video>
                                        </div>
                                        <div v-else style="display: flex">
                                            <div class="right_text right_mess">
                                                暂不支持此消息
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 图片 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMImageElem'" style="display: flex">
                                        <img :src="item.MsgBody[0].MsgContent.ImageInfoArray ? item.MsgBody[0].MsgContent.ImageInfoArray[2].URL : item.MsgBody[0].MsgContent.imageInfoArray[2].url"
                                            @click.stop="ImgPopupShow(item)" class="right_img">
                                        <img :src="$user_info.avatar" @click.stop="goUser(item)" class="body_avatar" />
                                    </div>
                                    <!-- 文本消息 -->
                                    <div v-else-if="item.MsgBody[0].MsgType == 'TIMTextElem'" style="display: flex">
                                        <div class="right_text right_mess">
                                            {{
                                                item.MsgBody[0].MsgContent.Text ? item.MsgBody[0].MsgContent.Text :
                                                item.MsgBody[0].MsgContent.text
                                            }}
                                        </div>
                                        <img :src="$user_info.avatar" @click.stop="goUser(item)" class="body_avatar" />
                                    </div>
                                    <img :src="$user_info.avatar" @click.stop="goUser(item)" class="body_avatar"
                                        v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType != 99" />
                                </div>
                                <div
                                    v-if="item.MsgBody[0].MsgContent.Data && item.MsgBody[0].MsgContent.Data.msgType == 99">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 语音 输入框 表情 -->
                <div class="chat_bottom" style="padding: 10px 0 20px 0">
                    <div style="background: #f2f2f5;display: flex;width: 95%">
                        <input type="text" v-model.trim="message" id="text" class="bottom_input"
                            @focus="biao_show = false" />
                        <div style="font-size: 15px;color: #FA902C;line-height: 30px;margin-left: 10px"
                            @click="pinot(message)">
                            发送
                        </div>
                    </div>

                    <!--                    <img src="@/assrts/32.png" @click="biao_show = !biao_show" class="expression"/>-->
                    <!-- 表情键盘 -->
                </div>
                <div style="height: 120px" v-if="biao_show">
                    <van-popup v-model="biao_show" position="bottom" :overlay="false" class="pur_emoji">
                        <div v-for="(item, index) in emoji" :key="index" @click="emoji_cli(item)" style="
              width: 38px;
              height: 45px;
              font-size: 25px;
              text-align: center;
              line-height: 45px;
            ">
                            {{ item }}
                        </div>
                    </van-popup>
                </div>
            </div>
        </div>
        <div v-else
            style="background-color:#F2F2F5;color: #ccc;width: 100vw;font-size: 15px;display: flex;align-items: center;justify-content: space-between">
            <div></div>
            暂无聊天
            <div></div>
        </div>
        <div class="imgPopup" @click="imgShow = false" v-show="imgShow">
            <div style="display: flex; justify-content: center; align-items: center;width:100%;height: 100vh;">
                <img :src="BigImg" alt="" style="width:100% ;">
            </div>
        </div>
    </div>
</template>

<script>
import emoji from "@/utils/emoji";
import { set } from "core-js/internals/weak-map-helpers";
import { Dialog } from "vant";

export default {
    name: "index",
    props: ["active"],
    data() {
        return {
            show_pic: false,
            left_t: 0,
            show_in: false,
            index: 0,
            show: false,
            isPinned: false,
            showcry: false,//是否展开
            showtest: false,
            imgShow: false, //是否展示大图
            BigImg: '', //图片路径
            time_bian: '',
            liulan: '',
            info: '',
            card_show: false,
            wid_val: '',
            wancheng: require('@/assrts/5508.png'),
            guoqi: require('@/assrts/7881.png'),
            dengdai: require('@/assrts/transfer.png'),
            MsgKey: '',
            he_uuid: '',
            he_show: false, //
            showPopover: '',
            value1: "",
            lastTime: 0, //默认上一次点击时间为8
            item_show: false,
            item_index: 0,
            message: "", // 文本消息
            emoji: "", // 表情库
            biao_show: false, // 表情键盘的显隐
            xuan_id: 0, // 选中的下标
            message_list: [],
            session_list: [],
            functions_list: [
                require("@/assrts/xiangji.png"),
                require("@/assrts/tuku.png"),
                require("@/assrts/hebao.png"),
                require("@/assrts/zhuanjzhang.png"),
                require("@/assrts/sousuolishi.png"),
                require("@/assrts/gerenmingpian.png")
            ],
        };
    },
    watch: {
        item_show(news) {
            if (news == false) {
                this.message_list[this.item_index].show = false;
            }
        },
    },
    mounted() {
        if (sessionStorage.getItem('account')) {
            let account = sessionStorage.getItem('account')
            this.$axios({
                url: '/bkx-base-user/juxinFriends/searchFriends',
                method: 'GET',
                params: {
                    accountOrPhone: account
                }
            }).then(res => {
                if (res.code == 200) {

                    this.info = res.data
                    this.card_show = true

                } else {
                    this.$toast(res.msg)
                }
                this.$nextTick(() => {
                    sessionStorage.removeItem('account')
                })

            })
        }
        this.emoji = emoji.emoji.data.split(',')
        this.queryH5()
    },
    methods: {
        closeAll() {
            this.show = false
            this.isPinned = false
            this.show_pic = false
        },
        /**
         * 时间处理
         * */
        time_out(message) {
            //获取当前时间 tims为当前的日期
            var new_d = new Date();
            var new_n = new_d.getFullYear();
            var new_y = new_d.getMonth() + 1;
            new_y = new_y < 10 ? ('0' + new_y) : new_y;
            var new_r = new_d.getDate();
            let tims = new_n + '-' + new_y + '-' + (new_r < 10 ? `0${new_r}` : new_r)
            //转换腾讯云给的时间 tima为转换出来的日期
            var new_data = new Date(message.time ? message.time * 1000 : message.MsgTime * 1000)
            let y = new_data.getFullYear();
            let m = new_data.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            let d = new_data.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = new_data.getHours();
            let minute = new_data.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            let tima = y + '-' + m + '-' + d
            if (this.time_bian != h + ':' + minute) {
                this.time_bian = h + ':' + minute
                if (tima == tims) {
                    return h + ':' + minute
                } else {
                    return y + '-' + m + '-' + d + ' ' + h + ':' + minute
                }
            }
        },
        //是否展开左侧
        showleft() {
            this.showcry = !this.showcry
            if (this.showcry) {
                setTimeout(() => {
                    this.showtest = true
                    this.show = false
                    this.isPinned = false
                }, 300)
            } else {
                this.showtest = false
                this.show = false
                this.isPinned = false
            }
        },
        //点击遮罩层关闭
        closez() {
            this.showcry = false
            if (this.showcry) {
                setTimeout(() => {
                    this.showtest = true
                }, 300)
            } else {
                this.showtest = false
            }
        },
        queryH5() {
            this.$axios({
                url: '/juxin-app/dialogue/queryH5',
                method: 'GET',
                params: {
                    pageNum: 1,
                    pageSize: 0
                }
            }).then(res => {
                if (res.code == 200) {
                    this.message_list = []
                    for (let i = 0; i < res.data.list.length; i++) {
                        if (res.data.list[i].appUuid[0] == 'c') {
                            if (this.$user_info.roleType == 100 || this.$user_info.roleType == 200 && res.data.list[i].isRobot == 1) {
                                this.message_list.push(res.data.list[i])
                            } else if (res.data.list[i].isRobot != 1) {
                                this.message_list.push(res.data.list[i])
                            }
                        }
                    }
                    if (this.$route.query.toaccount) {
                        for (let j = 0; j < this.message_list.length; j++) {
                            if (this.$route.query.toaccount == this.message_list[j].toAccount) {
                                console.log(this.$route.query.toaccount,'this.xuan_id')

                                this.xuan_id = j
                            }
                        }
                    }
                    this.$axios.put('juxin-app/dialogue/updateIsRead', {
                        uuid: this.message_list[this.xuan_id].uuid
                    }).then(res => {
                        if (res.code == 200) {
                            this.message_list[this.xuan_id].unReadCount = 0
                        }
                    })
                    if (this.message_list.length != 0) {
                        // 判断是否从选择日期过来的,如果是,则从选择的日期开始渲染
                        if (this.$route.query.time) {
                            this.$axios({
                                url: '/bkx-juxin-app/chattingRecords/getMsgPersonal',
                                method: 'GET',
                                params: {
                                    startTime: this.$route.query.time,
                                    keyword: '',
                                    account: this.$user_info.account,
                                    tAccount: this.message_list[this.xuan_id].toAccount,
                                    type: 0
                                }
                            }).then(res => {
                                if (res.code == 200) {
                                    let data = []
                                    for (let i = 0; i < res.data.length; i++) {
                                        if (res.data[i].MsgBody[0].MsgContent.Data) {
                                            if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 99) {
                                                if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson.type != 52) {
                                                    data.push(res.data[i])
                                                }
                                            } else {
                                                data.push(res.data[i])
                                            }
                                        } else {
                                            data.push(res.data[i])
                                        }
                                    }
                                    this.session_list = data
                                    for (let i = 0; i < this.session_list.length; i++) {
                                        if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                            this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                            if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                                this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                            }
                                        }

                                        if (i >= 1) {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        } else {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        }

                                    }
                                    this.jian_message()
                                    this.nextTick()
                                } else {
                                    this.$toast(res.msg)
                                }
                            })
                        } else {
                            this.$axios({
                                url: '/bkx-juxin-app/chattingRecords/getMsgPersonal',
                                method: 'GET',
                                params: {
                                    account: this.$user_info.account,
                                    tAccount: this.message_list[this.xuan_id].toAccount,
                                    type: 0
                                }
                            }).then(res => {
                                if (res.code == 200) {
                                    let data = []
                                    for (let i = 0; i < res.data.length; i++) {
                                        if (res.data[i].MsgBody[0].MsgContent.Data) {
                                            if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 99) {
                                                if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson.type != 52) {
                                                    data.push(res.data[i])
                                                }
                                            } else if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 31) {
                                                let date = new Date(JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson.date)
                                                let Y = date.getFullYear() + '年'
                                                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
                                                let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
                                                let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
                                                let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
                                                let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
                                                res.data[i].MsgBody[0].MsgContent.Data = JSON.parse(res.data[i].MsgBody[0].MsgContent.Data)
                                                res.data[i].MsgBody[0].MsgContent.Data.msgJson.timea = Y + M + D + h + m + s
                                                res.data[i].MsgBody[0].MsgContent.Data = JSON.stringify(res.data[i].MsgBody[0].MsgContent.Data)
                                                data.push(res.data[i])
                                            } else {
                                                data.push(res.data[i])
                                            }
                                        } else {
                                            data.push(res.data[i])
                                        }
                                    }
                                    this.session_list = data
                                    for (let i = 0; i < this.session_list.length; i++) {
                                        if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                            this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                            if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                                this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                            }
                                        }

                                        if (i >= 1) {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        } else {
                                            this.session_list[i].timea = this.time_out(this.session_list[i])
                                        }
                                    }
                                    this.jian_message()
                                    this.nextTick()
                                } else {
                                    this.$toast(res.msg)
                                }
                            })
                        }
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // look 查看详情
        look() {
            this.$router.push('/Duigong_details')
        },
      /**
       * 跳转聚鑫团队信息*/
        jumpCont(item){
          if(item.businessType === 1 || item.businessType === 2){
            this.$router.push({ path: '/details', query: { info: item } })
          }
        },
        /**
         * 监听消息
         * */
        jian_message() {
            this.deng_tim(this.$user_info.account.toString())
            let that = this
            let onMessageReceived = function (event) {
                let data = event.data[0]
                if (typeof data.payload.data == 'string') {
                    data.payload.data = JSON.parse(data.payload.data)
                }
                if (data.payload.data && data.payload.data.msgType != 99) {
                    that.$emit('dan_shu', '添加小红点')

                }
                if (!data.payload.data || data.payload.data.msgType != 201) {
                    for (let i = 0; i < that.message_list.length; i++) {
                        if (that.message_list[that.xuan_id].toAccount != data.from) {
                            if (+that.message_list[i].toAccount == +data.from && data.conversationType == 'C2C') {
                                that.message_list[i].unReadCount += 1
                            }
                        }
                    }
                    if (data.from == that.message_list[that.xuan_id].userID) {
                        data.userID = data.to
                        if (data.type == "TIMTextElem") {
                            data.MsgBody = [
                                {
                                    MsgType: data.type,
                                    MsgContent: {
                                        Text: data.payload.text
                                    }
                                }
                            ]
                        } else if (data.type == "TIMImageElem") {
                            data.MsgBody = [
                                {
                                    MsgType: data.type,
                                    MsgContent: {
                                        ImageInfoArray: [{ a: 1 }, { a: 1 }, {
                                            URL: data.payload.imageInfoArray[0].imageUrl
                                        }]
                                    }
                                }
                            ]
                        } else {
                            if (typeof data.payload.data == 'string') {
                                data.payload.data = JSON.parse(data.payload.data)
                            }
                            if (typeof data.payload.data.msgJson == 'string') {
                                data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                            }
                            data.MsgBody = [
                                {
                                    MsgType: data.type,
                                    MsgContent: {
                                        Data: data.payload.data,
                                        Desc: "",
                                        Ext: "",
                                        Sound: ""
                                    }
                                }
                            ]
                        }
                        that.session_list.push(data)
                        setTimeout(() => {
                            that.nextTick()
                        }, 500)
                    } else {
                        for (let i = 0; i < that.message_list.length; i++) {
                            if (that.message_list[i].toAccount == data.from) {
                                if (that.message_list[i].isPinned == true) {
                                    that.message_list[i].isPinned = 1
                                    that.ceshi = that.message_list[i] //声明一个对象保存符合要求的数据
                                    that.message_list.splice(i, 1)//先把符合条件的数据从当前数组中删除
                                    that.message_list.unshift(that.ceshi)//通过unshift函数把符合要求的数据放到第一位
                                } else if (that.message_list[i].isPinned == false) {
                                    that.message_list[i].isPinned = 0
                                    that.ceshi = that.message_list[i] //声明一个对象保存符合要求的数据
                                    that.message_list.splice(i, 1)//先把符合条件的数据从当前数组中删除
                                    for (let i = 0; i < that.message_list.length; i++) {
                                        if (that.message_list[i].isPinned == 0) {
                                            that.message_list.splice(i, 0, that.ceshi);
                                            break
                                        }
                                    }
                                }
                                break
                            }
                        }
                    }
                    // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
                    // event.name - TIM.EVENT.MESSAGE_RECEIVED
                    // event.data - 存储 Message 对象的数组 - [Message]
                } else if (data.payload.data.msgType == 201) {
                    that.new_user_info(that.$user_info.uuid, that.$user_info.roleType, '/');
                }
            };
            this.$tim.on(this.$TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
        },
        /**
         * 移动到最底部
         * */
        nextTick() {
            // 定位聊天位置
            if (!this.$route.query.time) {
                this.$nextTick(() => {
                    document.getElementById('chat_content').scrollTop = document.getElementById('chat_content_xiang').clientHeight
                })
            }

        },

        /**
         * 发送个人名片
         * */
        fasong() {
            this.$toast.loading({
                message: '发送中...',
                forbidClick: true,
            });
            let that = this
            let msgJson = {
                uuid: this.info.account,
                userID: this.info.account,
                avatar: this.info.avatar,
                name: this.info.nickName,
                sign: '',
            }
            let data = {
                msgType: 4,
                msgJson: msgJson
            }
            let message = this.$tim.createCustomMessage({
                to: JSON.stringify(this.message_list[this.xuan_id].toAccount),
                conversationType: this.$TIM.TYPES.CONV_C2C,
                payload: {
                    data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
                    description: '', // 获取骰子点数
                    extension: ''
                }
            });
            let promise = this.$tim.sendMessage(message);
            promise.then(function (imResponse) {
                that.pulish_xiao(that.$user_info.account, JSON.stringify(that.message_list[that.xuan_id].toAccount))
                that.$router.push('/')
                that.card_show = false
                let data = imResponse.data.message
                if (typeof data.payload.data == 'string') {
                    data.payload.data = JSON.parse(data.payload.data)
                }
                if (typeof data.payload.data.msgJson == 'string') {
                    data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                }
                data.MsgBody = [
                    {
                        MsgType: data.type,
                        MsgContent: {
                            Data: data.payload.data,
                            Desc: "",
                            Ext: "",
                            Sound: ""
                        }
                    }
                ]
                that.session_list.push(data)
                if (that.liulan) {
                    that.go_mesage(that.liulan)
                }
                setTimeout(() => {
                    that.nextTick()
                    that.$toast.clear()
                }, 500)
            }).catch(function (imError) {
                // 发送失败
                console.warn('sendMessage error:', imError);
            });
        },
        /**
         * 前往荷包页面
         * */
        envelope(id, index) {
            if (id == 1 && !this.session_list[index].MsgBody[0].MsgContent.Data.msgJson.status) {
                this.he_show = true
                this.he_uuid = this.session_list[index].MsgBody[0].MsgContent.Data.msgJson.uuid
                this.MsgKey = this.session_list[index].MsgKey
            } else {
                this.$router.push({
                    path: '/envelope',
                    query: {
                        active: this.active,
                        uuid: this.he_uuid ? this.he_uuid : this.session_list[index].MsgBody[0].MsgContent.Data.msgJson.uuid,
                        account: this.message_list[this.xuan_id].toAccount
                    }
                })
            }
        },
        /**
         * 领取荷包
         * */
        ling_he() {
            this.$axios.post('/bkx-pay/redpacket/gettoreceive', {
                uuid: this.he_uuid,
                targetMsgID: this.MsgKey
            }).then(res => {
                if (res.code == 200) {
                    this.envelope(2)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        /**
         * 前往转账页面
         * */
        transfer(item) {
            this.$router.push({
                path: '/transfer',
                query: {
                    uuid: item.MsgBody[0].MsgContent.Data.msgJson.uuid,
                    MsgKey: item.MsgKey,
                    account: this.message_list[this.xuan_id].toAccount
                }
            })
        },
        /**
         * 前往好友信息页面
         * */
        goUser(item, id) {
            // if (this.message_list[this.xuan_id].isRobot == 0) {
            this.$router.push({
                path: `/blackListDetails`,
                query: {
                    account: id ? item.MsgBody[0].MsgContent.Data.msgJson.userID : item.From_Account,
                    toaccount: id ? this.message_list[this.xuan_id].toAccount : '',
                    ida: 1,
                    num: 123
                }
            })
            // }
        },
        /**
         * 文本消息添加表情
         * */
        emoji_cli(item) {
            this.message = `${this.message}${item}`
        },
        pinot(message) {
            let now = new Date().valueOf();
            //第一次点击
            if (this.lastTime == 0) {
                this.go_mesage(message);//首次点击调用方法
                this.lastTime = now;
            } else {
                if ((now - this.lastTime) > 1000) {
                    this.lastTime = now;
                    this.go_mesage(message);//首次点击调用方法
                }
            }
        },
        /**
         * 发送消息
         * */
        go_mesage(text) {
            document.getElementById('text').focus()
            let that = this
            let message = this.$tim.createTextMessage({
                to: JSON.stringify(this.message_list[this.xuan_id].toAccount),
                conversationType: this.$TIM.TYPES.CONV_C2C,
                payload: {
                    text: text ? text : this.message
                },
                needReadReceipt: true
            });
            // 2. 发送消息
            let promise = this.$tim.sendMessage(message);
            promise.then(function (imResponse) {
                that.message = ''
                that.time_bian = ''
                let timea = that.time_out(imResponse.data.message)
                if (that.session_list.length != 0) {
                    if (that.session_list[that.session_list.length - 1].timea != timea) {
                        imResponse.data.message.timea = timea
                    }
                } else {
                    imResponse.data.message.timea = timea
                }
                that.pulish_xiao(that.$user_info.account, JSON.stringify(that.message_list[that.xuan_id].toAccount))
                imResponse.data.message.userID = imResponse.data.message.to
                imResponse.data.message.MsgBody = [
                    {
                        MsgType: imResponse.data.message.type,
                        MsgContent: {
                            Text: imResponse.data.message.payload.text
                        }
                    }
                ]
                let index = that.xuan_id
                that.session_list.push(imResponse.data.message)
                if (that.message_list.length > 1) {
                    if (that.message_list[index].isPinned == true) {
                        that.message_list[index].isPinned = 1
                        that.ceshi = that.message_list[index] //声明一个对象保存符合要求的数据
                        that.message_list.splice(index, 1)//先把符合条件的数据从当前数组中删除
                        that.message_list.unshift(that.ceshi)//通过unshift函数把符合要求的数据放到第一位
                        that.xuan_id = 0
                    } else if (that.message_list[index].isPinned == false) {
                        that.message_list[index].isPinned = 0
                        that.ceshi = that.message_list[index] //声明一个对象保存符合要求的数据
                        that.message_list.splice(index, 1)//先把符合条件的数据从当前数组中删除
                        for (let i = 0; i < that.message_list.length; i++) {
                            if (that.message_list[i].isPinned == 0) {
                                that.message_list.splice(i, 0, that.ceshi);
                                that.xuan_id = i
                                break
                            }
                        }
                    }
                }
                setTimeout(() => {
                    that.delexiao(that.message_list[that.xuan_id].uuid)
                }, 500)
                setTimeout(() => {
                    that.nextTick()
                }, 200)
            }).catch(function (imError) {
                that.$toast(imError) // 发送失败
            });
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        getVideoBase64(url) {
            let dataURL = ''
            let video = document.createElement('video')
            video.setAttribute('crossOrigin', 'anonymous') //处理跨域
            video.setAttribute('src', url)
            video.setAttribute('width', 375)
            video.setAttribute('height', 513)
            video.currentTime = 1
            video.addEventListener('loadeddata', () => {
                let canvas = document.createElement('canvas')
                let width = video.width //canvas的尺寸和图片一样
                let height = video.height
                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(video, 0, 0, width, height) //绘制canvas
                dataURL = canvas.toDataURL('image/jpeg') //转换为base64
                const blob = this.base64ImgtoFile(dataURL)
                const blobUrl = window.URL.createObjectURL(blob);
                this.imgUrl = blobUrl
            })
        },
        base64ImgtoFile(dataurl, filename = 'file') {
            //将base64格式分割：['data:image/png;base64','XXXX']
            const arr = dataurl.split(',')
            // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
            // image/png
            const mime = arr[0].match(/:(.*?);/)[1]  //image/png
            //[image,png] 获取图片类型后缀
            const suffix = mime.split('/')[1] //png
            const bstr = atob(arr[1])   //atob() 方法用于解码使用 base-64 编码的字符串
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
            })
        },
        /**
         * 发送图片/视频消息
         * */
        afterRead(file) {
            this.$toast.loading({
                message: '发送中...',
                forbidClick: true,
                duration: 0
            });
            let that = this
            if (file.file.type == 'video/mp4' || file.file.type == 'video/quicktime') {
                let formData = new FormData()
                formData.append('file', file.file)
                this.$axios.post('/file/ownerUpload', formData).then(res => {
                    if (res.code == 200) {
                        //视频地址
                        this.getVideoBase64(res.data.url)
                        var duration = 0;
                        let that = this
                        that.$toast.loading({
                            message: '发送中...',
                            forbidClick: true,
                        });
                        setTimeout(() => {
                            let msgJson = {
                                dataSize: file.file.size,
                                duration: duration,
                                uuid: that.guid(),
                                url: res.data.url,
                                snapshotUrl: that.imgUrl
                            }
                            let data = {
                                msgType: 102,
                                msgJson: msgJson
                            }
                            let message = that.$tim.createCustomMessage({
                                to: JSON.stringify(that.message_list[that.xuan_id].toAccount),
                                conversationType: that.$TIM.TYPES.CONV_C2C,
                                payload: {
                                    data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
                                    description: '', // 获取骰子点数
                                    extension: ''
                                }
                            });
                            let promise = that.$tim.sendMessage(message);
                            promise.then(function (imResponse) {
                                that.pulish_xiao(that.$user_info.account, JSON.stringify(that.message_list[that.xuan_id].toAccount))
                                that.$router.push('/')
                                that.card_show = false
                                let data = imResponse.data.message
                                if (typeof data.payload.data == 'string') {
                                    data.payload.data = JSON.parse(data.payload.data)
                                }
                                if (typeof data.payload.data.msgJson == 'string') {
                                    data.payload.data.msgJson = JSON.parse(data.payload.data.msgJson)
                                }
                                data.MsgBody = [
                                    {
                                        MsgType: data.type,
                                        MsgContent: {
                                            Data: data.payload.data,
                                            Desc: "",
                                            Ext: "",
                                            Sound: ""
                                        }
                                    }
                                ]
                                that.session_list.push(data)
                                if (that.liulan) {
                                    that.go_mesage(that.liulan)
                                }
                                setTimeout(() => {
                                    that.nextTick()
                                    that.$toast.clear()
                                }, 500)
                            }).catch(function (imError) {
                                // 发送失败
                                console.warn('sendMessage error:', imError);
                            });
                        }, 1500)
                    }
                })
            } else {
                // 1. 创建消息实例，接口返回的实例可以上屏
                let message = this.$tim.createImageMessage({
                    to: JSON.stringify(this.message_list[this.xuan_id].toAccount),
                    conversationType: this.$TIM.TYPES.CONV_C2C,
                    payload: {
                        file: file.file
                    },
                    onProgress: function (event) {
                    }
                });
                // 2. 发送消息
                let promise = this.$tim.sendMessage(message);
                promise.then(function (imResponse) {
                    setTimeout(() => {
                        that.delexiao(that.message_list[that.xuan_id].uuid)
                    })
                    that.pulish_xiao(that.$user_info.account, JSON.stringify(that.message_list[that.xuan_id].toAccount))
                    imResponse.data.message.userID = imResponse.data.message.to
                    imResponse.data.message.MsgBody = [
                        {
                            MsgType: imResponse.data.message.type,
                            MsgContent: imResponse.data.message.payload
                        }
                    ]
                    that.session_list.push(imResponse.data.message)
                    setTimeout(() => {
                        that.nextTick()
                        that.$toast.clear()
                    }, 500)
                }).catch(function (imError) {
                    that.$toast(imError) // 发送失败
                });
            }
        },
        // 单聊 跳到聊天设置
        more(info) {

            this.$router.push({
                path: '/More',
                query: {
                    info: JSON.stringify(info)
                }
            })
        },
        /**
         * 顶部功能
         * */
        fun_click(index) {
            if (index == 2) {
                this.$router.push({
                    path: '/wallet',
                    query: {
                        active: this.active,
                        account: this.message_list[this.xuan_id].toAccount
                    }
                })
            } else if (index == 3) {
                this.$router.push({
                    path: '/transfer_page',
                    query: {
                        account: this.message_list[this.xuan_id].toAccount
                    }
                })
            } else if (index == 4) {
                this.$router.push({
                    path: '/Chat_search',
                    query: {
                        id: 1,
                        account: this.message_list[this.xuan_id].toAccount,
                        tAccount: this.message_list[this.xuan_id].toAccount
                    }
                })
            } else if (index == 5) {
                this.$router.push({
                    path: '/Business',
                    query: {
                        id: this.active,
                        account: this.message_list[this.xuan_id].toAccount
                    }
                })
            } else if (index == 6) {
                console.log('点击位置')
            }
        },
        /**
         * 长按头像后
         * */
        gtouchstart(item, index) {
            console.log(item)
            this.show_pic = false
            this.show = false
            this.isPinned = false
            if (index == 0 || index) {
                var self = this;
                this.timeOutEvent = setTimeout(function () {
                    self.show = true
                    self.isPinned = item.isPinned
                    self.longPress(index);
                }, 300); //这里设置定时器，定义长按500毫秒触发长按事件
                return false;
            }
        },
        //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
        showDeleteButton(item) {
            clearTimeout(this.timeOutEvent); //清除定时器
            if (this.timeOutEvent != 0) {
                this.xuan_id = item
                this.session_list = []
                this.$axios.put('juxin-app/dialogue/updateIsRead', {
                    uuid: this.message_list[item].uuid
                }).then(res => {
                    if (res.code == 200) {
                        this.message_list[item].unReadCount = 0
                    }
                })
                this.$axios({
                    url: '/bkx-juxin-app/chattingRecords/getMsgPersonal',
                    method: 'GET',
                    params: {
                        account: this.$user_info.account,
                        tAccount: this.message_list[item].toAccount,
                        type: 0
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.session_list = res.data
                        for (let i = 0; i < this.session_list.length; i++) {
                            if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                    this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                }
                            }
                            let unReadCount = 0
                            unReadCount += this.session_list[i].unReadCount
                            this.$emit('dan_shu', unReadCount)
                            if (i >= 1) {
                                this.session_list[i].timea = this.time_out(this.session_list[i])
                            } else {
                                this.session_list[i].timea = this.time_out(this.session_list[i])
                            }
                        }
                        setTimeout(() => {
                            this.nextTick()
                        }, 500)
                    } else {
                        this.$toast(res.msg)
                    }
                })
            }
            return false;
        },
        //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
        gtouchmove() {
            clearTimeout(this.timeOutEvent); //清除定时器
            this.timeOutEvent = 0;
        },
        //真正长按后应该执行的内容
        longPress(val) {
            this.show_pic = false
            this.timeOutEvent = 0;
            this.message_list[val].show = true
            this.item_show = true
            this.item_index = val
            this.wid_val = document.getElementById(`dian${val}`).getBoundingClientRect().top
            this.index = val
            let left = document.querySelectorAll('#left-z>img')[val]
            let right = document.querySelector('.caidan-z')
            right.style.top = (left.getBoundingClientRect().top + window.pageYOffset - 10) + 'px'
            setTimeout(() => {
                this.show_pic = true
            }, 200)
        },
        /**
         * 置顶事件
         * */
        pulichs(index, id) {
            this.message_list[this.index].show = false
            if (id == 2) { // 删除
                this.$dialog.confirm({
                    message: "确定删除该会话？",
                    confirmButtonColor: '#fa902c'
                }).then(() => {
                    this.$axios({
                        url: 'bkx-juxin-app/dialogue/delDialogue',
                        method: 'DELETE',
                        params: {
                            uuid: this.message_list[this.index].uuid
                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.message_list[this.index].isPinned = 1
                            this.message_list.splice(this.index, 1)//先把符合条件的数据从当前数组中删除
                            this.isPinned = false
                            this.show = false
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }).catch(() => {
                    // on cancel
                });
            } else {
                this.$axios.put('/bkx-juxin-app/dialogue/updateIs', {
                    uuid: this.message_list[this.index].uuid, //会话主键id
                    isPinned: id == 1 ? '0' : '1', //是否置顶（0否1是）
                    isDonotDisturb: this.message_list[this.index].isDonotDisturb == false ? '0' : '1' //是否免打扰
                }).then(res => {
                    if (res.code == 200) {
                        if (id == 3) {
                            this.message_list[this.index].isPinned = 1
                            this.ceshi = this.message_list[this.index] //声明一个对象保存符合要求的数据
                            this.message_list.splice(this.index, 1)//先把符合条件的数据从当前数组中删除
                            this.message_list.unshift(this.ceshi)//通过unshift函数把符合要求的数据放到第一位
                            this.isPinned = false
                            this.show = false
                        } else if (id == 1) {
                            this.message_list[this.index].isPinned = 0
                            this.ceshi = this.message_list[this.index] //声明一个对象保存符合要求的数据
                            this.message_list.splice(this.index, 1)//先把符合条件的数据从当前数组中删除
                            for (let i = 0; i < this.message_list.length; i++) {
                                if (this.message_list[i].isPinned == 0) {
                                    this.message_list.splice(i, 0, this.ceshi);
                                    break
                                }
                            }
                            this.isPinned = false
                            this.show = false
                        }
                        for (let j = 0; j < this.message_list.length; j++) {
                            if (this.message_list[j].uuid == this.message_list[this.xuan_id].uuid) {
                                this.$axios({
                                    url: '/bkx-juxin-app/chattingRecords/getMsgPersonal',
                                    method: 'GET',
                                    params: {
                                        account: this.$user_info.account,
                                        tAccount: this.message_list[j].toAccount,
                                        type: 0
                                    }
                                }).then(res => {
                                    if (res.code == 200) {
                                        let data = []
                                        for (let i = 0; i < res.data.length; i++) {
                                            if (res.data[i].MsgBody[0].MsgContent.Data) {
                                                if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgType == 99) {
                                                    if (JSON.parse(res.data[i].MsgBody[0].MsgContent.Data).msgJson.type != 52) {
                                                        data.push(res.data[i])
                                                    }
                                                } else {
                                                    data.push(res.data[i])
                                                }
                                            } else {
                                                data.push(res.data[i])
                                            }
                                        }
                                        this.session_list = data
                                        for (let i = 0; i < this.session_list.length; i++) {
                                            if (this.session_list[i].MsgBody[0].MsgContent.Data) {
                                                this.session_list[i].MsgBody[0].MsgContent.Data = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data)
                                                if (typeof this.session_list[i].MsgBody[0].MsgContent.Data.msgJson == 'string') {
                                                    this.session_list[i].MsgBody[0].MsgContent.Data.msgJson = JSON.parse(this.session_list[i].MsgBody[0].MsgContent.Data.msgJson)
                                                }
                                            }
                                            if (i >= 1) {
                                                this.session_list[i].timea = this.time_out(this.session_list[i])
                                            } else {
                                                this.session_list[i].timea = this.time_out(this.session_list[i])
                                            }
                                        }

                                        this.jian_message()
                                        this.nextTick()
                                    } else {
                                        this.$toast(res.msg)
                                    }
                                })
                                break
                            }
                        }
                    } else {
                        this.$toast(res.msg)
                    }
                })
            }
        },
        // 图片
        ImgPopupShow(item) {
            this.imgShow = true
            if (item.MsgBody[0].MsgContent.ImageInfoArray) {
                this.BigImg = item.MsgBody[0].MsgContent.ImageInfoArray[0].URL
            } else {
                this.BigImg = item.MsgBody[0].MsgContent.imageInfoArray[2].url
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.left_mess {
    margin-left: 12px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.right_mess {
    margin-right: 12px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.pur_emoji {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 240px;
}

.left::-webkit-scrollbar {
    display: none;
}

.B-box::-webkit-scrollbar {
    display: none;
}

.chat_content {
    background-size: cover;
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
}

/* 中间 左侧头像区域 */
.content .left {
    position: relative;
    z-index: 999;
    height: 76vh;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
}

.left_z {
    width: 128px;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
}

.cryz {
    position: absolute;
    width: 28px;
    height: 70px;
    top: 50%;
    right: -25px;
    background: url("https://test.files.gongxiangsc.com/2023/08/01/21e4e9a9-4db7-44bb-a73c-86d159516260.png") no-repeat center right;
    transform: translateY(-50%);
    border-radius: 0 35px 35px 0;
    z-index: 1000;
}

.cryz_z {
    position: absolute;
    width: 28px;
    height: 70px;
    top: 50%;
    right: -25px;
    background: url("https://test.files.gongxiangsc.com/2023/08/01/5ae71b8f-5cf9-4d84-b51a-230390470820.png") no-repeat center right;
    transform: translateY(-50%);
    border-radius: 0 35px 35px 0;
    z-index: 900;
}

.cryz_z_show {
    width: 500px !important;
}

.text {
    font-size: 30px;
    color: #181818;
}

.chat_time {
    text-align: center;
    font-size: 20px;
    color: #ccc;
    margin-top: 22px;
}

.fun_img {
    width: 66px;
    height: 66px;
    display: block;
    margin: 0 auto;
    margin-right: 44px;
}

.chat_body {
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0 20px 20px 20px;
}


.body_left {
    // display: flex;
    justify-content: space-between;
    margin-top: 22px;
}

.body_right {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
}

.body_avatar {
    width: 77px;
    height: 77px;
    object-fit: cover;
}

.left_text {
    max-width: 420px;
    word-wrap: break-word;
    background-color: #fff;
    font-size: 30px;
    padding: 18px 20px;
}

.right_text {
    max-width: 420px;
    word-wrap: break-word;
    background-color: #fc9013;
    font-size: 30px;
    padding: 18px 20px;
    color: #fff;
}

.left_img {
    margin-left: 12px;
    border-radius: 8px;
}

.right_img {
    width: 200px;
    margin-right: 12px;
    border-radius: 8px;
}

.right_video {
    width: 400px;
    margin-right: 12px;
    border-radius: 8px;
}

.content .left .touxiang {
    width: 84px;
    height: 84px;
    margin: 20px;
    border-radius: 8px;
    object-fit: cover;
}

.xuan_touxiang {
    width: 84px;
    height: 84px;
    margin: 20px;
    border-radius: 8px;
    border: 4px solid #fc9013;
    object-fit: cover;
}

/* 右侧内容 */
.content .rigth {
    flex: 1;
    width: 80%;
    background: #f2f2f5;
    display: flex;
    flex-direction: column;
    background-size: 100%;
    background-repeat: no-repeat;
}

.content .rigth .T-box {
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
    margin: 22px 0;
}

.content .rigth .T-box .icon {
    width: 48px;
    height: 48px;
}

.content .rigth .T-box .icon img {
    width: 100%;
    height: 100%;
}

.content .rigth .T-box div {
    margin: 0;
    font-size: 32px;
    color: #000000;
}

.content .rigth .B-box {
    width: 100%;
    height: 104px;
    background: #d3d3d3;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    align-items: center;
}

.content .rigth .chat_bottom {
    display: flex;
    width: 100%;
    background: #ffffff;
}

.content .rigth .chat_bottom .chat_bottom_one {
    width: 48px;
    height: 48px;
    padding-top: 36px;
    padding-right: 20px;
}

.content .rigth .chat_bottom .chat_bottom_one img {
    width: 100%;
    height: 100%;
}

.content .rigth .chat_bottom .chat_bottom_two {
    width: 85%;
    margin: 15px 0;
    height: 88px;
    border: 0;
    border-radius: 8px;
}

.content .rigth .chat_bottom .chat_bottom_two input {
    height: 67px;
    width: 85%;
    border: 0;
    background: #f2f2f5;
}

/* 表情 */
.expression {
    width: 54px;
    height: 54px;
    margin-left: 20px;
}

.dibudingwei {
    position: sticky;
    left: 0;
    width: 100%;
    bottom: 0;
}

.van-tabs__line {
    z-index: 0;
}

.bottom_input {
    width: 83%;
    height: 60px;
    background: #f2f2f5;
    border: 0;
    font-size: 24px;
}

.van-popup {
    max-height: none;
}

.zhi_back {
    background-color: #ffede3;
}

.caidan {
    height: 121px;
    position: absolute;
    z-index: 9999;
    display: flex;
    // left: 100px;
}

.pulic {
    width: 114px;
    height: 100%;
    text-align: center;
}

.el-date-picker {
    width: 1456px;
}

.left_envelope {
    background-color: #fa902c;
}

.left_envelope img {
    margin: 32px 20px 14px 26px;
}

.right_envelope {
    background-color: #fa902c;
}

.right_envelope img {
    margin: 32px 20px 14px 26px;
}

.envelope_text {
    color: #fff;
    font-size: 26px;
    //   line-height: 120px;
    padding: 0 52px 0 0;
}

.left_transfer {
    background-color: #fa902c;
}

.left_transfer img {
    margin: 32px 20px 14px 26px;
}

.right_transfer {
    background-color: #fa902c;
}

.right_transfer img {
    margin: 32px 20px 14px 26px;
}

.transfer_text {
    color: #fff;
    font-size: 30px;
    padding: 0 52px 0 0;
}

.left_card {
    background-color: #fff;
}

.left_card img {
    margin: 32px 20px 14px 26px;
}

.right_card {
    background-color: #fff;
}

.right_card img {
    margin: 32px 20px 14px 26px;
}

.card_text {
    width: 316px;
    color: #181818;
    line-height: 112px;
    font-size: 30px;
    padding: 0 52px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.viewer-title {
    opacity: 0;
}

.dingdian {
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    color: #fff;
}

.hebao {
    width: 666px;
    height: 654px;
    margin: 0 auto;
    background-image: url("@/assrts/hebaojiemian.png");
    background-size: 100%;
    margin-top: 290px;
}

.hebao img:hover {
    transform: rotateY(3600deg);
}

.hebao img {
    position: absolute;
    top: 700px;
    left: 275px;
    transform-origin: 100px 0 0;
    transform-style: preserve-3d;
    transition: all 20s;
}

.dingwei_img {
    margin-top: 40px;
}

.back_input {
    width: 97%;
    border: 0;
    font-size: 14px;
    margin: 0 auto;
    display: block;
    margin-top: 5px;
    background-color: #ececec
}

.back_input::placeholder {
    color: #b0b0b0;
}

.imgPopup {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #1E252F;
    z-index: 10000;
    transition: 0.2s;
}
</style>
<style scoped>
/deep/ .van-overlay {
    /* z-index: 2002 !important; */
}

/deep/ .van-popup {
    background: none !important;
}

/deep/ .van-popup--center {
    top: 43% !important;
}
</style>

