<template>
    <div class="Tab">
        <img src="@/assrts/177.png" class="img_box" @click.stop="onClickLeft" />
        <div class="shezhi">{{ shezhi }}</div>
        <div style="width: 24px"></div>
    </div>
</template>

<script>
import { Dialog } from "vant";

export default {
    props: ["shezhi", "active", "baocun", 'url', 'text'],
    data() {
        return {};
    },
    created() {
    },
    methods: {
        // 左侧返回箭头
        onClickLeft() {
            if (this.active && !this.$route.query.a) {
                console.log(this.$route.query.account);
                this.$router.push({
                    path: "/",
                    query: {
                        id: this.active,
                        toaccount: this.$route.query.account,
                        togroundid: this.$route.query.account
                    },
                });
            } else if (this.baocun == 1) {

            } else if (this.url) {
                this.$router.push(this.url)
                if (this.url == '/BulkTransferIndex') {
                    sessionStorage.removeItem('searchPayeeEdit')
                    sessionStorage.removeItem('chooseLabelId')
                }
            } else if (this.text) {
                Dialog.confirm({
                    message: this.text,
                    confirmButtonColor: '#FF9113'
                }).then(() => {
                    this.$router.back(-1);
                }).catch(() => {
                    this.$router.back(-1);
                });
            } else {
                this.$router.back(-1);
            }
            // 添加账户页面点击返回后清除选择的账户缓存
            localStorage.removeItem("AccountData")
            localStorage.removeItem("AccountData1")
            localStorage.removeItem("echoData")
        },
    },
};
</script>

<style scoped>
.Tab {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.Tab .img_box {
    margin-left: 20px;
    width: 40px;
    height: 48px;
}

.Tab .img_box img {
    width: 100%;
    height: 100%;
}

.Tab .shezhi {
    color: #181818;
    font-size: 36px;
}
</style>
