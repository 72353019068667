// 导入tim插件
// import TIM from 'tim-js-sdk'
import TIM from 'tim-js-sdk/tim-js-friendship.js'
// tim上传插件
import TIMUploadPlugin from 'tim-upload-plugin';
// 截取顶部路径
let lujing = window.location.href.split('#')[0]
let options = {
    // 正式  1400572155
    // 测试  1400670459
    SDKAppID: lujing == 'https://juxin.kunzhousc.com/' ? 1400572155 : 1400670459
}
//创建SDK实例
let tim = TIM.create(options);
//设置SDK日志输出级别
tim.setLogLevel(1);
//注册腾讯云即时通信IM上传插件
tim.registerPlugin({
    'tim-upload-plugin': TIMUploadPlugin
})
export default tim
