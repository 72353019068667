import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router)
/**
 * meta 中的keepAlive 是否需要缓存
 * scrollTop 滚动条位置
 * */
export default new Router({
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    },

    routes: [
        { path: '/', name: 'home', component: () => import('@/views/home/home.vue'),meta:{keepAlive: true,scrollTop: 'A',} }, // 首页
        { path: '/login', name: 'login', component: () => import('@/views/login') }, //登陆页面
        { path: '/sign_vip', name: 'sign_vip', component: () => import('@/views/login/sign_vip.vue') }, //企业会员注册
        { path: '/Collection', name: 'Collection', component: () => import('@/views/home/collection.vue') }, //收付款页面
        { path: '/Setting', name: 'Setting', component: () => import('@/views/home/setting.vue') }, // 设置页面
        { path: '/Setting_id', name: 'Setting_id', component: () => import('@/views/home/setting_id.vue') },//设置中的账号与安全页面
        { path: '/Phone', name: 'Phone', component: () => import('@/views/home/phone.vue') },
        { path: '/user_info', name: 'user_info', component: () => import('@/views/home/user_info.vue') }, // 个人信息页面
        { path: '/Phone_verify', name: 'phone_verify', component: () => import('@/views/home/phone_verify.vue') }, // 验证手机号
        { path: '/Tongyong', name: 'Tongyong', component: () => import('@/views/home/tongyong.vue') }, //  设置——通用页面
        { path: '/Setting_privacy', name: 'setting_privacy', component: () => import('@/views/home/setting_privacy.vue') }, // 隐私设置页面
        { path: '/Tiaophone', name: 'Tiaophone', component: () => import('@/views/home/tiaophone.vue') }, // 手机号更换完成页面
        { path: '/Juxinpassword', name: 'juxinpassword', component: () => import('@/views/home/juxinpassword.vue') },   // 聚信号密码 =》 设置密码
        { path: '/give_index', name: 'give_index', component: () => import('@/views/give_service/give_index.vue') },   // 服务首页
        { path: '/Aboutjx', name: 'aboutjx', component: () => import('@/views/home/aboutjx.vue') }, // 关于聚信人和
        { path: '/Real_name', name: 'real_name', component: () => import('@/views/home/real_name.vue') }, //实名认证
        { path: '/Renzheng', name: 'renzheng', component: () => import('@/views/home/renzheng.vue') }, // 点击认证 - 实名认证
        { path: '/Renzhengnext', name: 'renzhengnext', component: () => import('@/views/home/renzhengnext') }, // 实名认证成功跳转的
        { path: '/More', name: 'more', component: () => import('@/views/more') }, // 单聊 三个点页面
        { path: '/set_mount', name: 'set_mount', component: () => import('@/views/home/set_mount') }, // 设置收款码金额
        { path: '/Invitation_code', name: 'Invitationcode', component: () => import('@/views/login/Invitation_code.vue') },//邀请码为空 跳转填写邀请码页面
        { path: '/Wechat', name: 'wechat', component: () => import('@/views/login/wechat.vue') }, // 微信-绑定手机号
        { path: '/historical_update', name: 'historicalupdate', component: () => import('@/views/home/historical_update') }, // 历史更新
        { path: '/historical_updateDetails', name: 'historical_updateDetails', component: () => import('@/views/home/historical_updateDetails') }, // 更新内容
        { path: '/aboutme', name: 'aboutme', component: () => import('@/views/home/aboutme') }, // 关于我们
        { path: '/asset_list', name: 'asset_list', component: () => import('@/views/give_service/asset_list.vue') }, // 系统费用页面
        { path: '/home', name: 'home', component: () => import('@/views/give_service/system_fees/home.vue') }, // 系统费用页面
        { path: '/premium_received', name: 'premium_received', component: () => import('@/views/give_service/system_fees/premium_received.vue') }, // 系统费用缴纳页面
        { path: '/advance', name: 'advance', component: () => import('@/views/give_service/system_fees/advance.vue') }, // 预收入账单页面
        { path: '/withdrawal_records', name: 'withdrawal_records', component: () => import('@/views/give_service/system_fees/withdrawal_records.vue') }, // 提现历史页面
        { path: '/Give_more', name: 'give_more', component: () => import('@/views/give_service/give_more.vue') }, // 支付设置
        { path: '/Give_modify', name: 'give_modify', component: () => import('@/views/give_service/give_modify.vue') }, // 修改密码
        { path: '/bill_details', name: 'bill_details', component: () => import('@/views/give_service/bill_details.vue') }, // 我的资产账单明细页面
        { path: '/Give_forget', name: 'give_forget', component: () => import('@/views/give_service/give_forget.vue') }, // 忘记支付密码
        { path: '/one_detalis', name: 'one_detalis', component: () => import('@/views/give_service/one_detalis.vue') }, // 账户明细的详情
        { path: '/Chat_background', name: 'chatbackground', component: () => import('@/views/more/Chat_background.vue') },// 聊天背景
        { path: '/Chat_search', name: 'chat_search', component: () => import('@/views/more/Chat_search.vue') },// 聊天记录
        { path: '/ImgMove', name: 'ImgMove', component: () => import('@/views/more/ImgMove.vue') },// 图片与视频
        { path: '/trading', name: 'trading', component: () => import('@/views/more/trading.vue') },// 交易
        { path: '/recharge', name: 'recharge', component: () => import('@/views/give_service/recharge.vue') },// 我要充值
        { path: '/withdrawal', name: 'withdrawal', component: () => import('@/views/give_service/withdrawal.vue') },// 我要提现
        { path: '/Chat_complaint', name: 'chat_complaint', component: () => import('@/views/more/Chat_complaint.vue') },  // 投诉
        { path: '/ComplaintAndSuggestion', name: 'ComplaintAndSuggestion', component: () => import('@/views/more/ComplaintAndSuggestion.vue') },  // 投诉详情
        { path: '/Bank_card', name: 'Bank_card', component: () => import('@/views/give_service/bank_card.vue') },  // 银行卡页面
        { path: '/Bank_Add', name: 'bank_add', component: () => import('@/views/give_service/bank_Add.vue') }, //银行卡页面——添加
        { path: '/integral', name: 'integral', component: () => import('@/views/give_service//integral.vue') }, //积分兑换
        { path: '/Bank_Add_step', name: 'Bank_Add_step', component: () => import('@/views/give_service/bank_Add_step.vue') },//银行卡页面——添加--下一步
        { path: '/jiebang_bank', name: 'jiebang_bank', component: () => import('@/views/give_service/jiebang_bank.vue') }, //银行卡页面——添加
        { path: '/Successfully_opened', name: 'Successfully_opened', component: () => import('@/views/give_service/Successfully_opened.vue') },// 快捷方式开通成功
        { path: '/Added_successfully', name: 'Added_successfully', component: () => import('@/views/give_service/Added_successfully.vue') },  // 添加成功
        { path: '/wallet', name: 'wallet', component: () => import('@/views/home/wallet.vue') },  // 荷包页面
        { path: '/publicManagement', name: 'publicManagement', component: () => import('@/views/corporate_management/publicManagement.vue') },  // 对公管理
        { path: '/publicManagementDetails', name: 'publicManagement', component: () => import('@/views/corporate_management/publicManagementDetails.vue') },  // 对公管理单条数据详情
        { path: '/blacklist', name: 'blacklist', component: () => import('@/views/home/blacklist.vue') },  // 黑名单
        { path: '/BulkTransferIndex', name: 'BulkTransferIndex', component: () => import('@/views/give_service/BulkTransfer/index.vue') },  // 批量转账
        { path: '/blackListDetails', name: 'blackListDetails', component: () => import('@/views/home/blackListDetails.vue') },  // 个人信息详情
        { path: '/addFriends', name: 'addFriends', component: () => import('@/views/home/addFriends.vue') },  // 添加好友
        { path: '/SetRemarks', name: 'SetRemarks', component: () => import('@/views/home/SetRemarks.vue') }, //设置备注
        { path: '/signature', name: 'signature', component: () => import('@/views/home/signature.vue') },  // 个性签名
        { path: '/DataSetting', name: 'DataSetting', component: () => import('@/views/home/DataSetting.vue') },  // 黑名单
        { path: '/transfer_page', name: 'transfer_page', component: () => import('@/views/home/transfer_page.vue') },  // 转账页面
        { path: '/selectPayee', name: 'selectPayee', component: () => import('@/views/give_service/BulkTransfer/selectPayee.vue') },  // 批量转账-搜索/查找转账人
        { path: '/labelManagement', name: 'labelManagement', component: () => import('@/views/give_service/BulkTransfer/labelManagement.vue') },  // 批量转账-管理标签
        { path: '/group', name: 'group', component: () => import('@/views/give_service/BulkTransfer/Group.vue') },  // 批量转账-编辑标签-编辑成员
        { path: '/editTag', name: 'editTag', component: () => import('@/views/give_service/BulkTransfer/editTag.vue') },  // 批量转账-编辑标签
        { path: '/searchPayeeText', name: 'searchPayeeText', component: () => import('@/views/give_service/BulkTransfer/searchPayeeText.vue') }, //搜索页面
        { path: '/searchGroupPayee', name: 'searchGroupPayee', component: () => import('@/views/give_service/BulkTransfer/searchGroupPayee.vue') },  // 批量转账-添加群成员
        { path: '/LabsearchGroupPayee', name: 'LabsearchGroupPayee', component: () => import('@/views/give_service/BulkTransfer/LabsearchGroupPayee.vue') },  // 批量转账-编辑标签群聊页
        { path: '/MembersearchGroupPayee', name: 'MembersearchGroupPayee', component: () => import('@/views/give_service/BulkTransfer/MembersearchGroupPayee.vue') },  // 批量转账-编辑标签群聊页
        { path: '/SuccessfulTransfer', name: 'SuccessfulTransfer', component: () => import('@/views/give_service/BulkTransfer/SuccessfulTransfer.vue') },  // 转帐过程--结果
        { path: '/publicManagement_add', name: 'publicManagement_add', component: () => import('@/views/corporate_management/publicManagement_add.vue') }, // 添加对公管理页面
        { path: '/qun_more', name: 'qun_more', component: () => import('@/views/more/qun_more.vue') },  // 群设置页面
        { path: '/Add_Account', name: 'Add_Account', component: () => import('@/views/home/Add_Account.vue') }, // 添加对公管理页面 第二步
        { path: '/Add_Account_three', name: 'Add_Account_three', component: () => import('@/views/home/Add_Account_three.vue') }, // 添加对公管理第三步
        { path: '/Add_Account_four', name: 'Add_Account_four', component: () => import('@/views/home/Add_Account_four.vue') }, // 添加对公管理第4步
        { path: '/Apply_examine', name: 'Applyexamine', component: () => import('@/views/corporate_management/apply_examine .vue') }, // 申请对公审核
        { path: '/Application_Details', name: 'Application Details', component: () => import('@/views/corporate_management/Application_Details.vue') }, // 申请详情
        { path: '/envelope', name: 'envelope', component: () => import('@/views/home/envelope.vue') }, // 红包页面
        { path: '/transfer', name: 'transfer', component: () => import('@/views/home/transfer.vue') }, // 转账页面
        { path: '/Go_account', name: 'Go_account', component: () => import('@/views/give_service/Go_account.vue') }, // 对公账户
        { path: '/Duigong_details', name: 'Duigong_details', component: () => import('@/components/Duigong_details.vue') },  //对公详情
        { path: '/business', name: 'business', component: () => import('@/views/home/business.vue') }, // 好友界面
        { path: '/qun_manage', name: 'qun_manage', component: () => import('@/views/more/qun_manage.vue') }, // 群管理
        { path: '/qun_transference', name: 'qun_transference', component: () => import('@/views/more/qun_transference.vue') },  // 群主转让页面
        { path: '/administrators', name: 'administrators', component: () => import('@/views/more/administrators.vue') }, // 群管理员
        { path: '/change_avatar', name: 'change_avatar', component: () => import('@/views/home/change_avatar.vue') }, // 查看头像
        { path: '/business_card', name: 'business_card', component: () => import('@/views/home/business_card.vue') }, // 个人二维码页面
        { path: '/scan', name: 'scan', component: () => import('@/views/home/scan.vue') }, // 扫码页面
        { path: '/scan_xiang', name: 'scan_xiang', component: () => import('@/views/home/scan_xiang.vue') }, // 个人判断页面
        { path: '/Application_List', name: 'Application_List', component: () => import('@/views/more/Application_List.vue') }, // 申请列表
        { path: '/collections', name: 'collections', component: () => import('@/views/home/collections.vue') }, // 付款页面
        { path: '/Application_History', name: 'Application_History', component: () => import('@/views/more/Application_History') }, // 申请历史
        { path: '/modify_name', name: 'modify_name', component: () => import('@/views/more/modify.vue') },  // 修改群名称
        { path: '/qun_code', name: 'qun_code', component: () => import('@/views/more/qun_code.vue') }, // 群二维码
        { path: '/Write', name: 'Write', component: () => import('@/views/home/Write.vue') }, // 核销商品
        { path: '/qun_Notice', name: 'qun_Notice', component: () => import('@/views/more/qun_Notice.vue') }, // 群公告
        { path: '/create_now', name: 'create_now', component: () => import('@/views/more/create_now.vue') }, // 申请列表
        { path: '/qun_bianji', name: 'qun_bianji', component: () => import('@/views/more/qun_bianji.vue') },  // 群编辑
        { path: '/Give_shezhi', name: 'Give_shezhi', component: () => import('@/views/give_service/Give_shezhi.vue') }, // 服务 - 右上角三个点-支付设置-设置支付密码
        { path: '/Prohibition_manage', name: 'Prohibition_manage', component: () => import('@/views/more/Prohibition_manage.vue') },// 群聊-禁言管理
        { path: '/apply_empower', name: 'apply_empower', component: () => import('@/components/Tabbar/apply_empower.vue') }, // 小爱心 - 申请授权
        { path:  '/details', name:'details', component:()=>import('@/components/Tabbar/details.vue')}, // // 小爱心 - 申请授权 - 查看详情
        { path: '/promotion_commodity', name: 'promotion_commodity', component: () => import('@/components/Tabbar/promotion_commodity.vue') }, // 小爱心 - 推广商品
        { path: '/group_chat', name: 'group_chat', component: () => import('@/views/home/group_chat.vue') }, // 申请进入群聊页面
        { path: '/apply_changjia', name: 'apply_changjia', component: () => import('@/views/login/apply_changjia.vue') }, // 申请厂家实名认证
        { path: '/renzhengtwo', name: 'renzhengtwo', component: () => import('@/views/login/renzhengtwo.vue') }, // 申请厂家实名认证 -- 认证
        { path: '/apply_changjia_xia', name: 'apply_changjia_xia', component: () => import('@/views/login/apply_changjia_xia.vue') }, //申请厂家实名认证 -- 认证- 成功页面下一步
        { path: '/apply_next', name: 'apply_next', component: () => import('@/views/login/apply_next.vue') }, // //申请厂家实名认证 -- 认证- 成功页面下一步 --下一步
        { path: '/apply_Upload', name: 'apply_Upload', component: () => import('@/views/login/apply_Upload.vue') }, // 申请厂家-上传
        { path: '/renzheng_Jump', name: 'renzheng_Jump', component: () => import('@/views/login/renzheng_Jump.vue') }, //申请厂家-上传 认证跳转
        { path: '/wx_binding', name: 'wx_binding', component: () => import('@/views/home/wx_binding.vue') }, // 微信绑定
        { path: '/jiebang', name: 'jiebang', component: () => import('@/components/jiebang.vue') }, // 解绑成功
        { path: '/calendar', name: 'calendar', component: () => import('@/views/home/calendar.vue') }, //选择日历
        { path: '/delete_group', name: 'delete_group', component: () => import('@/views/home/delete_group.vue') }, // 删除群成员
        { path: '/select_address', name: 'select_address', component: () => import('@/views/home/select_address.vue') }, // 删除群成员
        { path: '/invite_code', name: 'invite_code', component: () => import('@/components/Tabbar/invite_code.vue') }, // 添加邀请人
        { path: '/activation', name: 'activation', component: () => import('@/components/Tabbar/activation.vue') }, // 激活积分支付
        { path: '/applyThroughFriends', name: 'applyThroughFriends', component: () => import('@/views/home/applyThroughFriends.vue') }, // 通过好友申请
        { path: '/wallet_list', name: 'wallet_list', component: () => import('@/views/home/wallet_list.vue') }, // 通过好友申请
        { path: '/buildGroup', name: 'buildGroup', component: () => import('@/views/home/buildGroup.vue') }, // 通过好友申请
        { path:  '/winxin' , name:'winxin', component:()=> import('@/views/login/winxin.vue')}, // 微信 跳了一个页
        { path: '/real_name_lose' , name:'real_name_lose', component:()=>import('@/views/home/real_name_lose.vue')}, // 实名认证失败页
        { path: '/usage_record' , name:'usage_record', component:()=>import('@/views/corporate_management/usage_record.vue')}, // 对公使用记录页
        { path: '/corporate_information' , name:'corporate_information', component:()=>import('@/views/corporate_management/corporate_information.vue')}, // 管理对公信息页
        { path: '/add_corporate' , name:'add_corporate', component:()=>import('@/views/corporate_management/add_corporate.vue')}, // 添加对公页
        { path: '/details_corporate' , name:'details_corporate', component:()=>import('@/views/corporate_management/details_corporate.vue')}, // 对公信息详情页

        // 定时转账
        { path: '/TimeTransferImmobilization', name:'TimeTransferImmobilization', component:()=>import('@/views/give_service/TimeTransferImmobilization/index.vue')}, // 定时转账(百分比)页面
        { path: '/changeInto', name:'changeInto', component:()=>import('@/views/give_service/TimeTransferImmobilization/changeInto.vue')}, //定时转账(百分比)转入页面

        { path: '/scaleSettings', name:'scaleSettings', component:()=>import('@/views/give_service/TimeTransferImmobilization/scaleSettings.vue')}, //定时转账(百分比)批量比例设置页面
        // { path: '/transferMoney', name:'transferMoney', component:()=>import('@/views/give_service/TimeTransferImmobilization/transferMoney.vue')}, //定时转账(百分比)转账金额页面
        { path: '/transferRecords', name:'transferRecords', component:()=>import('@/views/give_service/TimeTransferImmobilization/transferRecords.vue')}, //定时转账(百分比)转账记录
        { path: '/waiteTransfer', name:'waiteTransfer', component:()=>import('@/views/give_service/TimeTransferImmobilization/waiteTransfer.vue')}, //定时转账(百分比)待转账金额页面
        { path: '/addressBook', name:'addressBook', component:()=>import('@/views/give_service/TimeTransferImmobilization/addressBook.vue')}, //定时转账(百分比)通讯录。一级账户，二级账户页面
        { path: '/addressBook', name:'addressBook', component:()=>import('@/views/give_service/TimeTransferImmobilization/addressBook.vue')}, //定时转账(百分比)待转账金额页面
        { path: '/addAccount', name:'addAccount', component:()=>import('@/views/give_service/TimeTransferImmobilization/addAccount.vue')}, //定时转账(百分比)转账列表添加账户
        { path: '/timetransferList', name:'timetransferList', component:()=>import('@/views/give_service/TimeTransferImmobilization/timetransferList.vue')}, //定时转账(百分比)转账列表

        /*--------------------------------------------------- */

        { path: '/TimeTransferPercent', name:'TimeTransferPercent', component:()=>import('@/views/give_service/TimeTransferPercent/index.vue')}, // 定时转账(固定)页面
        { path: '/transferList', name:'transferList', component:()=>import('@/views/give_service/TimeTransferPercent/transferList.vue')}, // 定时转账(固定)-转账列表页面
        { path: '/PercentchangeInto', name:'PercentchangeInto', component:()=>import('@/views/give_service/TimeTransferPercent/PercentchangeInto.vue')}, // 定时转账(固定)转入页面
        { path: '/fixedTransfer', name:'fixedTransfer', component:()=>import('@/views/give_service/TimeTransferPercent/fixedTransfer.vue')},// 定时转账(固定)待转账页面
        // { path: '/precentMoney', name:'precentMoney', component:()=>import('@/views/give_service/TimeTransferPercent/precentMoney.vue')}, // 定时转账(固定)转入页面
        { path: '/addAccounts', name:'addAccounts', component:()=>import('@/views/give_service/TimeTransferPercent/addAccounts.vue')}, //定时转账(固定)转账列表添加账户


        { path: '/live_broadcast', name:'live_broadcast', component:()=>import('@/views/give_service/entertainment/live_broadcast.vue')}, //娱乐、直播
        { path: '/videoList', name:'videoList', component:()=>import('@/views/give_service/entertainment/videoList.vue'),meta:{keepAlive: true, scrollTop: 0,}}, //娱乐、视频
        { path: '/video_player', name:'video_player', component:()=>import('@/views/give_service/entertainment/video_player.vue')}, //娱乐、视频播放

    ]
})

