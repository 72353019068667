<template>
    <div>
        <TopPackVue style="background: #f2f2f5"></TopPackVue>
        <!-- 图片 -->
        <div style="margin: 42px auto 0px">
            <!-- 解绑成功 -->
            <img v-if="type == 1" src="@/assrts/jb1.png" alt=""
                 style="width: 210px; height: 185px; display: block; margin: 0 auto"/>
            <!-- 更改成功 -->
            <img v-if="type == 2" src="@/assrts/jb2.png" alt=""
                 style="width: 210px; height: 185px; display: block; margin: 0 auto"/>
            <!-- 新增成功 -->
            <img v-if="type == 3" src="@/assrts/jb3.png" alt=""
                 style="width: 210px; height: 185px; display: block; margin: 0 auto"/>
        </div>
        <!-- 字 -->
        <div style="
        margin-top: 35px;
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        text-align: center;
      ">
            {{ type == 1 ? "解绑成功" : type == 2 ? "更换成功" : "新增成功" }}
        </div>

        <!-- 倒计时 -->
        <div style="text-align: center; font-size: 12px; color: #cccccc">
            {{ time }}秒自动返回
        </div>
    </div>
</template>

<script>
import TopPackVue from "./TopPack.vue";

export default {
    components: {
        TopPackVue,
    },
    data() {
        return {
            time: 3, // 3s 跳转
            timer: null, //接收倒计时
            type: null, // 1 解绑成功 2 更换成功  3 新增成功
        };
    },
    created() {
        this.type = this.$route.query.id;
    },
    watch: {
        time: function (newVal) {
            if (newVal <= 0) {
                clearInterval(this.timer);
                if (this.type == 1) {
                    this.$router.push({
                        path: "/Setting",
                        query: {key: 1}, // 传 1 就是解绑了
                    });
                } else {
                    this.$router.push({
                        path: "/Setting",
                        query: {key: 2}, // 传 2 就是更换 2 // 已绑定
                    });
                }
            }
        },
    },
    mounted() {
        this.timer = setInterval(() => {
            this.time--;
        }, 1000);
    },
};
</script>

<style scoped>
a {
}
</style>
